<template>
  <div>
    <other-earnings-charges-table
      :chargeList="charges"
    ></other-earnings-charges-table>
  </div>
</template>

<script>
import OtherEarningsChargesTable from "./other-earnings-charges-table.vue";
export default {
  components: { OtherEarningsChargesTable },
  computed: {
    charges() {
      var chargeList = this.$store.state.otherEarningsChargesModule.charges;
      return chargeList.filter(
        (t) => t.adpCode != "D" && t.adpCode != "M" && t.adpCode != "L"
      );
    },
  },
};
</script>
