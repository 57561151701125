<template>
  <v-card flat class="primary--text">
    <validation-observer v-slot="{ invalid }">
      <v-toolbar flat>
        <v-toolbar-title>Paid Sick Leave Request</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <validation-observer v-slot="{ invalid }">
          <form-field
            :field="startDateField"
            @fieldChange="onStartDateChange"
            :validations="validations.startDate"
            :readonly="noEdit"
          ></form-field>
          <form-field
            :field="endDateField"
            @fieldChange="onEndDateChange"
            :validations="validations.endDate"
            :readonly="noEdit"
          ></form-field>
          <sessions-modal
            :startDate="startDate"
            :endDate="endDate"
            :disabled="invalid"
            :request="request"
            :noEdit="noEdit"
            @setHoursRequested="setHoursRequested"
            @setSessions="setSessions"
          ></sessions-modal>
        </validation-observer>
        <validation-provider
          :rules="validations.hoursRequested"
          v-slot="{ errors }"
        >
          <v-row class="mt-5">
            <v-subheader class="mt-2">Total hours to apply:</v-subheader>
            <v-col cols="2">
              <v-text-field
                v-model="hoursRequested"
                type="number"
                readonly
                filled
                dense
                hide-details
                reverse
              >
              </v-text-field>
            </v-col>
          </v-row>

          <span
            v-show="errors.length > 0"
            class="text-caption error--text mb-4"
          >
            Lookup and select which cancelled sessions to apply paid sick leave
            hours towards
          </span>
        </validation-provider>

        <v-row>
          <v-col cols="4">
            <validation-provider rules="required" v-slot="{ errors }">
              <v-autocomplete
                v-model="reason"
                label="Reason"
                :items="reasonOptions"
                :error-messages="errors"
                :readonly="noEdit"
              ></v-autocomplete>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="providerNote"
              label="Provider Notes"
              :readonly="noEdit"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="adminNote"
              label="Admin Notes"
              :readonly="noEdit"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          outlined
          :disabled="invalid || noEdit"
          @click="submit"
        >
          Submit Request
        </v-btn>
        <v-btn color="primary" text @click="cancel"> Cancel </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>
<script>
import FormField from "../../objs/Shared/FormField";
import { FieldTypes } from "../../objs/Shared/FieldTypes";
import SessionsModal from "./sessions-modal.vue";
import {
  required,
  minDate,
  maxDate,
  minValue,
} from "../../plugins/vee-validate/validation";
import PaidSickLeaveRequest from "../../objs/PaidSickLeaveRequest";
import { getSchoolYearFromDate } from "../../utils/utils";
export default {
  props: ["request", "noEdit"],
  components: {
    SessionsModal,
  },
  mounted() {
    if (this.request) {
      this.startDate = new Date(this.request.requestStart);
      this.endDate = new Date(this.request.requestEnd);
      this.hoursRequested = this.request.hoursRequested;
      this.reason = this.request.reason;
      this.providerNote = this.request.providerNote;
      this.adminNote = this.request.adminNote;
      this.sessions = this.request.sessions;
    }
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      loadingSessions: false,
      hoursRequested: 0,
      reasonOptions: ["Sick", "Sick child", "Doctor visit"],
      reason: "",
      providerNote: "",
      adminNote: "",
      sessions: [],
    };
  },
  computed: {
    validations: function () {
      return {
        startDate: { required: true },
        endDate: {
          required: true,
          minDate: this.startDate,
          maxDate: new Date(this.requestYear + 1, 6, 1), // July of year following startDate's year
        },
        hoursRequested: {
          minValue: 0.1,
        },
      };
    },
    startDateField() {
      return new FormField({
        label: "Start Date",
        value: this.startDate,
        componentName: FieldTypes.DATE_PICKER,
      });
    },
    endDateField() {
      return new FormField({
        label: "End Date",
        value: this.endDate,
        componentName: FieldTypes.DATE_PICKER,
      });
    },
    requestYear() {
      return getSchoolYearFromDate(this.startDate);
    },
  },
  methods: {
    onStartDateChange(value) {
      this.startDate = value;
    },
    onEndDateChange(value) {
      this.endDate = value;
    },
    setHoursRequested(value) {
      this.hoursRequested = value;
    },
    setSessions(value) {
      this.sessions = value;
    },
    cancel() {
      this.$router.push({ name: "Provider Requests" });
    },
    async submit() {
      this.$store.commit("uxModule/setShowLoader", true);
      let request;
      let message;
      if (this.request) {
        request = {
          ...this.request,
          requestStart: this.startDate,
          requestEnd: this.endDate,
          requestYear: this.requestYear,
          hoursRequested: this.hoursRequested,
          reason: this.reason,
          providerNote: this.providerNote,
          adminNote: this.adminNote,
          sessions: this.sessions,
        };
        message = await this.$store.dispatch(
          "paidSickLeaveModule/updateRequest",
          request
        );
      } else {
        request = new PaidSickLeaveRequest({
          requestStart: this.startDate,
          requestEnd: this.endDate,
          requestYear: this.requestYear,
          hoursRequested: this.hoursRequested,
          reason: this.reason,
          providerNote: this.providerNote,
          adminNote: this.adminNote,
          sessions: this.sessions,
        });
        message = await this.$store.dispatch(
          "paidSickLeaveModule/submitNewRequest",
          request
        );
      }

      this.$store.commit("uxModule/setShowLoader", false);
      if (message) {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          `Failed to submit request: ${message}`
        );
      } else {
        this.$router.push({ name: "Provider Requests" });
        this.$store.commit("uxModule/setSnackbarMsg", "Request submitted");
      }
      this.$store.commit("uxModule/setShowSnackbar", true);
    },
  },
};
</script>
