<template>
  <v-container>
    <v-row>
      <v-radio-group v-model="query" label="Search for sessions by..." row>
        <v-radio
          v-for="query in sessionQueries"
          :key="query"
          :label="query"
          :value="query"
        ></v-radio>
      </v-radio-group>
    </v-row>

    <v-row v-if="showProviderField">
      <v-col cols="4">
        <v-autocomplete
          label="Provider"
          v-model="providerId"
          :items="providerOptions"
          clearable
          hide-details
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row v-if="showClientField">
      <v-col cols="4">
        <v-autocomplete
          label="Client"
          v-model="clientId"
          :items="clientOptions"
          clearable
          hide-details
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row v-if="showDateField">
      <v-col cols="4">
        <date-picker
          :value="date"
          :field="{ label: 'Date of Service' }"
          @fieldChange="date = $event"
        ></date-picker>
      </v-col>
    </v-row>
    <v-row v-if="showDateRangeFields">
      <v-col cols="2">
        <date-picker
          :value="startDate"
          :field="{ label: 'Start Date' }"
          @fieldChange="startDate = $event"
        ></date-picker>
      </v-col>
      <v-col cols="2">
        <date-picker
          :value="endDate"
          :field="{ label: 'End Date' }"
          @fieldChange="endDate = $event"
        ></date-picker>
      </v-col>
    </v-row>
    <v-row v-if="showLegacyActivityField">
      <v-col cols="3">
        <v-text-field
          label="Legacy Activity ID"
          v-model="legacyActivityId"
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="showSessionField">
      <v-col cols="3">
        <v-text-field
          label="Session ID"
          v-model="sessionId"
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <v-btn
          @click="lookupSession"
          color="primary"
          :dark="!loading"
          rounded
          small
          :loading="loading"
          :disabled="loading"
        >
          Lookup Sessions
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { SessionQueries } from "../../objs/SessionQueries";
export default {
  data: function () {
    return {
      sessionQueries: Object.values(SessionQueries),
      query: SessionQueries.PROVIDER_AND_DATE,
      providerId: "",
      clientId: "",
      date: "",
      startDate: "",
      endDate: "",
      legacyActivityId: "",
      sessionId: "",
      loading: false,
    };
  },
  computed: {
    providerOptions: function () {
      return this.$store.state.optionModule.providers;
    },
    clientOptions: function () {
      return this.$store.state.optionModule.clients;
    },
    showProviderField: function () {
      return [
        SessionQueries.PROVIDER_AND_CLIENT,
        SessionQueries.PROVIDER_AND_DATE,
        SessionQueries.PROVIDER_AND_DATE_RANGE,
      ].includes(this.query);
    },
    showClientField: function () {
      return [
        SessionQueries.PROVIDER_AND_CLIENT,
        SessionQueries.CLIENT,
      ].includes(this.query);
    },
    showDateField: function () {
      return [
        SessionQueries.PROVIDER_AND_DATE,
        SessionQueries.DATE_OF_SERVICE,
      ].includes(this.query);
    },
    showDateRangeFields: function () {
      return SessionQueries.PROVIDER_AND_DATE_RANGE === this.query;
    },
    showLegacyActivityField: function () {
      return SessionQueries.LEGACY_ACTIVITY_ID === this.query;
    },
    showSessionField: function () {
      return SessionQueries.SESSION_ID === this.query;
    },
  },
  methods: {
    async lookupSession() {
      let success = false;
      this.loading = true;

      switch (this.query) {
        case SessionQueries.PROVIDER_AND_DATE:
          success = await this.$store.dispatch(
            "sessionModule/lookupSessionByProviderAndDate",
            { providerId: this.providerId, dateOfService: this.date }
          );
          break;
        case SessionQueries.PROVIDER_AND_DATE_RANGE:
          success = await this.$store.dispatch(
            "sessionModule/lookupSessionByProviderAndDateRange",
            {
              providerId: this.providerId,
              startDate: this.startDate,
              endDate: this.endDate,
            }
          );
          break;
        case SessionQueries.PROVIDER_AND_CLIENT:
          success = await this.$store.dispatch(
            "sessionModule/lookupSessionByProviderAndClient",
            { providerId: this.providerId, clientId: this.clientId }
          );
          break;
        case SessionQueries.CLIENT:
          success = await this.$store.dispatch(
            "sessionModule/lookupSessionByClient",
            { clientId: this.clientId }
          );
          break;
        case SessionQueries.DATE_OF_SERVICE:
          success = await this.$store.dispatch(
            "sessionModule/lookupSessionByDateOfService",
            { dateOfService: this.date }
          );
          break;
        case SessionQueries.LEGACY_ACTIVITY_ID:
          success = await this.$store.dispatch(
            "sessionModule/lookupSessionByLegacyActivityId",
            { legacyActivityId: this.legacyActivityId }
          );
          break;
        case SessionQueries.SESSION_ID:
          success = await this.$store.dispatch(
            "sessionModule/lookupSessionBySessionId",
            { sessionId: this.sessionId }
          );
          break;
        case SessionQueries.MISSING_REMOTE_ATTENDANCE_LOG:
          success = await this.$store.dispatch(
            "sessionModule/lookupSessionsMissingRemoteAttendanceLog"
          );
          break;
      }

      this.loading = false;

      if (success) {
        this.$emit("sessionsFound");
      } else {
        this.$store.commit("uxModule/setSnackbarMsg", "No sessions found.");
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
    },
  },
};
</script>
