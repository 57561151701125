var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-3 mx-auto",attrs:{"headers":_vm.headers,"items":_vm.fpayrollBatches},scopedSlots:_vm._u([{key:"item.totalPayout",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(_vm.formatCurrency(item.totalPayout)))])]}},{key:"item.createdOn",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(_vm.formatDate(item.createdOn)))])]}},{key:"item.closedOn",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(_vm.formatDate(item.closedOn)))])]}},{key:"item.paycheckDate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(_vm.formatDate(item.paycheckDate)))])]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(_vm.formatDate(item.startDate)))])]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(_vm.formatDate(item.endDate)))])]}},{key:"item.continueAddingProviders",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-checkbox',{staticClass:"pl-10",attrs:{"text":"","x-small":"","color":"primary","disabled":Boolean(item.closedOn)},on:{"change":function($event){return _vm.updateAutoAdd(item)}},model:{value:(item.continueAddingProviders),callback:function ($$v) {_vm.$set(item, "continueAddingProviders", $$v)},expression:"item.continueAddingProviders"}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[(!Boolean(item.closedOn))?_c('v-btn',{staticClass:"pt-1",attrs:{"text":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.processBatch(item)}}},[_vm._v(" Process ")]):_vm._e(),(Boolean(item.closedOn))?_c('v-btn',{staticClass:"pt-1",attrs:{"text":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.reviewBatch(item)}}},[_vm._v(" Review ")]):_vm._e(),_c('v-btn',{staticClass:"pt-1",attrs:{"text":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.reportsBatch(item)}}},[_vm._v(" Reports ")]),_c('v-btn',{staticClass:"pt-1",attrs:{"text":"","x-small":"","color":"primary","disabled":Boolean(item.closedOn)},on:{"click":function($event){return _vm.closeBatch(item)}}},[_vm._v(" Close ")]),_c('emails-menu',{attrs:{"payrollBatch":item}})],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }