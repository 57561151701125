<template>
  <validation-observer v-slot="{ invalid }">
    <v-row>
      <label style="font-weight: bold">Report range:</label>
    </v-row>
    <v-row>
      <v-col cols="3">
        <date-picker
          :value="startDate"
          :field="{ label: 'Start Date' }"
          @fieldChange="onStartDateChange"
          :validations="validations.startDate"
        ></date-picker>
      </v-col>
      <v-col cols="3">
        <date-picker
          :value="endDate"
          :field="{ label: 'End Date' }"
          @fieldChange="onEndDateChange"
          :validations="validations.endDate"
        ></date-picker>
      </v-col>
    </v-row>

    <v-row>
      <label style="font-weight: bold">Report filters:</label>
    </v-row>

    <v-row>
      <v-col cols="3">
        <v-autocomplete
          clearable
          label="Provider"
          :items="providerOptions"
          v-model="selectedProvider"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          clearable
          label="Client"
          :items="clientOptions"
          v-model="selectedClient"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-autocomplete
          clearable
          label="School"
          :items="schoolOptions"
          v-model="selectedSchool"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          clearable
          label="Team"
          :items="teamOptions"
          :item-text="
            (item) => `${item.leaderFirstName} ${item.leaderLastName}`
          "
          item-value="teamId"
          v-model="selectedTeam"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-autocomplete
          clearable
          multiple
          chips
          deletable-chips
          label="Programs"
          v-model="selectedPrograms"
          :items="programOptions"
          item-text="code"
          item-value="programId"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          clearable
          multiple
          chips
          deletable-chips
          label="Services"
          v-model="selectedServices"
          :items="serviceOptions"
          item-text="code"
          item-value="serviceId"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-select
          clearable
          label="Location"
          :items="locationOptions"
          v-model="selectedLocation"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-checkbox v-model="includeProviderSSN" label="Include SSN"></v-checkbox>
    </v-row>

    <v-row>
      <v-btn
        text
        color="primary"
        outlined
        rounded
        :disabled="invalid"
        @click="downloadDetailsReport"
        class="my-2"
        >Download Details Report</v-btn
      >
    </v-row>
  </validation-observer>
</template>

<script>
import { required, minDate } from "../../plugins/vee-validate/validation";
import { downloadFile } from "../../utils/download-utils";
import { formatDate } from "../../utils/format-utils";
export default {
  data: function () {
    return {
      startDate: "",
      endDate: "",
      selectedProvider: 0,
      selectedClient: 0,
      selectedSchool: 0,
      selectedTeam: 0,
      selectedPrograms: [],
      selectedServices: [],
      selectedLocation: "",
      includeProviderSSN: false,
    };
  },
  computed: {
    validations() {
      return {
        startDate: { required: true },
        endDate: {
          required: true,
          minDate: this.startDate,
        },
      };
    },
    providerOptions: function () {
      return this.$store.state.optionModule.providers;
    },
    clientOptions: function () {
      return this.$store.state.optionModule.clients;
    },
    schoolOptions() {
      return this.$store.state.reportsModule.schools;
    },
    teamOptions() {
      return this.$store.state.reportsModule.teams;
    },
    programOptions() {
      return this.$store.state.optionModule.programs;
    },
    serviceOptions() {
      return this.$store.state.optionModule.services;
    },
    locationOptions() {
      return this.$store.state.reportsModule.locationOptions;
    },
  },
  methods: {
    onStartDateChange(event) {
      this.startDate = event;
    },
    onEndDateChange(event) {
      this.endDate = event;
    },
    async downloadDetailsReport() {
      this.$store.commit("uxModule/setShowLoader", true);
      var response = await this.$store.dispatch(
        "reportsModule/getExpensesDetailsByDosReport",
        {
          start: this.startDate,
          end: this.endDate,
          provider: this.selectedProvider,
          client: this.selectedClient,
          school: this.selectedSchool,
          team: this.selectedTeam,
          programs: this.selectedPrograms,
          services: this.selectedServices,
          location: this.selectedLocation,
          includeProviderSSN: this.includeProviderSSN,
        }
      );
      if (response) {
        await downloadFile(
          response,
          this.getFileName("ExpensesDetailsByDateOfServiceReport")
        );
      } else {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Oops! Something went wrong. Please try again later."
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
      this.$store.commit("uxModule/setShowLoader", false);
    },
    getFileName(label) {
      return (
        label +
        " - " +
        formatDate(this.startDate) +
        " through " +
        formatDate(this.endDate) +
        ".csv"
      );
    },
  },
};
</script>
