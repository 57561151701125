import { formatCurrency, formatDate } from "../utils/format-utils";

export default class ProviderRemittance {
  remittanceReportId?: number | null;
  endDate?: Date | null;
  paycheckDate?: Date | null;
  totalPayout?: number | null;
  fileId?: number | null;
  public constructor(params: ProviderRemittance = {} as ProviderRemittance) {
    let {
      remittanceReportId = 0,
      endDate = new Date(),
      paycheckDate = new Date(),
      totalPayout = 0,
      fileId = 0,
    } = params;
    this.remittanceReportId = remittanceReportId;
    this.endDate = endDate;
    this.paycheckDate = paycheckDate;
    this.totalPayout = totalPayout;
    this.fileId = fileId;
  }

  get formattedEndDate() {
    if (this.endDate) return formatDate(this.endDate);
  }

  get formattedPaycheckDate() {
    if (this.paycheckDate) return formatDate(this.paycheckDate);
  }

  get formattedTotalPayout() {
    return formatCurrency(this.totalPayout);
  }

  get fileName() {
    let formattedDateForFilename = this.formattedEndDate?.replace(/\//g, "-");
    return `PayrollPeriodEnding_${formattedDateForFilename}.pdf`;
  }
}
