<template>
  <div>
    <v-row>
      <v-col cols="10">
        <div class="d-flex">
          <v-btn
            text
            plain
            color="primary"
            link
            :to="{ name: 'Current batch' }"
          >
            {{ batchLabel }}
          </v-btn>

          <v-icon color="primary"> mdi-menu-right </v-icon>
          <v-btn text plain color="primary">
            {{ providerLabel }}
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-toolbar color="primary" dark class="mt-3">
      <v-tabs v-model="tabs" fixed-tabs>
        <v-tab :href="`#tab-payable`">
          <span :style="payableStyle">Payable Session Charges</span>
        </v-tab>

        <v-tab :href="`#tab-nonpayable`">
          <span :style="nonpayableStyle">Non-payable Session Charges</span>
        </v-tab>

        <v-tab :href="`#tab-deferred`">
          <span :style="deferredStyle">Deferred Session Charges</span>
        </v-tab>

        <v-tab :href="`#tab-otherearnings`">
          <span :style="otherStyle">Other Earnings</span>
        </v-tab>

        <v-tab :href="`#tab-adjustments`">
          <span :style="adjustmentStyle">Adjustments</span>
        </v-tab>
      </v-tabs>
    </v-toolbar>

    <v-tabs-items v-model="tabs" fixed-tabs>
      <v-tab-item :value="`tab-payable`">
        <v-card flat style="width: 95vw">
          <v-card-text>
            <session-table :payable="true"></session-table>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item :value="`tab-nonpayable`">
        <v-card flat style="width: 95vw">
          <v-card-text>
            <session-table :nonpayable="true"></session-table>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item :value="`tab-deferred`">
        <v-card flat style="width: 95vw">
          <v-card-text>
            <session-table :deferred="true"></session-table>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item :value="`tab-otherearnings`">
        <v-card flat style="width: 95vw">
          <v-card-text>
            <other-earnings-charges-table
              :chargeList="otherEarningsChargeList"
              :isProvider="true"
            ></other-earnings-charges-table>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item :value="`tab-adjustments`">
        <v-card flat style="width: 95vw">
          <v-card-text>
            <session-table :adjustments="true"></session-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import SessionTable from "./session-charges-table";
import OtherEarningsChargesTable from "../other-earnings/other-earnings-charges-table";
import { formatCurrency } from "../../utils/format-utils";

export default {
  components: {
    SessionTable,
    OtherEarningsChargesTable,
  },
  data: function () {
    return {
      tabs: null,
      highlightStyle: "color: #05c9ff",
      regularStyle: "color: #696969",
    };
  },
  mounted() {
    if (this.sessionCharges.payable > 0) {
      this.tabs = "tab-payable";
    } else if (this.sessionCharges.nonpayable > 0) {
      this.tabs = "tab-nonpayable";
    } else if (this.sessionCharges.deferred > 0) {
      this.tabs = "tab-deferred";
    } else if (this.sessionCharges.other > 0) {
      this.tabs = "tab-otherearnings";
    } else if (this.sessionCharges.adjustment > 0) {
      this.tabs = "tab-adjustments";
    }
  },
  computed: {
    payableStyle() {
      return this.sessionCharges.payable > 0
        ? this.highlightStyle
        : this.regularStyle;
    },
    nonpayableStyle() {
      return this.sessionCharges.nonpayable > 0
        ? this.highlightStyle
        : this.regularStyle;
    },
    adjustmentStyle() {
      return this.sessionCharges.adjustment > 0
        ? this.highlightStyle
        : this.regularStyle;
    },
    deferredStyle() {
      return this.sessionCharges.deferred > 0
        ? this.highlightStyle
        : this.regularStyle;
    },
    otherStyle() {
      return this.sessionCharges.other > 0
        ? this.highlightStyle
        : this.regularStyle;
    },
    batchLabel() {
      return this.$store.getters["payrollBatchModule/getCurrentBatchName"];
    },
    providerLabel: function () {
      return (
        this.currentRemittanceReport.providerName +
        ": " +
        formatCurrency(this.currentRemittanceReport.totalPayout)
      );
    },
    currentRemittanceReport: function () {
      return this.$store.state.payrollBatchModule.currentRemittanceReport;
    },
    sessionCharges: function () {
      var deferredCharges =
        this.$store.state.payrollBatchModule.currentRemittanceReport
          .deferredSessionCharges;
      var payableCharges =
        this.$store.getters["payrollBatchModule/getPayableCharges"];
      var nonpayableChargeList =
        this.$store.getters["payrollBatchModule/getNonPayableCharges"];
      var adjustmentCharges =
        this.$store.getters["payrollBatchModule/getAdjustments"];
      var otherCharges =
        this.$store.state.payrollBatchModule.currentRemittanceReport
          .otherEarnings;

      return {
        payable: payableCharges.length,
        nonpayable: nonpayableChargeList.length,
        adjustment: adjustmentCharges.length,
        other: otherCharges.length,
        deferred: deferredCharges.length,
      };
    },
    otherEarningsChargeList() {
      var list =
        this.$store.state.payrollBatchModule.currentRemittanceReport
          .otherEarnings;
      list.forEach((t) => (t.chargeDate = new Date(Date.parse(t.chargeDate))));
      return list;
    },
  },
};
</script>
