import { formatCurrency, formatDate, formatTime } from "../utils/format-utils";

export default class SessionCharge {
  sessionChargeId?: number | null;
  sessionId?: number | null;
  remittanceReportId?: number | null;
  remittanceReportDeferredFromId?: number | null;
  activityId?: number | null;
  languageId?: number | null;
  durationMinutes?: number | null;
  groupSize?: number | null;
  locationId?: number | null;
  serviceId?: number | null;
  programId?: number | null;
  clientId?: number | null;
  calculatedAmount?: number | null;
  adjustedAmount?: number | null;
  rateId?: number | null;
  dateOfService?: Date | string | null;
  startTime?: Date | string | null;
  endTime?: Date | string | null;
  isBilingual?: boolean | null;
  missingRate?: boolean | null;
  isNonbillable?: boolean | null;
  isDenied?: boolean | null;
  language?: string | null;
  location?: string | null;
  locationCategory?: string | null;
  service?: string | null;
  program?: string | null;
  clientFirstName?: string | null;
  clientLastName?: string | null;
  providerId?: number | null;
  providerFirstName?: string | null;
  providerLastName?: string | null;
  rateSummary?: string | null;
  adjustmentExplanation?: string | null;
  denialExplanation?: string | null;
  flag?: string | null;
  isEval?: boolean | null;
  evalInvoiceReceived?: boolean | null;
  remoteAttendanceLogReceived?: boolean | null;
  payrollBatchDeferredFromId?: number | null;
  payrollBatch?: string | null;
  payrollBatchDeferredFrom?: string | null;
  paidOn?: Date | null;
  isPaid?: boolean | null;
  sessionStatus?: string | null;
  isAdjustment?: boolean | null;
  isEvalInvoiceRequired?: boolean | null;
  isRemoteAttendanceLogRequired?: boolean | null;

  public constructor(params: SessionCharge = {} as SessionCharge) {
    let {
      sessionChargeId = 0,
      sessionId = 0,
      remittanceReportId = 0,
      remittanceReportDeferredFromId = 0,
      activityId = 0,
      languageId = 0,
      durationMinutes = 0,
      groupSize = 0,
      locationId = 0,
      serviceId = 0,
      programId = 0,
      clientId = 0,
      clientFirstName = "",
      clientLastName = "",
      providerId = 0,
      providerFirstName = "",
      providerLastName = "",
      calculatedAmount = 0,
      adjustedAmount = 0,
      rateId = 0,
      dateOfService = null,
      startTime = null,
      endTime = null,
      isBilingual = false,
      missingRate = false,
      isNonbillable = false,
      isDenied = false,
      language = "",
      location = "",
      locationCategory = "",
      service = "",
      program = "",
      rateSummary = "",
      adjustmentExplanation = "",
      denialExplanation = "",
      flag = "",
      isEval = false,
      evalInvoiceReceived = false,
      remoteAttendanceLogReceived = false,
      payrollBatchDeferredFromId = 0,
      payrollBatch = "",
      payrollBatchDeferredFrom = "",
      paidOn = null,
      isPaid = false,
      sessionStatus = "",
      isAdjustment = false,
      isEvalInvoiceRequired = false,
      isRemoteAttendanceLogRequired = false,
    } = params;
    this.sessionChargeId = sessionChargeId;
    this.sessionId = sessionId;
    this.remittanceReportId = remittanceReportId;
    this.remittanceReportDeferredFromId = remittanceReportDeferredFromId;
    this.activityId = activityId;
    this.languageId = languageId;
    this.durationMinutes = durationMinutes;
    this.groupSize = groupSize;
    this.locationId = locationId;
    this.serviceId = serviceId;
    this.programId = programId;
    this.clientId = clientId;
    this.clientFirstName = clientFirstName;
    this.clientLastName = clientLastName;
    this.providerId = providerId;
    this.providerFirstName = providerFirstName;
    this.providerLastName = providerLastName;
    this.calculatedAmount = calculatedAmount;
    this.adjustedAmount = adjustedAmount;
    this.rateId = rateId;
    this.dateOfService = formatDate(dateOfService);
    this.startTime = formatTime(startTime);
    this.endTime = formatTime(endTime);
    this.isBilingual = isBilingual;
    this.missingRate = missingRate;
    this.isNonbillable = isNonbillable;
    this.isDenied = isDenied;
    this.language = language;
    this.location = location;
    this.locationCategory = locationCategory;
    this.service = service;
    this.program = program;
    this.rateSummary = rateSummary;
    this.adjustmentExplanation = adjustmentExplanation;
    this.denialExplanation = denialExplanation;
    this.flag = flag;
    this.isEval = isEval;
    this.evalInvoiceReceived = evalInvoiceReceived;
    this.remoteAttendanceLogReceived = remoteAttendanceLogReceived;
    this.payrollBatchDeferredFromId = payrollBatchDeferredFromId;
    this.payrollBatch = payrollBatch;
    this.payrollBatchDeferredFrom = payrollBatchDeferredFrom;
    this.paidOn = paidOn;
    this.isPaid = isPaid;
    this.sessionStatus = sessionStatus;
    this.isAdjustment = isAdjustment;
    this.isEvalInvoiceRequired = isEvalInvoiceRequired;
    this.isRemoteAttendanceLogRequired = isRemoteAttendanceLogRequired;
  }

  get clientFullName() {
    return this.clientLastName + ", " + this.clientFirstName;
  }

  get providerFullName() {
    return this.providerLastName + ", " + this.providerFirstName;
  }

  get formattedCalculatedAmount() {
    return formatCurrency(this.calculatedAmount);
  }

  get formattedAdjustedAmount() {
    return formatCurrency(this.adjustedAmount);
  }

  get missingEvalInvoice() {
    return this.isEvalInvoiceRequired && !this.evalInvoiceReceived;
  }
}
