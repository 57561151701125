<template>
  <v-container>
    <div class="d-flex pb-10 mb-5">
      <v-btn
        text
        plain
        color="primary"
        link
        :to="{ name: 'Scheduled Deductions' }"
      >
        Other Earnings
      </v-btn>
      <v-icon color="primary"> mdi-menu-right </v-icon>
      <v-btn text plain color="primary">
        {{ $route.name }}
      </v-btn>
    </div>
    <v-toolbar flat color="white">
      <v-toolbar-title>New Scheduled Deduction</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
    </v-toolbar>
    <validation-observer v-slot="{ invalid }" class="mt-5">
      <v-row>
        <v-col cols="12" md="6">
          <validation-provider
            :rules="validations.providerId"
            v-slot="{ errors }"
          >
            <v-select
              :items="providers"
              item-text="text"
              item-value="value"
              label="Provider"
              v-model="form.providerId"
              dense
              :error-messages="errors"
            ></v-select>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <validation-provider
            :rules="validations.omniCorporationDivisionId"
            v-slot="{ errors }"
          >
            <v-select
              :items="omniCorporationDivisions"
              item-text="description"
              item-value="omniCorporationDivisionId"
              label="Omni Corporations"
              v-model="form.omniCorporationDivisionId"
              dense
              :error-messages="errors"
            ></v-select>
          </validation-provider>
        </v-col>
        <v-col cols="12" md="3">
          <validation-provider
            :rules="validations.earningsCodeId"
            v-slot="{ errors }"
          >
            <v-select
              :items="deductionTypes"
              item-text="name"
              item-value="earningsCodeId"
              label="Deduction Type"
              v-model="form.earningsCodeId"
              dense
              :error-messages="errors"
            ></v-select>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="2">
          <v-switch
            v-model="form.isActive"
            label="Activation Status"
            style="margin-top: 0"
          ></v-switch>
        </v-col>

        <v-col cols="12" md="2">
          <date-picker
            :value="form.effectiveDate"
            :field="{ label: 'Effective Date' }"
            @fieldChange="changeEffectiveDate"
          >
          </date-picker>
        </v-col>

        <v-col cols="12" md="2">
          <validation-provider :rules="validations.amount" v-slot="{ errors }">
            <v-text-field
              v-model="form.amount"
              label="Amount"
              dense
              prefix="$"
              type="number"
              min="0"
              step="0.01"
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cancel"> Cancel </v-btn>
        <v-btn color="primary" @click="saveDeduction()" :disabled="invalid">
          Save
        </v-btn>
      </v-row>
    </validation-observer>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { required, minValue } from "../../plugins/vee-validate/validation";
import ScheduledDeduction from "../../objs/ScheduledDeduction";

export default {
  name: "NewScheduledDeduction",
  props: ["scheduledDeduction"],
  data: () => ({
    form: new ScheduledDeduction(),
  }),
  computed: {
    ...mapGetters({
      providers: "providerModule/getProviderOptions",
      earningsCodes: "optionModule/earningsCodes",
      omniCorporationDivisions: "optionModule/omniCorporationDivisions",
    }),
    deductionTypes() {
      return this.earningsCodes.filter((item) => {
        return item.canSchedule;
      });
    },
    uxData() {
      if (this.form.scheduledDeductionId > 0) {
        return {
          loaderText: "Updating scheduled deduction...",
          path: "otherEarningsModule/updateScheduledDeduction",
          snackText: "Updated scheduled deduction successfully!",
        };
      }
      return {
        loaderText: "Creating scheduled deduction...",
        path: "otherEarningsModule/createScheduledDeduction",
        snackText: "Create new scheduled deduction successfully!",
      };
    },
    validations() {
      return {
        amount: {
          required: true,
          minValue: 0,
        },
        earningsCodeId: { required: true },
        providerId: { required: true },
        effectiveDate: { required: true },
        isActive: { required: true },
        omniCorporationDivisionId: { required: true },
      };
    },
  },

  async created() {
    if (this.providers.length === 0)
      await this.$store.dispatch("providerModule/getAllProviders");
  },
  mounted() {
    this.form = {
      ...this.form,
      ...this.scheduledDeduction,
    };
  },
  methods: {
    changeEffectiveDate(date) {
      this.form.effectiveDate = date;
    },
    cancel() {
      this.$router.push({
        name: "Scheduled Deductions",
      });
    },
    async saveDeduction() {
      this.$store.dispatch("uxModule/showLoader", this.uxData.loaderText);
      const success = await this.$store.dispatch(this.uxData.path, {
        ...this.form,
      });

      this.$store.dispatch("uxModule/hideLoader");

      if (success)
        this.$store.dispatch("uxModule/showSnackBarMsg", this.uxData.snackText);
      else this.$store.dispatch("uxModule/showSnackBarMsg", "Raised an error!");

      this.$router.push({
        name: "Scheduled Deductions",
      });
    },
  },
};
</script>
