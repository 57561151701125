<template>
  <v-container>
    <div class="d-flex pb-10 mb-5">
      <v-btn text plain color="primary" link :to="{ name: 'All Providers' }">
        Provider Rates
      </v-btn>
      <v-icon color="primary"> mdi-menu-right </v-icon>
      <v-btn text plain color="primary">
        {{ $route.name }}
      </v-btn>
    </div>
    <validation-observer v-slot="{ invalid }">
      <v-row>
        <v-col cols="12" md="3">
          <validation-provider :rules="validations.gender" v-slot="{ errors }">
            <v-select
              :items="genders"
              label="Gender"
              v-model="form.gender"
              dense
              :error-messages="errors"
            ></v-select>
          </validation-provider>
        </v-col>
        <v-col cols="12" md="3">
          <validation-provider :rules="validations.title" v-slot="{ errors }">
            <v-select
              :items="titles"
              label="Title"
              v-model="form.title"
              dense
              :error-messages="errors"
            ></v-select>
          </validation-provider>
        </v-col>
        <v-col cols="12" md="3">
          <validation-provider
            :rules="validations.firstName"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="form.firstName"
              label="First Name"
              dense
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>
        </v-col>
        <v-col cols="12" md="3">
          <validation-provider
            :rules="validations.lastName"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="form.lastName"
              label="Last Name"
              dense
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <validation-provider
            :rules="validations.streetAddress"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="form.streetAddress"
              label="Street address line 1"
              dense
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>
        </v-col>
        <v-col>
          <v-text-field
            v-model="form.streetAddressLine2"
            label="Street address line 2"
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <validation-provider :rules="validations.city" v-slot="{ errors }">
            <v-text-field
              v-model="form.city"
              label="City"
              dense
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>
        </v-col>
        <v-col cols="12" md="3">
          <validation-provider :rules="validations.state" v-slot="{ errors }">
            <v-select
              :items="usStates"
              label="State"
              v-model="form.state"
              dense
              :error-messages="errors"
            ></v-select>
          </validation-provider>
        </v-col>
        <v-col cols="12" md="3">
          <validation-provider :rules="validations.zipCode" v-slot="{ errors }">
            <v-text-field
              v-model="form.zipCode"
              label="Zip code"
              dense
              :error-messages="errors"
              v-mask="maskZipCode"
            ></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="3">
          <validation-provider
            :rules="validations.homePhone"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="form.homePhone"
              label="Home phone"
              dense
              :error-messages="errors"
              v-mask="maskPhone"
            ></v-text-field>
          </validation-provider>
        </v-col>
        <v-col cols="12" md="3">
          <validation-provider
            :rules="validations.mobilePhone"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="form.mobilePhone"
              label="Mobile phone"
              dense
              :error-messages="errors"
              v-mask="maskPhone"
            ></v-text-field>
          </validation-provider>
        </v-col>
        <v-col cols="12" md="3">
          <validation-provider
            :rules="validations.workPhone"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="form.workPhone"
              label="Work phone"
              dense
              :error-messages="errors"
              v-mask="maskPhone"
            ></v-text-field>
          </validation-provider>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            :items="phoneTypes"
            label="Preferred Phone"
            v-model="form.preferredPhone"
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-row v-if="phoneValidation">
        <v-col>
          <v-subheader>*At least one phone number is required.</v-subheader>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <validation-provider
            :rules="validations.emailAddress"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="form.emailAddress"
              label="Email address"
              dense
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>
        </v-col>
        <v-col cols="12" md="6">
          <validation-provider
            :rules="validations.legacyProviderId"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="form.legacyProviderId"
              label="Legacy System Id"
              dense
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <validation-provider
            :rules="validations.disciplines"
            v-slot="{ errors }"
          >
            <v-select
              :items="disciplines"
              item-text="name"
              return-object
              label="Disciplines"
              v-model="form.disciplines"
              dense
              multiple
              :error-messages="errors"
            >
            </v-select>
          </validation-provider>
        </v-col>
        <v-col cols="12" md="6">
          <validation-provider
            :rules="validations.languages"
            v-slot="{ errors }"
          >
            <v-select
              :items="languages"
              item-text="name"
              return-object
              label="Languages"
              v-model="form.languages"
              dense
              multiple
              :error-messages="errors"
            ></v-select>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <date-picker
            :value="form.appStartDate"
            :field="{ label: `App Start Date` }"
            @fieldChange="changeAppStartDate"
          ></date-picker>
        </v-col>
      </v-row>
      <v-btn color="primary" @click="save" :disabled="invalid"> Save </v-btn>
      <v-btn color="primary" text @click="close"> Cancel </v-btn>
    </validation-observer>
  </v-container>
</template>
<script>
import { required, email } from "../../plugins/vee-validate/validation";
import Provider from "../../objs/Provider";
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";

export default {
  name: "Provider",
  props: {
    providerProps: {
      type: Object,
    },
  },
  directives: { mask },
  data: () => ({
    form: new Provider(),
    maskZipCode: "#####",
    maskPhone: "(###) ###-####",
  }),
  computed: {
    ...mapGetters({
      genders: "optionModule/genders",
      titles: "optionModule/titles",
      disciplines: "optionModule/disciplines",
      languages: "optionModule/languages",
      phoneTypes: "optionModule/phoneTypes",
      usStates: "optionModule/usStates",
    }),
    uxData() {
      if (this.form.providerId > 0) {
        return {
          loaderText: "Updating Provider...",
          path: "providerModule/updateProvider",
          snackText: "Updated provider successfully!",
        };
      }
      return {
        loaderText: "Creating Provider...",
        path: "providerModule/createProvider",
        snackText: "Create new provider successfully!",
      };
    },
    validations() {
      return {
        gender: {
          required: true,
        },
        title: {
          required: true,
        },
        firstName: {
          required: true,
        },
        lastName: {
          required: true,
        },
        streetAddress: {
          required: true,
        },
        city: {
          required: true,
        },
        state: {
          required: true,
        },
        zipCode: {
          required: true,
        },
        homePhone: {
          required:
            this.form.preferredPhone === "Home" ||
            (!this.form.mobilePhone && !this.form.workPhone),
        },
        mobilePhone: {
          required:
            this.form.preferredPhone === "Mobile" ||
            (!this.form.homePhone && !this.form.workPhone),
        },
        workPhone: {
          required:
            this.form.preferredPhone === "Work" ||
            (!this.form.homePhone && !this.form.mobilePhone),
        },
        emailAddress: {
          required: true,
          email: true,
        },
        legacyProviderId: {
          required: true,
        },
        disciplines: {
          required: true,
        },
        languages: {
          required: true,
        },
        disciplines: {
          required: true,
        },
      };
    },
    phoneValidation() {
      return (
        !this.form.homePhone && !this.form.mobilePhone && !this.form.workPhone
      );
    },
  },
  created() {
    this.form = {
      ...this.form,
      ...this.providerProps,
      ...this.sample,
    };
  },
  mounted() {
    if (this.form.languages.length === 0)
      this.form.languages = [
        {
          languageId: 7,
          name: "English",
        },
      ];
  },
  methods: {
    modifyPhonenumbers() {
      this.form.homePhone &&
        (this.form.homePhone = this.form.homePhone.replace(/\D/g, ""));
      this.form.mobilePhone &&
        (this.form.mobilePhone = this.form.mobilePhone.replace(/\D/g, ""));
      this.form.workPhone &&
        (this.form.workPhone = this.form.workPhone.replace(/\D/g, ""));
    },
    async save() {
      this.modifyPhonenumbers();
      if (this.form.preferredPhone === "") this.form.preferredPhone = null;
      this.$store.dispatch("uxModule/showLoader", this.uxData.loaderText);

      const success = await this.$store.dispatch(this.uxData.path, {
        ...this.form,
      });

      this.$store.dispatch("uxModule/hideLoader");

      if (success)
        this.$store.dispatch("uxModule/showSnackBarMsg", this.uxData.snackText);
      else this.$store.dispatch("uxModule/showSnackBarMsg", "Raised an error!");

      this.$router.push({ name: "All Providers" });
    },
    changeAppStartDate(date) {
      this.form.appStartDate = date;
    },
    close() {
      this.$router.push({ name: "All Providers" });
    },
  },
};
</script>
