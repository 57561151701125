<template>
  <v-dialog v-model="dialog" width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip bottom v-bind="attrs" v-on="on">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-btn text x-small v-on="on" @click="showDialog">
              <v-icon small>mdi-check</v-icon>
            </v-btn>
          </span>
        </template>
        <span> Approve/Deny request</span>
      </v-tooltip>
    </template>

    <v-card class="primary--text">
      <v-card-title></v-card-title>
      <v-card-text class="text-body-1">
        <div class="my-2">
          Provider:
          <span class="font-weight-bold">{{ request.providerName }}</span>
        </div>
        <div class="my-2">
          Date(s):
          <span class="font-weight-bold"
            >{{ request.formattedStartDate }} -
            {{ request.formattedEndDate }}</span
          >
        </div>
        <div class="my-2">
          Total Hours:
          <span class="font-weight-bold">{{ request.hoursRequested }}</span>
        </div>
        <div class="my-2">
          Reason: <span class="font-weight-bold">{{ request.reason }}</span>
        </div>
        <div v-if="request.providerNote" class="my-2">
          Provider Note:
          <span class="font-weight-bold">{{ request.providerNote }}</span>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark text @click="denyRequest">Deny</v-btn>
        <v-btn color="primary" dark text @click="approveRequest">Approve</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["request"],
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    provider() {
      return this.request.provider;
    },
  },
  methods: {
    showDialog() {
      this.dialog = true;
    },
    denyRequest: async function () {
      let message = await this.$store.dispatch(
        "paidSickLeaveModule/denyRequest",
        {
          requestId: this.request.paidSickLeaveRequestId,
        }
      );
      if (message) {
        this.$store.commit("uxModule/setSnackbarMsg", message);
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
      this.dialog = false;
    },
    approveRequest: async function () {
      let message = await this.$store.dispatch(
        "paidSickLeaveModule/approveRequest",
        {
          requestId: this.request.paidSickLeaveRequestId,
        }
      );
      if (message) {
        this.$store.commit("uxModule/setSnackbarMsg", message);
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
      this.dialog = false;
    },
  },
};
</script>
