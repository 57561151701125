<template>
  <v-sheet height="60vh">
    <tr class="font-weight-bold">
      <td class="lg-col">Provider Name</td>
      <td class="sm-col">Provider ID</td>
      <td class="med-col">Programs</td>
      <td class="med-col">Payout</td>
      <td class="sm-col">Flagged</td>
      <td class="sm-col">Details</td>
      <td class="med-col">Last Rerun</td>
      <td class="lg-col">Actions</td>
    </tr>
    <v-divider></v-divider>
    <v-virtual-scroll
      :items="items"
      item-height="50"
      id="virtual-scroll-table"
      v-scroll.self="onScroll"
    >
      <template v-slot:default="{ item }">
        <tr
          :key="item.remittanceReportId"
          class="text-body-2 font-weight-medium"
        >
          <td class="lg-col">{{ item.providerName }}</td>
          <td class="sm-col">{{ item.legacyProviderId }}</td>
          <td class="med-col">{{ item.programs }}</td>
          <td class="med-col">{{ formatCurrency(item.totalPayout) }}</td>
          <td class="sm-col">
            <v-switch
              v-model="item.isFlagged"
              @change="flagItem(item)"
              dense
              hide-details
              :disabled="review"
            ></v-switch>
          </td>
          <td class="sm-col">
            <v-tooltip bottom v-if="item.missingRates">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs">mdi-alert-box-outline</v-icon>
              </template>
              <span>Missing Rates</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.isManual">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs">mdi-checkbook</v-icon>
              </template>
              <span>Manual Check</span>
            </v-tooltip>
            <span
              v-if="!item.missingRates && !item.isManual"
              class="grey--text darken-2 text-body-2"
              >N/A</span
            >
          </td>
          <td class="med-col">{{ formatDateAndTime(item.lastRerunAt) }}</td>
          <td class="lg-col">
            <v-row dense>
              <!-- current batch actions -->
              <rerun-modal
                v-if="!review"
                :remittanceReportId="item.remittanceReportId"
                :origStart="item.startDate"
                :origEnd="item.endDate"
              ></rerun-modal>
              <reimport-modal
                v-if="!review"
                :legacyId="item.legacyProviderId"
                :origStart="item.startDate"
                :origEnd="item.endDate"
              ></reimport-modal>
              <v-btn
                v-if="!review"
                title="Go to report"
                x-small
                text
                @click="goToRemittanceReport(item)"
              >
                <v-icon>mdi-arrow-right-bold</v-icon>
              </v-btn>
              <close-report-modal
                v-if="!review"
                :remittanceReportId="item.remittanceReportId"
              >
              </close-report-modal>
              <!-- review batch actions -->
              <preview-file-action v-if="review" :fileId="item.fileId">
              </preview-file-action>
              <download-file-action
                v-if="review"
                :fileId="item.fileId"
                :filename="reportFilename(item)"
              >
              </download-file-action>
              <revert-report-modal
                v-if="review"
                :remittanceReportId="item.remittanceReportId"
              >
              </revert-report-modal>
            </v-row>
          </td>
        </tr>
      </template>
    </v-virtual-scroll>
  </v-sheet>
</template>

<script>
import RerunModal from "./rerun-report-modal";
import ReimportModal from "./reimport-modal";
import PreviewFileAction from "../../components/Shared/preview-file-action";
import DownloadFileAction from "../../components/Shared/download-file-action";
import RevertReportModal from "./revert-report-modal";
import CloseReportModal from "./close-report-modal";
import {
  formatDateForFilename,
  formatCurrency,
  formatDateAndTime,
} from "../../utils/format-utils";
export default {
  props: ["items", "review"],
  components: {
    RerunModal,
    ReimportModal,
    PreviewFileAction,
    DownloadFileAction,
    RevertReportModal,
    CloseReportModal,
  },
  mounted() {
    document.getElementById("virtual-scroll-table").scrollTo({
      left: 0,
      top: this.$store.state.payrollBatchModule.scrollBy,
      behavior: "smooth",
    });
  },
  data: function () {
    return {
      debounce: 0,
    };
  },
  methods: {
    formatCurrency,
    formatDateAndTime,
    onScroll(e) {
      // debounce if scrolling fast
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.$store.commit(
          "payrollBatchModule/updateScroll",
          e.target.scrollTop
        );
      }, 20);
    },
    flagItem(item) {
      this.$store.dispatch("payrollBatchModule/flagRemittanceReport", {
        remittanceReportId: item.remittanceReportId,
        flag: item.isFlagged,
      });
    },
    reportFilename(item) {
      return `${
        item.legacyProviderId
      }_PayrollPeriodEnding_${formatDateForFilename(item.endDate)}.pdf`;
    },
    async goToRemittanceReport(item) {
      this.$store.commit("uxModule/setShowLoader", true);
      var provider = this.$store.getters["providerModule/getProviderById"](
        item.providerId
      );
      await this.$store.dispatch("providerModule/setSelectedProvider", {
        ...provider,
      });
      this.$store.commit(
        "payrollBatchModule/setCurrentRemittanceReportId",
        item.remittanceReportId
      );
      await this.$store.dispatch("payrollBatchModule/loadRemittanceReport");
      this.$store.commit("uxModule/setShowLoader", false);

      this.$router.push({ name: "Remittance report" });
    },
  },
};
</script>
<style>
#virtual-scroll-table {
  height: 90%;
}
#virtual-scroll-table tr {
  background: white;
}
#virtual-scroll-table tr:hover {
  background: #a5bcc9;
}
.lg-col {
  min-width: 16vw;
  width: 16vw;
  max-width: 16vw;
}
.med-col {
  min-width: 12vw;
  width: 12vw;
  max-width: 12vw;
}
.sm-col {
  min-width: 8vw;
  width: 8vw;
  max-width: 8vw;
}
</style>
