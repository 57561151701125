<template>
  <div>
    <v-radio-group :value="true" row dense>
      <v-radio label="Active Rates" :value="true"></v-radio>
    </v-radio-group>
    <v-divider class="my-5"></v-divider>
    <session-rates-report></session-rates-report>
  </div>
</template>
<script>
import SessionRatesReport from "./session-rates-report.vue";
export default {
  components: { SessionRatesReport },
  async mounted() {
    await this.$store.dispatch("reportsModule/getReportOptions");
  },
};
</script>
