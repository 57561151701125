import * as api from "../Services/api.js";
import ProviderRemittance from "../../objs/ProviderRemittance";
import Provider from "../../objs/Provider";

export const state = {
  providers: [],
  selectedProvider: new Provider(),
  sessionRates: [],
  corporations: [],
  associatedCorporations: [],
  allStaffingAgencies: [],
  otherEarnings: [],
};
export const mutations = {
  SET_PROVIDERS(state, payload) {
    state.providers = payload;
  },
  SET_SELECTED_PROVIDER(state, payload) {
    state.selectedProvider = new Provider(payload);
  },
  CREATE_PROVIDER(state, payload) {
    state.providers.push({ ...payload });
  },
  UPDATE_PROVIDER(state, payload) {
    const findIndex = state.providers.findIndex(
      ({ providerId }) => providerId === payload.providerId
    );
    state.providers.splice(findIndex, 1, { ...payload });
  },
  DELETE_PROVIDER(state, payload) {
    const findIndex = state.providers.findIndex(
      ({ providerId }) => providerId === payload
    );
    state.providers.splice(findIndex, 1);
  },
  SET_SESSION_RATES(state, payload) {
    state.sessionRates = payload;
  },
  CREATE_SESSION_RATE(state, payload) {
    state.sessionRates.push({
      ...payload,
    });
  },
  UPDATE_SESSION_RATE(state, payload) {
    const findIndex = state.sessionRates.findIndex(
      ({ sessionRateId }) => sessionRateId === payload.sessionRateId
    );
    state.sessionRates.splice(findIndex, 1, { ...payload });
  },
  DELETE_SESSION_RATE(state, payload) {
    const findIndex = state.sessionRates.findIndex(
      ({ sessionRateId }) => sessionRateId === payload
    );
    state.sessionRates.splice(findIndex, 1);
  },
  SET_CORPORATIONS(state, payload) {
    state.corporations = payload;
  },
  CREATE_CORPORATION(state, payload) {
    state.corporations.push({ ...payload });
  },
  UPDATE_CORPORATION(state, payload) {
    const findIndex = state.corporations.findIndex(
      ({ providerCorporationId }) =>
        providerCorporationId === payload.providerCorporationId
    );
    state.corporations.splice(findIndex, 1, { ...payload });
  },
  DELETE_CORPORATION(state, payload) {
    const findIndex = state.corporations.findIndex(
      ({ providerCorporationId }) => providerCorporationId === payload
    );
    state.corporations.splice(findIndex, 1);
  },
  SET_ALL_STAFFING_AGENCIES(state, payload) {
    state.allStaffingAgencies = payload;
  },
  SET_ASSOCIATED_CORPORATIONS(state, payload) {
    state.associatedCorporations = payload;
  },
  ADD_STAFFING_AGENCY(state, payload) {
    state.allStaffingAgencies.push({
      ...payload,
    });
  },
  ADD_ASSOCIATED_CORPORATIONS(state, payload) {
    state.associatedCorporations.push({
      ...payload,
    });
  },
  SET_OTHER_EARNINGS(state, payload) {
    state.otherEarnings = payload;
  },
  CREATE_OTHER_EARNING_RATE(state, payload) {
    state.otherEarnings.push({
      ...payload,
    });
  },
  UPDATE_OTHER_EARNING_RATE(state, payload) {
    const findIndex = state.otherEarnings.findIndex(
      ({ otherEarningsRateId }) =>
        otherEarningsRateId === payload.otherEarningsRateId
    );
    state.otherEarnings.splice(findIndex, 1, { ...payload });
  },
  DELETE_OTHER_EARNING_RATE(state, payload) {
    const findIndex = state.otherEarnings.findIndex(
      ({ otherEarningsRateId }) => otherEarningsRateId === payload
    );
    state.otherEarnings.splice(findIndex, 1);
  },
};
export const actions = {
  async getAllProviders({ commit }) {
    return await api.get("/provider/getAllProviders/").then((response) => {
      if (response.success) {
        commit("SET_PROVIDERS", response.data);
      }
    });
  },
  async createProvider({ commit }, data) {
    return await api
      .post("/provider/createProvider/", data)
      .then((response) => {
        response.success && commit("CREATE_PROVIDER", response.data);
        return response.success;
      });
  },
  setSelectedProvider({ commit }, data) {
    commit("SET_SELECTED_PROVIDER", data);
  },
  async updateProvider({ commit }, data) {
    return await api
      .post("/provider/updateProvider/", data)
      .then((response) => {
        response.success && commit("UPDATE_PROVIDER", response.data);
        return response.success;
      });
  },
  async importSingleProvider({ commit }, data) {
    var success = await api.postQuery("/bOEImport/importSingleProvider/", data);
    var msg = "";
    if (success) {
      await api.get("/provider/getAllProviders/").then((response) => {
        commit("SET_PROVIDERS", response.data);
      });
      msg = "Provider successfully imported.";
    } else {
      msg = "Error importing provider";
    }
    commit("uxModule/setSnackbarMsg", msg, {
      root: true,
    });
    commit("uxModule/setShowSnackbar", true, {
      root: true,
    });
  },
  async deleteProvider({ commit }, providerId) {
    return await api
      .post("/provider/deleteProvider?providerId=" + providerId)
      .then((response) => {
        response.success && commit("DELETE_PROVIDER", providerId);
        return response.success;
      });
  },

  async createProviderCorporationAssociation({ commit, state }, payload) {
    var response = await api.postQuery(
      "/providerCorporation/createProviderCorporationAssociation",
      payload
    );
    if (response.success) {
      var i;
      for (i = 0; i < state.corporations.length; i++)
        commit("uxModule/setSnackbarMsg", "Error undeferring this charge.", {
          root: true,
        });
      commit("uxModule/setShowSnackbar", true, {
        root: true,
      });
      {
        if (
          state.corporations[i].providerCorporationId ==
          payload.providerCorporationId
        ) {
          var lastProvider =
            response.data.providers[response.data.providers.length - 1];
          state.corporations[i].providers.push(lastProvider);
        }
      }
    }
    return response.success;
  },

  async clearProviderCorporationAssociation({ commit, state }, payload) {
    var response = await api.postQuery(
      "/providerCorporation/deleteProviderCorporationAssociation",
      payload
    );
    if (response.success) {
      var i;
      for (i = 0; i < state.corporations.length; i++) {
        if (
          state.corporations[i].providerCorporationId ==
          payload.providerCorporationId
        ) {
          var j;
          for (j = 0; j < state.corporations[i].providers.length; j++) {
            if (
              state.corporations[i].providers[j].providerId ==
              payload.providerId
            ) {
              state.corporations[i].providers.splice(j, 1);
            }
          }
          break;
        }
      }
    }
    return response.success;
  },

  async getSessionRatesForProvider({ commit }, providerId) {
    return await api
      .get("/sessionRate/getSessionRatesForProvider?providerId=" + providerId)
      .then((response) => {
        response.success && commit("SET_SESSION_RATES", response.data);
      });
  },
  async createSessionRate({ commit }, data) {
    let URL = "";
    //if(data.isAssign) URL = '/payrollBatch/createAndAssignRate'
    //else
    URL = "/sessionRate/createSessionRate";
    return await api.post(URL, data).then((response) => {
      if (response.success) commit("CREATE_SESSION_RATE", response.data);
      return response.success;
    });
  },
  async updateSessionRate({ commit }, data) {
    return await api
      .post("/sessionRate/updateSessionRate", data)
      .then((response) => {
        if (response.success) commit("UPDATE_SESSION_RATE", response.data);
        return response.success;
      });
  },
  async deleteSessionRate({ commit }, data) {
    return await api
      .post("/sessionRate/deleteSessionRate?sessionRateId=" + data)
      .then((response) => {
        if (response.success) {
          commit("DELETE_SESSION_RATE", data);
          return "";
        }

        return response.message;
      });
  },

  async getAllProviderCorporations({ commit }) {
    return await api
      .get("/providerCorporation/getAllProviderCorporations/")
      .then((response) => [
        response.success && commit("SET_CORPORATIONS", response.data),
      ]);
  },
  async createProviderCorporation({ commit }, data) {
    return await api
      .post("/providerCorporation/createProviderCorporation/", data)
      .then((response) => {
        if (response.success) {
          commit("CREATE_CORPORATION", response.data);
          if (response.data.isStaffingAgency)
            commit("ADD_STAFFING_AGENCY", response.data);
          else commit("ADD_ASSOCIATED_CORPORATIONS", response.data);
        }
        return response;
      });
  },
  async updateProviderCorporation({ commit }, data) {
    return await api
      .post("/providerCorporation/updateProviderCorporation/", data)
      .then((response) => {
        response.success && commit("UPDATE_CORPORATION", response.data);
        return response.success;
      });
  },
  async deleteProviderCorporation({ commit }, data) {
    return await api
      .post(
        "/providerCorporation/deleteProviderCorporation?providerCorporationId=" +
          data
      )
      .then((response) => {
        response.success && commit("DELETE_CORPORATION", data);
        return response;
      });
  },
  async getAllStaffingAgencies({ commit }) {
    return await api
      .get("/providerCorporation/getStaffingAgencies/")
      .then((response) => {
        response.success && commit("SET_ALL_STAFFING_AGENCIES", response.data);
      });
  },
  async getCorporationsForProvider({ commit }, data) {
    return await api
      .get("/providerCorporation/getCorporationsForProvider?providerId=" + data)
      .then((response) => {
        response.success &&
          commit("SET_ASSOCIATED_CORPORATIONS", response.data);
      });
  },
  async getOtherEarningRatesForProvider({ commit }, data) {
    return await api
      .get(
        "/otherEarningsRate/getOtherEarningsRatesForProvider?providerId=" + data
      )
      .then((response) => [
        response.success && commit("SET_OTHER_EARNINGS", response.data),
      ]);
  },

  async createOtherEarningsRate({ commit }, data) {
    return await api
      .post("/otherEarningsRate/createOtherEarningsRate", data)
      .then((response) => {
        if (response.success)
          commit("CREATE_OTHER_EARNING_RATE", response.data);
        return response.success;
      });
  },

  async hasMatchingRate({ commit }, data) {
    return await api
      .post("/sessionRate/matchingSessionRate", data)
      .then((response) => {
        return response.data;
      });
  },
  async updateOtherEarningsRate({ commit }, data) {
    return await api
      .post("/otherEarningsRate/updateOtherEarningsRate", data)
      .then((response) => {
        if (response.success)
          commit("UPDATE_OTHER_EARNING_RATE", response.data);
        return response.success;
      });
  },
  async deleteOtherEarningsRate({ commit }, data) {
    return await api
      .post(
        "/otherEarningsRate/deleteOtherEarningsRate?otherEarningsRateId=" + data
      )
      .then((response) => {
        if (response.success) commit("DELETE_OTHER_EARNING_RATE", data);
        return response.success;
      });
  },
  async getProviderRemittances({}, data) {
    return api
      .getQuery("/paystub/getProviderRemittances", {
        providerId: data.providerId,
      })
      .then((response) => {
        if (response.success) {
          return response.data?.map((item) => new ProviderRemittance(item));
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
};
export const getters = {
  getProviderOptions: (state) => {
    var names = new Array();
    var i;
    for (i = 0; i < state.providers.length; i++) {
      var name =
        state.providers[i].lastName + ", " + state.providers[i].firstName;
      names.push({ text: name, value: state.providers[i].providerId });
    }
    names = names.sort((a, b) => (a.text > b.text ? 1 : -1));
    return names;
  },
  getTaxIds: (state) => {
    var ids = new Array();
    var i;
    for (i = 0; i < state.corporations.length; i++) {
      ids.push(state.corporations[i].taxId);
    }
    return ids;
  },
  providers: ({ providers }) => providers,
  selectedProvider: ({ selectedProvider }) => selectedProvider,
  sessionRates: ({ sessionRates }) => sessionRates,
  corporations: ({ corporations }) => corporations,
  allStaffingAgencies: ({ allStaffingAgencies }) => allStaffingAgencies,
  associatedCorporations: ({ associatedCorporations }) =>
    associatedCorporations,
  otherEarnings: ({ otherEarnings }) => otherEarnings,
  getLegacyProviderId: (state) => (providerId) => {
    return state.providers.find((item) => item.providerId == providerId)
      ?.legacyProviderId;
  },
  getProviderById: (state) => (providerId) => {
    return state.providers.find((item) => item.providerId == providerId);
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
