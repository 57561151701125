<template>
  <div>
    <v-row>
      <label class="pt-2 font-weight-bold text-subtitle-2" color="primary"
        >Search for a session...</label
      >
      <v-btn @click="toggleExpand" icon
        ><v-icon>{{ iconText }}</v-icon></v-btn
      >
    </v-row>
    <v-row v-if="expanded" class="mt-5">
      <session-lookup @sessionsFound="sessionsFound"></session-lookup>
    </v-row>
    <v-divider class="my-12"></v-divider>
    <v-row>
      <override-modal
        v-if="selectedSessions.length > 0"
        :sessionIds="selectedSessions.map((s) => s.sessionId)"
        mode="BulkOverride"
        :noEdit="noEdit"
      ></override-modal>
    </v-row>
    <v-row>
      <session-details-table
        v-if="sessionDetails.length > 0"
        :sessions="sessionDetails"
        @rowSelected="rowSelected"
      ></session-details-table>
    </v-row>
  </div>
</template>

<script>
import SessionLookup from "./session-lookup";
import SessionDetailsTable from "./session-details-table";
import OverrideModal from "../process/override-modal";

export default {
  beforeRouteLeave(to, from, next) {
    this.$store.commit("sessionModule/clearSessionDetails");
    next();
  },
  components: {
    SessionLookup,
    SessionDetailsTable,
    OverrideModal,
  },
  data() {
    return {
      expanded: true,
      selectedSessions: [],
    };
  },
  computed: {
    noEdit() {
      return this.$store.getters["securityModule/isReadOnly"];
    },
    sessionDetails() {
      return this.$store.state.sessionModule.sessionDetails;
    },
    iconText() {
      return this.expanded
        ? "mdi-unfold-less-horizontal"
        : "mdi-unfold-more-horizontal";
    },
  },
  methods: {
    rowSelected(selected) {
      this.selectedSessions = selected;
    },
    sessionsFound() {
      this.expanded = false;
    },
    toggleExpand() {
      this.expanded = !this.expanded;
    },
  },
};
</script>
