<template>
  <validation-observer v-slot="{ invalid }">
    <v-row>
      <label style="font-weight: bold">Report range:</label>
    </v-row>
    <v-row>
      <v-col cols="3">
        <date-picker
          :value="startDate"
          :field="{ label: 'Start Date' }"
          @fieldChange="onStartDateChange"
          :validations="validations.startDate"
        ></date-picker>
      </v-col>
      <v-col cols="3">
        <date-picker
          :value="endDate"
          :field="{ label: 'End Date' }"
          @fieldChange="onEndDateChange"
          :validations="validations.endDate"
        ></date-picker>
      </v-col>
    </v-row>

    <v-row>
      <label style="font-weight: bold">Report filters:</label>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-autocomplete
          clearable
          multiple
          chips
          deletable-chips
          label="Programs"
          v-model="selectedPrograms"
          :items="programOptions"
          item-text="code"
          item-value="programId"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          clearable
          multiple
          chips
          deletable-chips
          label="Services"
          v-model="selectedServices"
          :items="serviceOptions"
          item-text="code"
          item-value="serviceId"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-autocomplete
          clearable
          multiple
          chips
          deletable-chips
          label="Sites"
          :items="siteOptions"
          v-model="selectedSites"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          clearable
          multiple
          chips
          deletable-chips
          label="Corporations"
          :items="corpOptions"
          v-model="selectedCorps"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-select
          clearable
          label="Location"
          :items="locationOptions"
          v-model="selectedLocation"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-checkbox
        v-model="includeProviderSSN"
        label="Include SSN (Details report only)"
      ></v-checkbox>
    </v-row>

    <v-row>
      <v-btn
        text
        color="primary"
        outlined
        rounded
        :disabled="invalid"
        @click="downloadReport"
        class="my-2"
        >Download Report</v-btn
      ></v-row
    >
    <v-row>
      <v-btn
        text
        color="primary"
        outlined
        rounded
        :disabled="invalid"
        @click="downloadDetailsReport"
        class="my-2"
        >Download Details Report</v-btn
      >
    </v-row>
  </validation-observer>
</template>

<script>
import { required, minDate } from "../../plugins/vee-validate/validation";
import { downloadFile } from "../../utils/download-utils";
import { formatDate } from "../../utils/format-utils";
export default {
  data: function () {
    return {
      startDate: "",
      endDate: "",
      selectedPrograms: [],
      selectedServices: [],
      selectedSites: [],
      selectedCorps: [],
      selectedLocation: "",
      includeProviderSSN: false,
    };
  },
  computed: {
    validations() {
      return {
        startDate: { required: true },
        endDate: {
          required: true,
          minDate: this.startDate,
        },
      };
    },
    programOptions() {
      return this.$store.state.optionModule.programs;
    },
    serviceOptions() {
      return this.$store.state.optionModule.services;
    },
    siteOptions() {
      return this.$store.state.reportsModule.omniSiteOptions;
    },
    corpOptions() {
      return this.$store.state.reportsModule.omniCorpOptions;
    },
    locationOptions() {
      return this.$store.state.reportsModule.locationOptions;
    },
  },
  methods: {
    onStartDateChange(event) {
      this.startDate = event;
    },
    onEndDateChange(event) {
      this.endDate = event;
    },
    async downloadReport() {
      this.$store.commit("uxModule/setShowLoader", true);
      var response = await this.$store.dispatch(
        "reportsModule/getExpensesByPaydateReport",
        {
          start: this.startDate,
          end: this.endDate,
          programs: this.selectedPrograms,
          services: this.selectedServices,
          omniSites: this.selectedSites,
          omniCorps: this.selectedCorps,
          location: this.selectedLocation,
        }
      );
      if (response) {
        await downloadFile(
          response,
          this.getFileName("ExpensesByPaymentDateReport")
        );
      } else {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Oops! Something went wrong. Please try again later."
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
      this.$store.commit("uxModule/setShowLoader", false);
    },
    async downloadDetailsReport() {
      this.$store.commit("uxModule/setShowLoader", true);
      var response = await this.$store.dispatch(
        "reportsModule/getExpensesDetailsByPaydateReport",
        {
          start: this.startDate,
          end: this.endDate,
          programs: this.selectedPrograms,
          services: this.selectedServices,
          omniSites: this.selectedSites,
          omniCorps: this.selectedCorps,
          location: this.selectedLocation,
          includeProviderSSN: this.includeProviderSSN,
        }
      );
      if (response) {
        await downloadFile(
          response,
          this.getFileName("ExpensesDetailsByPaymentDateReport")
        );
      } else {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Oops! Something went wrong. Please try again later."
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
      this.$store.commit("uxModule/setShowLoader", false);
    },
    getFileName(label) {
      return (
        label +
        " - " +
        formatDate(this.startDate) +
        " through " +
        formatDate(this.endDate) +
        ".csv"
      );
    },
  },
};
</script>
