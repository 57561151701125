<template>
  <div>
    <v-menu top close-on-click>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          :dark="Boolean(payrollBatch.closedOn)"
          text
          class="pb-3"
          :disabled="!Boolean(payrollBatch.closedOn)"
          v-bind="attrs"
          v-on="on"
        >
          Emails
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="generatePdfs">
          <v-list-item-title>Generate PDF reports</v-list-item-title>
        </v-list-item>

        <v-list-item @click="sendEmails">
          <v-list-item-title>Send email notifications</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: ["payrollBatch"],
  methods: {
    async generatePdfs() {
      this.$store.commit("uxModule/setLoaderMsg", "Generating pdfs..");
      this.$store.commit("uxModule/setShowLoader", true);

      let failedIds = await this.$store.dispatch(
        "payrollBatchModule/generatePdfs",
        this.payrollBatch.payrollBatchId
      );

      let message = failedIds
        ? `PDF reports successfully generated. Total failures ${failedIds.length}`
        : "Error generating PDF reports!";
      this.$store.commit("uxModule/setShowLoader", false);
      this.$store.commit("uxModule/setSnackbarMsg", message);
      this.$store.commit("uxModule/setShowSnackbar", true);
    },
    async sendEmails() {
      this.$store.commit(
        "uxModule/setLoaderMsg",
        "Sending remittance notification emails.."
      );
      this.$store.commit("uxModule/setShowLoader", true);

      let failedEmails = await this.$store.dispatch(
        "payrollBatchModule/sendRemittanceNotificationEmails",
        this.payrollBatch.payrollBatchId
      );

      let message = failedEmails
        ? `Emails successfully sent. Total failures ${failedEmails.length}`
        : "Error sending emails!";
      this.$store.commit("uxModule/setShowLoader", false);
      this.$store.commit("uxModule/setSnackbarMsg", message);
      this.$store.commit("uxModule/setShowSnackbar", true);
    },
  },
};
</script>
