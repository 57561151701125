<template>
  <v-menu top offset-y close-on-click>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        outlined
        :dark="!Boolean(noSelectedCharges)"
        :disabled="Boolean(noSelectedCharges)"
        v-bind="attrs"
        v-on="on"
      >
        Bulk Actions
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        :disabled="!hasOtherReportInBatch"
        @click="moveToOtherReport"
      >
        <v-list-item-icon><v-icon>mdi-arrow-top-left</v-icon></v-list-item-icon>
        <v-list-item-title>Move to other report</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  props: ["selectedCharges"],
  computed: {
    noSelectedCharges: function () {
      return this.selectedCharges.length < 1;
    },
    hasOtherReportInBatch: function () {
      return this.$store.getters[
        "payrollBatchModule/isMultipleReportsForProviderInCurrentBatch"
      ];
    },
  },
  methods: {
    moveToOtherReport: async function () {
      this.$store.commit("uxModule/setShowLoader", true);
      let otherChargeIds =
        this.selectedCharges?.map((item) => item.otherEarningsChargeId) || [];
      let success = await this.$store.dispatch(
        "payrollBatchModule/moveOtherEarningsChargesToOtherReport",
        { otherEarningsChargeIds: otherChargeIds }
      );

      this.$store.commit("uxModule/setShowLoader", false);
      let message = success
        ? "Other earnings charges moved successfully."
        : "Failed to move other earnings charges";
      this.$store.commit("uxModule/setSnackbarMsg", message);
      this.$store.commit("uxModule/setShowSnackbar", true);
    },
  },
};
</script>
