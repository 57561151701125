var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isProvider)?_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"clearable":"","items":_vm.nameOptions,"label":"Search by provider"},model:{value:(_vm.nameFilter),callback:function ($$v) {_vm.nameFilter=$$v},expression:"nameFilter"}})],1),_c('v-col',{attrs:{"cols":"2"}},[(!_vm.isMedical)?_c('v-select',{attrs:{"items":_vm.codeFilterOptions,"item-text":"name","item-value":"earningsCodeId","label":"Charge Type","clearable":""},model:{value:(_vm.codeFilter),callback:function ($$v) {_vm.codeFilter=$$v},expression:"codeFilter"}}):_vm._e(),(_vm.isMedical)?_c('v-select',{attrs:{"items":_vm.codeFilterOptions,"label":"Charge Type","clearable":""},model:{value:(_vm.codeFilter),callback:function ($$v) {_vm.codeFilter=$$v},expression:"codeFilter"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.paidFilterOptions,"label":"Payment Status","clearable":""},model:{value:(_vm.paidFilter),callback:function ($$v) {_vm.paidFilter=$$v},expression:"paidFilter"}})],1)],1):_vm._e(),(_vm.isProvider)?_c('v-row',{staticClass:"my-8"},[_c('other-charges-actions-menu',{attrs:{"selectedCharges":_vm.selectedItems}})],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-1",attrs:{"show-select":_vm.isProvider,"dense":"","headers":_vm.headers,"items":_vm.charges,"item-key":"otherEarningsChargeId"},scopedSlots:_vm._u([{key:"item.providerText",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(item.providerText))])]}},{key:"item.legacyProviderId",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(item.legacyProviderId))])]}},{key:"item.earningsCodeText",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(item.earningsCodeText))])]}},{key:"item.calculatedAmount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm.formatCurrency(item.calculatedAmount)))])]}},{key:"item.chargeDate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm.formatDateString(item.chargeDate)))])]}},{key:"item.note",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(item.note))])]}},{key:"item.isScheduledDeduction",fn:function(ref){
var item = ref.item;
return [(item.isScheduledDeduction)?_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Auto-scheduled")]):_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Manually entered")])]}},{key:"item.payrollBatchText",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(item.payrollBatchText))])]}},{key:"item.isPaid",fn:function(ref){
var item = ref.item;
return [(item.isPaid)?_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Paid")]):_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Unpaid")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.isPaid)?_c('tooltip-button',{attrs:{"icon":"mdi-magnify","size":"16","tooltip-text":"View charge"},on:{"click":function($event){return _vm.viewCharge(item)}}}):_vm._e(),(!item.isPaid)?_c('tooltip-button',{attrs:{"icon":"mdi-delete","size":"16","tooltip-text":"Delete charge"},on:{"click":function($event){return _vm.deleteCharge(item)}}}):_vm._e(),(!item.isPaid)?_c('tooltip-button',{attrs:{"icon":"mdi-pencil","size":"16","tooltip-text":"Edit charge"},on:{"click":function($event){return _vm.editCharge(item)}}},[_vm._v("Edit")]):_vm._e(),(!item.isPaid && item.remittanceReportId !== null)?_c('tooltip-button',{attrs:{"icon":"mdi-timer-sand","size":"16","tooltip-text":"Defer charge"},on:{"click":function($event){return _vm.deferCharge(item)}}}):_vm._e(),(
          !item.isPaid &&
          item.payrollBatchDeferredFromId !== null &&
          item.remittanceReportId === null
        )?_c('tooltip-button',{attrs:{"icon":"mdi-timer-off","size":"16","tooltip-text":"Undefer charge"},on:{"click":function($event){return _vm.undeferCharge(item)}}}):_vm._e()]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }