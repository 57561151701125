<template>
  <v-container>
    <div class="d-flex" v-if="newform">
      <v-btn text plain color="primary" link :to="{ name: 'All Providers' }">
        Provider Rates
      </v-btn>
      <v-icon color="primary"> mdi-menu-right </v-icon>
      <v-btn
        class="lower-case"
        text
        plain
        color="primary"
        link
        :to="{ name: 'All Providers' }"
      >
        {{ providerName }}
      </v-btn>
      <v-icon color="primary"> mdi-menu-right </v-icon>
      <v-btn v-if="form.sessionRateId === 0" text plain color="primary">
        {{ $route.name }}
      </v-btn>
      <v-btn v-else text plain color="primary"> Edit Session Rate </v-btn>
    </div>

    <div class="d-flex" v-else>
      <v-btn text plain color="primary" link :to="{ name: 'Current batch' }">
        {{ batchLabel }}
      </v-btn>
      <v-icon color="primary"> mdi-menu-right </v-icon>
      <v-btn
        class="lower-case"
        text
        plain
        color="primary"
        link
        :to="{ name: 'Remittance report' }"
      >
        {{ providerLabel }}
      </v-btn>
      <v-icon color="primary"> mdi-menu-right </v-icon>
      <v-btn text plain color="primary"> New Rate </v-btn>
    </div>

    <v-toolbar flat color="white" style="margin-top: 20px">
      <v-toolbar-title>Session Rate</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
    </v-toolbar>
    <validation-observer v-slot="{ invalid }">
      <v-row>
        <v-col cols="12" md="6">
          <date-picker
            :value="form.effectiveDate"
            :field="{ label: 'Effective Date' }"
            :validations="{ required: true }"
            @fieldChange="form.effectiveDate = $event"
          ></date-picker>
        </v-col>
      </v-row>

      <v-row>
        <v-radio-group v-model="lastEffective">
          <v-radio label="Indeterminate" value="Indeterminate"></v-radio>
          <v-radio value="Specific date">
            <template v-slot:label>
              <span class="mr-5">Specific date</span>
              <date-picker
                v-if="showLastEffective"
                :value="form.lastEffectiveDate"
                :field="{ label: 'Last effective date' }"
                :validations="validations.lastEffectiveDate"
                @fieldChange="form.lastEffectiveDate = $event"
              ></date-picker>
            </template>
          </v-radio>
        </v-radio-group>
      </v-row>

      <v-row v-show="showLastEffective"> </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <validation-provider
            :rules="validations.programId"
            v-slot="{ errors }"
          >
            <v-select
              :items="programs"
              :item-text="(item) => `${item.code} - ${item.name}`"
              item-value="programId"
              label="Program"
              v-model="form.programId"
              dense
              :error-messages="errors"
            ></v-select>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <validation-provider
            :rules="validations.serviceId"
            v-slot="{ errors }"
          >
            <v-select
              :items="serviceOptions"
              item-text="name"
              item-value="serviceId"
              label="Service"
              v-model="form.serviceId"
              dense
              :error-messages="errors"
            ></v-select>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <validation-provider
            :rules="validations.taxStatusId"
            v-slot="{ errors }"
          >
            <v-select
              :items="taxStatuses"
              item-text="name"
              item-value="taxStatusId"
              label="Tax/Billing Status"
              v-model="form.taxStatusId"
              dense
              :error-messages="errors"
            ></v-select>
          </validation-provider>
        </v-col>
        <v-col
          v-if="form.taxStatusId === 3 && associatedCorporations.length > 0"
          cols="12"
          md="3"
        >
          <v-radio-group v-model="professionActionType" class="mt-0">
            <v-radio
              v-for="(item, index) in professionActionRadios"
              :key="index"
              :label="item.text"
              :value="item.value"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col
          v-if="
            form.taxStatusId === 3 &&
            professionActionType === 1 &&
            associatedCorporations.length > 0
          "
        >
          <validation-provider
            :rules="validations.providerCorporationId"
            v-slot="{ errors }"
          >
            <v-select
              :items="associatedCorporations"
              item-text="name"
              item-value="providerCorporationId"
              label="Select an existing company"
              v-model="form.providerCorporationId"
              dense
              :error-messages="errors"
            ></v-select>
          </validation-provider>
        </v-col>
        <v-col
          v-if="
            form.taxStatusId === 3 &&
            (associatedCorporations.length === 0 || professionActionType === 2)
          "
        >
          <v-btn color="primary" @click="createCorporation(false)">
            Create new company
          </v-btn>
        </v-col>
        <v-col v-if="form.taxStatusId === 4" cols="12" md="3">
          <v-radio-group v-model="agencyActionType" class="mt-0">
            <v-radio
              v-for="(item, index) in agencyActionRadios"
              :key="index"
              :label="item.text"
              :value="item.value"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col v-if="form.taxStatusId === 4 && agencyActionType === 1">
          <validation-provider
            :rules="validations.providerCorporationId"
            v-slot="{ errors }"
          >
            <v-select
              :items="allStaffingAgencies"
              item-text="name"
              item-value="providerCorporationId"
              label="Select staffing agency"
              v-model="form.providerCorporationId"
              dense
              :error-messages="errors"
            ></v-select>
          </validation-provider>
        </v-col>
        <v-col
          v-if="
            form.taxStatusId === 4 &&
            (allStaffingAgencies.length === 0 || agencyActionType === 2)
          "
        >
          <v-btn color="primary" @click="createCorporation(true)">
            Create new company
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <validation-provider
            :rules="validations.rateUnitTypeId"
            v-slot="{ errors }"
          >
            <v-select
              :items="rateUnitTypes"
              item-text="name"
              item-value="rateUnitTypeId"
              label="Unit Type"
              v-model="form.rateUnitTypeId"
              dense
              :error-messages="errors"
            ></v-select>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <validation-provider
            :rules="validations.sessionLocationCategory"
            v-slot="{ errors }"
          >
            <v-select
              :items="sessionLocationCategories"
              label="Location Type"
              v-model="form.sessionLocationCategory"
              dense
              :error-messages="errors"
            ></v-select>
          </validation-provider>
        </v-col>
        <v-col
          cols="12"
          md="6"
          v-if="form.sessionLocationCategory === 'Specific location'"
        >
          <validation-provider
            :rules="validations.sessionLocationId"
            v-slot="{ errors }"
          >
            <v-select
              :items="sessionLocations"
              :item-text="(item) => `${item.name} (${item.legacyLocationId})`"
              item-value="sessionLocationId"
              label="Location"
              clearable
              dense
              v-model="form.sessionLocationId"
              :error-messages="errors"
            ></v-select>
          </validation-provider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="4">
          <v-checkbox
            v-model="form.isGroup"
            label="Group"
            dense
            hide-details="auto"
            class="mt-0"
          ></v-checkbox>
        </v-col>
        <v-col v-if="form.isGroup" cols="12" md="4">
          <v-radio-group v-model="groupType" class="mt-0">
            <v-radio
              v-for="(item, index) in groupRadios"
              :key="index"
              :label="item.text"
              :value="item.value"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col v-if="form.isGroup && groupType === 1" cols="12" md="2">
          <validation-provider
            :rules="validations.groupSize"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="form.groupSize"
              label="Group Size"
              dense
              type="number"
              min="0"
              step="1"
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>
        </v-col>
        <v-col
          v-if="form.isGroup && groupType === 2"
          class="d-flex"
          cols="12"
          md="4"
        >
          <validation-provider
            :rules="validations.groupSizeMin"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="form.groupSizeMin"
              class="mr-5"
              label="Min"
              dense
              type="number"
              min="0"
              step="1"
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>
          <validation-provider
            :rules="validations.groupSizeMax"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="form.groupSizeMax"
              label="Max"
              dense
              type="number"
              min="0"
              step="1"
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-checkbox
            v-model="form.isBilingual"
            label="Bilingual"
            dense
            hide-details="auto"
            class="mt-0"
          ></v-checkbox>
        </v-col>
        <v-col v-if="form.isBilingual">
          <validation-provider
            :rules="validations.languages"
            v-slot="{ errors }"
          >
            <v-select
              v-model="form.languages"
              :items="languages"
              item-text="name"
              return-object
              label="Language"
              :menu-props="{ openOnHover: true }"
              chips
              clearable
              dense
              multiple
              :error-messages="errors"
            ></v-select>
          </validation-provider>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-checkbox
            v-model="form.isClientSpecific"
            label="Client specific"
            dense
            hide-details="auto"
            class="mt-0"
          ></v-checkbox>
        </v-col>
        <v-col v-if="form.isClientSpecific">
          <validation-provider
            :rules="validations.clientId"
            v-slot="{ errors }"
          >
            <v-autocomplete
              v-model="form.clientId"
              :items="clients"
              item-text="text"
              item-value="value"
              label="Client"
              chips
              clearable
              dense
              :error-messages="errors"
            ></v-autocomplete>
          </validation-provider>
        </v-col>
      </v-row>

      <v-row v-if="hasSEITDiscipline">
        <v-col>
          <v-checkbox
            v-model="form.bonusRateDisabled"
            label="Disable SEIT 20+ Hours Rate"
            dense
            hide-details="auto"
            class="mt-0"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="3">
          <validation-provider
            :rules="validations.dollarAmount"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="form.dollarAmount"
              label="Amount"
              dense
              prefix="$"
              type="number"
              min="0"
              step="0.01"
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cancel"> Cancel </v-btn>
        <v-btn color="primary" @click="saveRates()" :disabled="invalid">
          Assign Rate
        </v-btn>
      </v-row>
    </validation-observer>
    <v-dialog v-model="corporationDialog" persistent width="500">
      <individual-coporation-modal
        v-if="corporationDialog"
        :corporation="newCorporation"
        @setNewCompany="setNewCompany"
        @close="corporationDialog = false"
      />
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import {
  required,
  minValue,
  minDate,
} from "../../plugins/vee-validate/validation";
import IndividualCoporationModal from "../../components/modal/Individual-coporation-modal.vue";
import SessionRate from "../../objs/SessionRate";
import ProviderCorporation from "../../objs/ProviderCorporation";

export default {
  components: {
    IndividualCoporationModal,
  },
  props: ["sessionRate"],
  data: () => ({
    lastEffective: "Indeterminate",
    form: new SessionRate(),
    corporationDialog: false,
    newCorporation: new ProviderCorporation(),
    groupType: 0,
    groupRadios: [
      {
        text: "Specific group size",
        value: 1,
      },
      {
        text: "Group size range",
        value: 2,
      },
    ],
    agencyActionType: 1,
    agencyActionRadios: [
      {
        text: "Select existing staffing agency",
        value: 1,
      },
      {
        text: "Create new staffing agency",
        value: 2,
      },
    ],
    professionActionType: 1,
    professionActionRadios: [
      {
        text: "Select existing company",
        value: 1,
      },
      {
        text: "Create new company",
        value: 2,
      },
    ],
  }),
  computed: {
    providerName() {
      return this.selectedProvider.fullName;
    },
    showLastEffective() {
      return this.lastEffective == "Specific date";
    },
    ...mapGetters({
      selectedProvider: "providerModule/selectedProvider",
      taxStatuses: "optionModule/taxStatuses",
      services: "optionModule/associatedServices", // Only gets associated services before routing all session rates page
      programs: "optionModule/programs",
      clients: "optionModule/clients",
      languages: "optionModule/languages",
      sessionLocationCategories: "optionModule/sessionLocationCategories",
      sessionLocations: "optionModule/sessionLocations",
      allStaffingAgencies: "providerModule/allStaffingAgencies",
      associatedCorporations: "providerModule/associatedCorporations",
    }),
    rateUnitTypes() {
      return this.$store.getters["optionModule/getUnitTypesForProgram"](
        this.form.programId
      );
    },
    providerLabel() {
      return this.$store.state.payrollBatchModule.currentRemittanceReport
        .providerName;
    },
    batchLabel() {
      return this.$store.getters["payrollBatchModule/getCurrentBatchName"];
    },
    newform() {
      return !this.sessionRate.isAssign;
    },
    serviceOptions() {
      return this.$store.state.optionModule.services;
    },
    uxData() {
      if (this.form.sessionRateId > 0) {
        return {
          loaderText: "Updating Session Rate...",
          path: "providerModule/updateSessionRate",
          snackText: "Updated session rate successfully!",
        };
      }
      return {
        loaderText: "Creating Session Rate...",
        path: "providerModule/createSessionRate",
        snackText: "Create new session rate successfully!",
      };
    },
    validations() {
      return {
        programId: { required: true },
        effectiveDate: { required: true },
        lastEffectiveDate: {
          required: this.lastEffective === "Specific date",
          minDate: this.form.effectiveDate,
        },
        serviceId: { required: true },
        taxStatusId: { required: true },
        rateUnitTypeId: { required: true },
        sessionLocationCategory: { required: true },
        sessionLocationId: {
          required: this.form.sessionLocationCategory === "Specific location",
        },
        clientId: {
          required: this.form.isClientSpecific == true,
        },
        providerCorporationId: {
          required: this.form.taxStatusId > 2,
        },
        languages: {
          required: this.form.isBilingual,
        },
        groupSize: {
          required: true,
          minValue: 0,
        },
        groupSizeMin: {
          required: true,
          minValue: 0,
        },
        groupSizeMax: {
          required: true,
          minValue: 0,
        },
        dollarAmount: {
          required: true,
          minValue: 0,
        },
      };
    },
    hasSEITDiscipline() {
      return (
        this.selectedProvider.disciplines.filter(
          (item) => item.name == "Special Education"
        ).length > 0
      );
    },
  },
  async created() {
    await this.$store.dispatch("providerModule/getAllStaffingAgencies");
    await this.$store.dispatch("providerModule/getAllProviderCorporations");
    await this.$store.dispatch(
      "providerModule/getCorporationsForProvider",
      this.sessionRate.providerId
    );
  },
  mounted() {
    this.form = {
      ...this.form,
      ...this.sessionRate,
      effectiveDate: Boolean(this.sessionRate.effectiveDate)
        ? new Date(this.sessionRate.effectiveDate)
        : "",
      lastEffectiveDate: Boolean(this.sessionRate.lastEffectiveDate)
        ? new Date(this.sessionRate.lastEffectiveDate)
        : "",
    };

    if (Boolean(this.form.lastEffectiveDate)) {
      this.lastEffective = "Specific date";
    } else {
      this.lastEffective = "Indeterminate";
    }
  },
  watch: {
    form: {
      deep: true,
      handler: "watchForm",
    },
    professionActionType() {
      if (this.professionActionType === 2)
        this.form.providerCorporationId = null;
    },
    agencyActionType() {
      if (this.agencyActionType === 2) this.form.providerCorporationId = null;
    },
    groupType() {
      if (this.groupType === 1) {
        this.form.groupSizeMin = 0;
        this.form.groupSizeMax = 0;
      } else if (this.groupType === 2) this.form.groupSize = 0;
    },
  },
  methods: {
    watchForm(newValue) {
      if (newValue.sessionLocationCategory != "Specific location") {
        this.form.sessionLocationId = null;
        this.sessionRate.sessionLocationId = null;
      }
      if (newValue.taxStatusId === 3 && newValue.providerCorporationId > 0)
        this.professionActionType = 1;
      else if (newValue.taxStatusId === 4 && newValue.providerCorporationId > 0)
        this.agencyActionType = 1;
      else if (newValue.taxStatusId < 3) this.form.providerCorporationId = null;
      if (newValue.isGroup && newValue.groupSize > 0) this.groupType = 1;
      if (newValue.isGroup && newValue.groupSizeMin > 0) this.groupType = 2;
      if (!newValue.isGroup) {
        newValue.groupSize = 0;
        newValue.groupSizeMin = 0;
        newValue.groupSizeMax = 0;
      }
      if (!newValue.isClientSpecific) {
        this.form.clientId = null;
      }
    },
    createCorporation(value) {
      this.newCorporation.isStaffingAgency = value;
      this.corporationDialog = true;
    },
    setNewCompany(corporationId) {
      if (this.form.taxStatusId === 3) this.professionActionType = 1;
      else if (this.form.taxStatusId === 4) this.agencyActionType = 1;
      this.form.providerCorporationId = corporationId;
    },
    cancel() {
      //this.$router.push({
      //    name: 'All Session Rates'
      //})
      this.$router.go(-1);
    },
    async saveRates() {
      if (
        this.form.lastEffectiveDate == null ||
        this.form.lastEffectiveDate == "" ||
        this.lastEffective == "Indeterminate"
      ) {
        this.form.lastEffectiveDate = null;
      }
      if (this.form.providerCorporationId === 0)
        this.form.providerCorporationId = null;
      if (this.form.sessionLocationId === 0) this.form.sessionLocationId = null;
      this.$store.dispatch("uxModule/showLoader", this.uxData.loaderText);

      //First check if there is an overlap
      var overlap = await this.$store.dispatch(
        "providerModule/hasMatchingRate",
        { ...this.form }
      );
      if (!overlap) {
        const success = await this.$store.dispatch(this.uxData.path, {
          ...this.form,
        });
        if (this.sessionRate.isAssign) {
          await this.$store.dispatch(
            "payrollBatchModule/reprocessRemittanceReport"
          );
        }
        this.$store.dispatch("uxModule/hideLoader");

        if (success)
          this.$store.dispatch(
            "uxModule/showSnackBarMsg",
            this.uxData.snackText
          );
        else
          this.$store.dispatch("uxModule/showSnackBarMsg", "Raised an error!");

        this.$router.go(-1);
      } else {
        this.$store.dispatch("uxModule/hideLoader");

        this.$store.dispatch(
          "uxModule/showSnackBarMsg",
          "The effective dates of this rate overlap with a preexisting rates. Adjust the dates of this rate, or of the previously entered rate, and try again."
        );
      }
    },
  },
};
</script>
