<template>
  <div>
    <v-radio-group v-model="reportType" row>
      <v-radio label="Expenses By Payment Date" value="byPaymentDate"></v-radio>
      <v-radio label="Expenses By Date of Service" value="byDOS"></v-radio>
    </v-radio-group>

    <v-divider class="my-5"></v-divider>
    <expenses-by-payment-date-report v-if="reportType == 'byPaymentDate'">
    </expenses-by-payment-date-report>

    <expenses-by-dos-report v-if="reportType == 'byDOS'">
    </expenses-by-dos-report>
  </div>
</template>

<script>
import ExpensesByPaymentDateReport from "./expenses-by-payment-date-report.vue";
import ExpensesByDosReport from "./expenses-by-dos-report.vue";

export default {
  components: { ExpensesByPaymentDateReport, ExpensesByDosReport },
  async mounted() {
    await this.$store.dispatch("reportsModule/getReportOptions");
  },
  data() {
    return {
      reportType: "byPaymentDate",
    };
  },
};
</script>
