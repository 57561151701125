<template>
  <v-card class="mx-auto">
    <v-card-title>
      <carryover-actions :selectedCarryover="selected"></carryover-actions>
      <tooltip-button
        icon="mdi-file-export"
        tooltip-text="Export to csv"
        @click="exportCarryover"
      ></tooltip-button>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        prepend-icon="mdi-magnify"
        clearable
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="carryover"
      sort-by="legacyProviderId"
      class="elevation-1 mx-auto"
      :loading="loading"
      :search="search"
      show-select
      item-key="paidSickLeaveCarryoverId"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <tooltip-button
          icon="mdi-restore"
          size="16"
          tooltip-text="Reset Carryover Hours"
          @click="resetCarryover(item)"
        ></tooltip-button>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import CarryoverActions from "./carryover-actions.vue";
import { formatDate } from "../../utils/format-utils";
import { downloadFile } from "../../utils/download-utils";
export default {
  props: ["loading"],
  components: {
    CarryoverActions,
  },
  data() {
    return {
      headers: [
        { text: "Legacy Provider Id", value: "legacyProviderId" },
        { text: "Provider Name", value: "providerName" },
        { text: "Carryover Hours", value: "carryover" },
        { text: "School Year", value: "year" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      selected: [],
      search: "",
    };
  },
  async mounted() {
    await this.$store.dispatch("paidSickLeaveModule/loadCarryover", {
      date: new Date(),
    });
  },
  computed: {
    carryover() {
      return this.$store.state.paidSickLeaveModule.carryover || [];
    },
  },
  methods: {
    resetCarryover: async function (item) {
      this.$store.commit("uxModule/setShowLoader", true);
      let success = await this.$store.dispatch(
        "paidSickLeaveModule/resetCarryover",
        item
      );

      let message = success
        ? "Reset carryover successfully."
        : `Error resetting carryover`;
      this.$store.commit("uxModule/setSnackbarMsg", message);
      this.$store.commit("uxModule/setShowSnackbar", true);

      this.$store.commit("uxModule/setShowLoader", false);
    },
    exportCarryover: async function () {
      this.$store.commit("uxModule/setShowLoader", true);
      var response = await this.$store.dispatch(
        "paidSickLeaveModule/exportCarryover"
      );
      if (response) {
        await downloadFile(
          response,
          `PaidSickLeaveCarryover_${formatDate(new Date())}.csv`
        );
      } else {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Oops! Something went wrong. Please try again later."
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
      this.$store.commit("uxModule/setShowLoader", false);
    },
  },
};
</script>
