var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"d-flex pb-10 mb-5"},[_c('v-btn',{attrs:{"text":"","plain":"","color":"primary","link":"","to":{ name: 'Scheduled Deductions' }}},[_vm._v(" Other Earnings ")]),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-menu-right ")]),_c('v-btn',{attrs:{"text":"","plain":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$route.name)+" ")])],1),_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("New Scheduled Deduction")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1),_c('validation-observer',{staticClass:"mt-5",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":_vm.validations.providerId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.providers,"item-text":"text","item-value":"value","label":"Provider","dense":"","error-messages":errors},model:{value:(_vm.form.providerId),callback:function ($$v) {_vm.$set(_vm.form, "providerId", $$v)},expression:"form.providerId"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"rules":_vm.validations.omniCorporationDivisionId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.omniCorporationDivisions,"item-text":"description","item-value":"omniCorporationDivisionId","label":"Omni Corporations","dense":"","error-messages":errors},model:{value:(_vm.form.omniCorporationDivisionId),callback:function ($$v) {_vm.$set(_vm.form, "omniCorporationDivisionId", $$v)},expression:"form.omniCorporationDivisionId"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"rules":_vm.validations.earningsCodeId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.deductionTypes,"item-text":"name","item-value":"earningsCodeId","label":"Deduction Type","dense":"","error-messages":errors},model:{value:(_vm.form.earningsCodeId),callback:function ($$v) {_vm.$set(_vm.form, "earningsCodeId", $$v)},expression:"form.earningsCodeId"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-switch',{staticStyle:{"margin-top":"0"},attrs:{"label":"Activation Status"},model:{value:(_vm.form.isActive),callback:function ($$v) {_vm.$set(_vm.form, "isActive", $$v)},expression:"form.isActive"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('date-picker',{attrs:{"value":_vm.form.effectiveDate,"field":{ label: 'Effective Date' }},on:{"fieldChange":_vm.changeEffectiveDate}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"rules":_vm.validations.amount},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Amount","dense":"","prefix":"$","type":"number","min":"0","step":"0.01","error-messages":errors},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":function($event){return _vm.saveDeduction()}}},[_vm._v(" Save ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }