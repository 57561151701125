<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="charges"
      sort-by="paidOn"
      class="elevation-1 mx-auto"
    >
      <template v-slot:[`item.sessionChargeId`]="{ item }">
        <span style="font-size: 11px">
          {{ item.sessionChargeId }}
        </span>
      </template>

      <template v-slot:[`item.sessionStatus`]="{ item }">
        <span style="font-size: 11px">
          {{ item.sessionStatus }}
        </span>
      </template>

      <template v-slot:[`item.rateSummary`]="{ item }">
        <span style="font-size: 11px">
          {{ item.rateSummary }}
        </span>
      </template>

      <template v-slot:[`item.payrollBatch`]="{ item }">
        <span style="font-size: 11px">
          {{ item.payrollBatch }}
        </span>
      </template>

      <template v-slot:[`item.payrollBatchDeferredFrom`]="{ item }">
        <span style="font-size: 11px">
          {{ item.payrollBatchDeferredFrom }}
        </span>
      </template>

      <template v-slot:[`item.flag`]="{ item }">
        <span style="font-size: 11px">
          {{ item.flag }}
        </span>
      </template>

      <template v-slot:[`item.calculatedAmount`]="{ item }">
        <span style="font-size: 11px">
          {{ formatCurrency(item.calculatedAmount) }}
        </span>
      </template>

      <template v-slot:[`item.adjustedAmount`]="{ item }">
        <span style="font-size: 11px">
          {{ formatCurrency(item.adjustedAmount) }}
        </span>
      </template>

      <template v-slot:[`item.isPaid`]="{ item }">
        <span style="font-size: 11px">
          {{ isPaidText(item.isPaid) }}
        </span>
      </template>

      <template v-slot:[`item.paidOn`]="{ item }">
        <span style="font-size: 11px">
          {{ paidOnText(item) }}
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { formatCurrency } from "../../utils/format-utils";
export default {
  props: ["charges"],
  data: function () {
    return {
      headers: [
        {
          text: "Charge Id",
          value: "sessionChargeId",
          sortable: false,
        },
        {
          text: "Payment Status",
          value: "isPaid",
          sortable: false,
        },
        {
          text: "Flag",
          value: "flag",
          sortable: false,
        },
        {
          text: "Session Status",
          value: "sessionStatus",
          sortable: false,
        },

        {
          text: "Rate",
          value: "rateSummary",
          sortable: false,
        },
        {
          text: "Calculated Amount",
          value: "calculatedAmount",
          sortable: false,
        },
        {
          text: "Adjusted Amount",
          value: "adjustedAmount",
          sortable: false,
        },
        {
          text: "Payroll Batch",
          value: "payrollBatch",
          sortable: false,
        },

        {
          text: "Deferred From Batch",
          value: "payrollBatchDeferredFrom",
          sortable: false,
        },

        {
          text: "Paid on",
          value: "paidOn",
          sortable: false,
        },

        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    formatCurrency,
    isPaidText(val) {
      if (val) {
        return "Paid";
      } else {
        return "Unpaid";
      }
    },
    adjustmentText(val) {
      if (val) {
        return "Yes";
      } else {
        return "";
      }
    },
    payrollBatchText(item) {
      if (
        item.payrollBatchIditem.payrollBatchDeferredFrom != "" &&
        item.payrollBatchDeferredFrom != null
      ) {
        return "Deferred from " + item.payrollBatchDeferredFrom;
      } else {
        return item.payrollBatch;
      }
    },
    paidOnText(item) {
      if (item.isPaid) {
        return new Date(item.paidOn).toLocaleDateString();
      } else {
        return "";
      }
    },
  },
};
</script>
