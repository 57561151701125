var urlPrefix = process.env.VUE_APP_OMNI_BASE_URL;

import * as signalR from "@microsoft/signalr";

//start connection to hub
const connection = new signalR.HubConnectionBuilder()
  .withUrl(urlPrefix + "/payrollHub")
  .configureLogging(signalR.LogLevel.Information)
  .build();

//restart connection if it closes

connection.serverTimeoutInMilliseconds = 300000;
connection.keepAliveIntervalInMilliseconds = 300000;


connection.onclose(async () =>
{
  await start();
});

async function start()
{
  try
  {
    await connection.start();
  } catch (err)
  {
    console.log(`Error starting hub connection: ${err}`);
    setTimeout(start, 5000);
  }
}
start();
export { connection };
