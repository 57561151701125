<template>
  <v-card>
    <v-card-title class="headline">
      Associated Provider List
      <v-spacer></v-spacer>
      <v-btn
        :disabled="noEdit"
        v-on:click="addProvider = true"
        x-large
        icon
        color="primary"
        ><v-icon>mdi-plus-circle</v-icon></v-btn
      >
    </v-card-title>
    <v-card-text>
      <v-data-table :headers="headers" :items="providers">
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            :disabled="noEdit"
            x-small
            text
            v-on:click="clearAssociation(item)"
            >Clear</v-btn
          >
        </template>
      </v-data-table>

      <div v-if="addProvider">
        <v-row>
          <v-col cols="4" class="ml-4">
            <v-select
              clearable
              :items="providerOptions"
              v-model="selectedProvider"
              label="Select a provider"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-btn
              medium
              text
              primary
              v-if="selectedProvider != null"
              v-on:click="createAssociation"
              class="mt-4"
              >Save</v-btn
            >
            <v-btn medium text primary v-on:click="cancel" class="mt-4"
              >Cancel</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="$emit('close')"> Close </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  async created() {
    if (this.providerOptions.length === 0)
      await this.$store.dispatch("providerModule/getAllProviders");
  },
  name: "AssociatedProvidersModal",
  props: ["providers", "corporationId", "noEdit"],
  data: () => ({
    selectedProvider: null,
    addProvider: false,
    headers: [
      {
        text: "Provider Id",
        value: "providerId",
      },
      {
        text: "First Name",
        value: "firstName",
      },
      {
        text: "Last Name",
        value: "lastName",
      },
      {
        text: "Legacy Provider Id",
        value: "legacyProviderId",
      },
      {
        text: "Email Address",
        value: "emailAddress",
      },
      {
        text: "Actions",
        value: "actions",
      },
    ],
  }),
  computed: {
    providerOptions: function () {
      return this.$store.getters["providerModule/getProviderOptions"];
    },
  },
  methods: {
    async clearAssociation(item) {
      this.$store.commit("uxModule/setShowLoader", true);
      var success = await this.$store.dispatch(
        "providerModule/clearProviderCorporationAssociation",
        {
          providerId: item.providerId,
          providerCorporationId: this.corporationId,
        }
      );
      this.$store.commit("uxModule/setShowLoader", false);
      if (!success) {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "This association could not be severed. The provider may already have existing rates with this corporation."
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
    },
    async createAssociation() {
      this.$store.commit("uxModule/setShowLoader", true);
      var success = await this.$store.dispatch(
        "providerModule/createProviderCorporationAssociation",
        {
          providerId: this.selectedProvider,
          providerCorporationId: this.corporationId,
        }
      );
      this.$store.commit("uxModule/setShowLoader", false);
      this.selectedProvider = null;
      this.addProvider = false;
    },
    cancel() {
      this.selectedProvider = null;
      this.addProvider = false;
    },
  },
};
</script>
