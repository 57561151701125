<template>
  <requests-table :loading="loading"></requests-table>
</template>
<script>
import RequestsTable from "./requests-table.vue";
export default {
  components: {
    RequestsTable,
  },
  data() {
    return {
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    this.$store.dispatch("paidSickLeaveModule/clearData");
    await this.$store.dispatch("paidSickLeaveModule/getAllOpenRequests");
    this.loading = false;
  },
};
</script>