<template>
  <div>
    <p>This is a home page.</p>
  </div>
</template>

<script>
  export default {

  }
</script>
