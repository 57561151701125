<template>
  <div>
    <v-card-subtitle>
      <download-zip-button
        :selectedFiles="selectedItems"
        :zipFileName="zipFileName"
      ></download-zip-button>
    </v-card-subtitle>
    <v-card-text>
      <v-data-table
        v-model="selectedItems"
        :headers="headers"
        :items="displayRemittances"
        item-key="remittanceReportId"
        :loading="loading"
        loading-text="Loading... Please wait"
        show-select
      >
        <template v-slot:[`item.actions`]="{ item }">
          <preview-file-action :fileId="item.fileId"></preview-file-action>
          <download-file-action
            :fileId="item.fileId"
            :filename="item.fileName"
          ></download-file-action>
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>
<script>
import DownloadFileAction from "../../components/Shared/download-file-action.vue";
import PreviewFileAction from "../../components/Shared/preview-file-action.vue";
import DownloadZipButton from "../../components/Shared/download-zip-button.vue";
export default {
  components: {
    PreviewFileAction,
    DownloadFileAction,
    DownloadZipButton,
  },
  props: ["providerId"],
  data() {
    return {
      loading: true,
      headers: [
        {
          align: "start",
          sortable: false,
          width: 3,
          value: "type",
        },
        { text: "Report ID", value: "remittanceReportId" },
        { text: "Paycheck Date", value: "formattedPaycheckDate" },
        { text: "Payroll Period Ending", value: "formattedEndDate" },
        { text: "Total Payout", value: "formattedTotalPayout" },
        { text: "Remittance", value: "actions", sortable: false },
      ],
      remittances: [],
      selectedItems: [],
    };
  },
  computed: {
    legacyProviderId: function () {
      return (
        this.$store.getters["providerModule/getLegacyProviderId"](
          this.providerId
        ) || ""
      );
    },
    zipFileName: function () {
      return "Payroll_Remittances_" + this.legacyProviderId + ".zip";
    },
    displayRemittances: function () {
      return this.remittances
        ?.filter((item) => item.fileId)
        .sort((a, b) => {
          return new Date(b.paycheckDate) - new Date(a.paycheckDate);
        });
    },
  },
  methods: {
    getRemittances: async function () {
      this.remittances = await this.$store.dispatch(
        "providerModule/getProviderRemittances",
        { providerId: this.providerId }
      );
      this.loading = false;
    },
  },
  watch: {
    providerId: {
      immediate: true,
      handler: function () {
        if (this.providerId) {
          this.getRemittances();
        } else {
          this.loading = false;
          this.remittances = [];
        }
      },
    },
  },
};
</script>
