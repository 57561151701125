<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn :disabled="disabled" color="primary"
             v-bind="attrs"
             icon
             v-on:click="clicked"
             v-on="on">
        <v-icon 
                
                
               :size="size"
                color="primary"
                icon="icon">{{icon}}</v-icon>
      </v-btn>
    </template>
    <span>{{tooltipText}}</span>
  </v-tooltip>
</template>

<script>
  export default {
    props: ['icon', 'tooltipText', 'disabled', 'size'],

    methods: {
      clicked()
      {
        this.$emit('click');
      }
    }
  }
</script>
