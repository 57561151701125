<template>
  <router-view></router-view>
</template>
<script>
export default {
  mounted: async function () {
    await this.$store.dispatch(
      "otherEarningsChargesModule/getAllOtherEarningsCharges"
    );
  },
};
</script>
