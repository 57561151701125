<template>
  <v-container>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="fpayrollBatches"
          class="elevation-3 mx-auto"
        >
          <template v-slot:[`item.totalPayout`]="{ item }">
            <span style="font-size: 11px">{{
              formatCurrency(item.totalPayout)
            }}</span>
          </template>

          <template v-slot:[`item.createdOn`]="{ item }">
            <span style="font-size: 11px">{{
              formatDate(item.createdOn)
            }}</span>
          </template>

          <template v-slot:[`item.closedOn`]="{ item }">
            <span style="font-size: 11px">{{ formatDate(item.closedOn) }}</span>
          </template>

          <template v-slot:[`item.paycheckDate`]="{ item }">
            <span style="font-size: 11px">{{
              formatDate(item.paycheckDate)
            }}</span>
          </template>

          <template v-slot:[`item.startDate`]="{ item }">
            <span style="font-size: 11px">{{
              formatDate(item.startDate)
            }}</span>
          </template>

          <template v-slot:[`item.endDate`]="{ item }">
            <span style="font-size: 11px">{{ formatDate(item.endDate) }}</span>
          </template>
          <template v-slot:[`item.continueAddingProviders`]="{ item }">
            <v-row>
              <v-checkbox
                class="pl-10"
                text
                x-small
                v-model="item.continueAddingProviders"
                color="primary"
                @change="updateAutoAdd(item)"
                :disabled="Boolean(item.closedOn)"
              >
              </v-checkbox>
            </v-row>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-row>
              <v-btn
                text
                x-small
                color="primary"
                @click="processBatch(item)"
                class="pt-1"
                v-if="!Boolean(item.closedOn)"
              >
                Process
              </v-btn>

              <v-btn
                text
                x-small
                color="primary"
                @click="reviewBatch(item)"
                class="pt-1"
                v-if="Boolean(item.closedOn)"
              >
                Review
              </v-btn>

              <v-btn
                text
                x-small
                color="primary"
                @click="reportsBatch(item)"
                class="pt-1"
              >
                Reports
              </v-btn>

              <v-btn
                text
                x-small
                color="primary"
                @click="closeBatch(item)"
                class="pt-1"
                :disabled="Boolean(item.closedOn)"
              >
                Close
              </v-btn>

              <emails-menu :payrollBatch="item"></emails-menu>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mask } from "vue-the-mask";
import AddProviders from "./add-providers-to-batch";
import EmailsMenu from "./batch-email-actions-menu";
import { formatCurrency, formatDate } from "../../utils/format-utils";
export default {
  components: {
    EmailsMenu,
    AddProviders,
  },
  data() {
    return {
      headers: [
        { text: "Batch Id", value: "payrollBatchId", sortable: false },
        { text: "Batch Name", value: "name", sortable: false },
        { text: "Created On", value: "createdOn", sortable: false },
        { text: "Closed On", value: "closedOn", sortable: false },
        { text: "Start Date", value: "startDate", sortable: false },
        { text: "End Date", value: "endDate", sortable: false },
        { text: "Paycheck Date", value: "paycheckDate", sortable: false },
        { text: "Total Payout", value: "totalPayout", sortable: false },
        {
          text: "Auto Add Providers",
          value: "continueAddingProviders",
          sortable: false,
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      batchFilter: [
        {
          text: "All Batches",
          value: 1,
        },
        {
          text: "Batch Date",
          value: 2,
        },
      ],
      filterOne: 1,
      filterDate: "",
      filterName: "",
      dateMask: "####-##-##",
    };
  },
  mounted: async function () {
    this.$store.commit("uxModule/setShowLoader", true);
    await this.$store.dispatch("payrollBatchModule/loadBatches");
    this.$store.commit("uxModule/setShowLoader", false);
  },
  computed: {
    fpayrollBatches() {
      return this.$store.state.payrollBatchModule.batches;
    },
  },
  directives: { mask },

  watch: {
    filterOne() {
      if (this.filterOne === 1) this.filterDate = "";
    },
  },
  methods: {
    formatCurrency,
    formatDate,
    async updateAutoAdd(item) {
      await this.$store.dispatch("payrollBatchModule/updateAutoAdd", {
        payrollBatchId: item.payrollBatchId,
        add: item.continueAddingProviders,
      });
    },
    async processBatch(item) {
      this.$store.commit("uxModule/setShowLoader", true);
      await this.$store.dispatch(
        "payrollBatchModule/loadBatch",
        item.payrollBatchId
      );
      this.$store.commit("uxModule/setShowLoader", false);

      this.$router.push({ name: "Current batch" });
    },

    async reviewBatch(item) {
      this.$store.commit("uxModule/setShowLoader", true);
      await this.$store.dispatch(
        "payrollBatchModule/loadBatch",
        item.payrollBatchId
      );
      this.$store.commit("uxModule/setShowLoader", false);
      this.$router.push({ name: "ReviewBatch" });
    },

    async closeBatch(item) {
      this.$store.commit("uxModule/setShowLoader", true);
      await this.$store.dispatch(
        "payrollBatchModule/closeBatch",
        item.payrollBatchId
      );
      this.$store.commit("uxModule/setShowLoader", false);
    },
    reportsBatch(item) {
      this.$router.push({
        name: "Payroll Batch Reports",
        params: {
          payrollBatchId: item.payrollBatchId,
        },
      });
    },
    filter() {
      let $this = this;
      if ($this.filterName === "")
        $this.fpayrollBatches = Array.from($this.payrollBatches);
      else
        $this.fpayrollBatches = $this.payrollBatches.filter(function (batch) {
          return batch.name.includes($this.filterName);
        });

      if ($this.filterDate === "") return;
      else
        $this.fpayrollBatches = $this.fpayrollBatches.filter(function (batch) {
          return (
            batch.startDate.includes($this.filterDate) ||
            batch.endDate.includes($this.filterDate) ||
            batch.paycheckDate.includes($this.filterDate)
          );
        });
    },
  },
};
</script>
