import { formatDate } from "../utils/format-utils";
import Provider from "./Provider";
import PaidSickLeaveRequestSession from "./PaidSickLeaveRequestSession";

export default class PaidSickLeaveRequest {
  paidSickLeaveRequestId?: number | null;
  providerId?: number | null;
  provider?: Provider | null;
  requestStart?: Date | null;
  requestEnd?: Date | null;
  requestYear?: number | null;
  hoursRequested?: number | null;
  reason?: string | null;
  providerNote?: string | null;
  adminNote?: string | null;
  requestApproved?: boolean | null;
  requestDenied?: boolean | null;
  requestSubmittedAt?: Date | null;
  requestProcessedAt?: Date | null;
  hoursApplied?: number | null;
  sessions?: PaidSickLeaveRequestSession[] | null;
  public constructor(
    params: PaidSickLeaveRequest = {} as PaidSickLeaveRequest
  ) {
    let {
      paidSickLeaveRequestId = 0,
      providerId = 0,
      provider = null,
      requestStart = null,
      requestEnd = null,
      requestYear = null,
      hoursRequested = 0,
      reason = "",
      providerNote = "",
      adminNote = "",
      requestApproved = false,
      requestDenied = false,
      requestSubmittedAt = null,
      requestProcessedAt = null,
      hoursApplied = 0,
      sessions = [],
    } = params;
    this.paidSickLeaveRequestId = paidSickLeaveRequestId;
    this.providerId = providerId;
    this.provider = provider ? new Provider(provider) : null;
    this.requestStart = requestStart;
    this.requestEnd = requestEnd;
    this.requestYear = requestYear;
    this.hoursRequested = hoursRequested;
    this.reason = reason;
    this.providerNote = providerNote;
    this.adminNote = adminNote;
    this.requestApproved = requestApproved;
    this.requestDenied = requestDenied;
    this.requestSubmittedAt = requestSubmittedAt;
    this.requestProcessedAt = requestProcessedAt;
    this.hoursApplied = hoursApplied;
    this.sessions = sessions;
  }

  get formattedStartDate() {
    if (this.requestStart) return formatDate(this.requestStart);
  }

  get formattedEndDate() {
    if (this.requestEnd) return formatDate(this.requestEnd);
  }

  get formattedSubmittedDate() {
    if (this.requestSubmittedAt) return formatDate(this.requestSubmittedAt);
  }

  get status() {
    if (this.requestApproved) return "Approved";
    else if (this.requestDenied) return "Denied";
    else return "Pending Approval";
  }

  get pending() {
    return this.requestApproved == false && this.requestDenied == false;
  }

  get providerName() {
    if (this.provider)
      return this.provider.firstName + " " + this.provider.lastName;
  }
}
