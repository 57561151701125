import * as api from "../Services/api.js";

export const state = {
  adpAccounts: [],
};

export const mutations = {
  setAdpAccounts(state, payload) {
    state.adpAccounts = payload;
  },

  deleteAdpAccount(state, id) {
    const toDelete = (element) => element.adpAccountId == id;
    var index = state.adpAccounts.findIndex(toDelete);
    state.adpAccounts.splice(index, 1);
  },

  addAdpAccount(state, payload) {
    state.adpAccounts.push(payload);
    state.adpAccounts.sort((a, b) => a.provider.localeCompare(b.provider));
  },

  updateAdpAccount(state, payload) {
    const toUpdate = (element) => element.adpAccountId == payload.adpAccountId;
    var index = state.adpAccounts.findIndex(toUpdate);
    state.adpAccounts.splice(index, 1, payload);
  },
};

export const getters = {};

export const actions = {
  async getAllAdpAccounts({ commit }) {
    await api.get("/adp/getAllAdpAccounts").then((response) => {
      if (response.success) {
        commit("setAdpAccounts", response.data);
      }
    });
  },

  async createAdpAccount({ commit, state }, payload) {
    var response = await api.post("/adp/createAdpAccount", payload);
    if (response.success) {
      commit("addAdpAccount", response.data);
      return "";
    }

    return response.message;
  },

  async updateAdpAccount({ commit, state }, payload) {
    var response = await api.post("/adp/updateAdpAccount", payload);
    if (response.success) {
      commit("updateAdpAccount", response.data);
      return true;
    } else {
      return false;
    }
  },

  async deleteAdpAccount({ commit, state }, payload) {
    var response = await api.postQuery("/adp/deleteAdpAccount", payload);
    if (response.success) {
      commit("deleteAdpAccount", payload.adpAccountId);
      return true;
    } else {
      return false;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
