<template>
  <v-menu top offset-y close-on-click>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        outlined
        :dark="!Boolean(noSelectedCarryover)"
        :disabled="Boolean(noSelectedCarryover)"
        v-bind="attrs"
        v-on="on"
        small
      >
        Bulk Actions
      </v-btn>
    </template>

    <v-list>
      <v-list-item @click="resetCarryover">
        <v-list-item-icon><v-icon>mdi-restore</v-icon></v-list-item-icon>
        <v-list-item-title>Reset Carryover Hours</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  props: ["selectedCarryover"],
  computed: {
    noSelectedCarryover: function () {
      return this.selectedCarryover.length < 1;
    },
  },
  methods: {
    resetCarryover: async function () {
      this.$store.commit("uxModule/setShowLoader", true);
      await this.$store.dispatch(
        "paidSickLeaveModule/bulkResetCarryover",
        this.selectedCarryover
      );
      this.$store.commit("uxModule/setShowLoader", false);
    },
  },
};
</script>
