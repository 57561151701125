<template>
  <v-container>
    <v-row>
      <v-subheader class="font-weight-black">Filter report by:</v-subheader>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-autocomplete
          label="Programs"
          v-model="selectedPrograms"
          :items="programOptions"
          :item-text="(item) => `${item.code} - ${item.name}`"
          item-value="code"
          multiple
          chips
          deletable-chips
          clearable
          dense
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          label="Services"
          v-model="selectedServices"
          :items="serviceOptions"
          :item-text="(item) => `${item.code} - ${item.name}`"
          item-value="code"
          multiple
          chips
          deletable-chips
          clearable
          dense
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-btn
        text
        outlined
        rounded
        color="primary"
        @click="downloadReport"
        class="my-2"
        >Download Report</v-btn
      >
    </v-row>
  </v-container>
</template>
<script>
import { downloadFile } from "../../utils/download-utils";
import { formatDateForFilename } from "../../utils/format-utils";
export default {
  data() {
    return {
      selectedPrograms: [],
      selectedServices: [],
    };
  },
  computed: {
    programOptions() {
      return this.$store.state.optionModule.programs;
    },
    serviceOptions() {
      return this.$store.state.optionModule.services;
    },
  },
  methods: {
    async downloadReport() {
      this.$store.commit("uxModule/setShowLoader", true);
      var response = await this.$store.dispatch(
        "reportsModule/getSessionRatesReport",
        {
          programs: this.selectedPrograms,
          services: this.selectedServices,
        }
      );
      if (response) {
        await downloadFile(
          response,
          `ActiveRates_${formatDateForFilename(new Date())}.csv`
        );
      } else {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Oops! Something went wrong. Please try again later."
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
      this.$store.commit("uxModule/setShowLoader", false);
    },
  },
};
</script>
