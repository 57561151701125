var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"d-flex pb-10 mb-5"},[_c('v-btn',{attrs:{"text":"","plain":"","color":"primary","link":"","to":{ name: 'Scheduled Deductions' }}},[_vm._v(" Other Earnings ")]),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-menu-right ")]),_c('v-btn',{attrs:{"text":"","plain":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$route.name)+" ")])],1),_c('v-toolbar',{staticClass:"mb-5",attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Scheduled deductions")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{staticClass:"btn mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.newItem}},[_vm._v(" New ")])],1),_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-row',{staticClass:"mt-5 mb-2"},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"height":"42","items":_vm.activationTypes,"label":"Show activation status"},model:{value:(_vm.filterActivation),callback:function ($$v) {_vm.filterActivation=$$v},expression:"filterActivation"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"height":"42","item-text":"name","item-value":"earningsCodeId","items":_vm.deductionTypes,"multiple":"","label":"Show deduction type"},model:{value:(_vm.filterType),callback:function ($$v) {_vm.filterType=$$v},expression:"filterType"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"clearable":"","label":"Search","height":"42","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 mx-auto",attrs:{"headers":_vm.headers,"items":_vm.tableData,"sort-by":"effective_date"},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(item.amount)+" ")]}},{key:"item.activation",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"input-value":item.isActive,"value":""},on:{"change":function($event){return _vm.changeActivation(item.scheduledDeductionId)}}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.editItem(item.scheduledDeductionId)}}},'v-icon',attrs,false),on),[_vm._v(" fa fa-pencil-square-o ")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit scheduled deduction")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.deleteItem(item.scheduledDeductionId)}}},[_vm._v(" fa fa-trash-o ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete scheduled deduction")])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }