<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          outlined
          text
          small
          v-bind="attrs"
          @click="openDialog"
          v-on="on"
          color="primary"
        >
          + Manual Check
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Add Manual Check
        </v-card-title>

        <v-card-text class="mt-4">
          <v-row>
            <v-col cols="10">
              <v-autocomplete
                :items="providerOptions"
                v-model="selectedProvider"
                label="Select provider to add"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="10">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate"
                    :clearable="false"
                    label="Start date"
                    prepend-icon="mdi-calendar"
                    readonly
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="startDate = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="10">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDate"
                    :clearable="false"
                    label="End date"
                    prepend-icon="mdi-calendar"
                    readonly
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <loader></loader>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="cancel"> Cancel </v-btn>
          <v-btn color="primary" text @click="createManualCheck">
            Create Manual Check
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Loader from "../../components/Shared/loader";

export default {
  components: {
    Loader,
  },

  props: ["payrollBatchId"],
  data: function () {
    return {
      dialog: false,
      selectedProvider: null,
      startDate: "",
      endDate: "",
      menu1: false,
      menu2: false,
    };
  },
  computed: {
    providerOptions() {
      return this.$store.getters[
        "payrollBatchModule/getProviderOptionsForAddingManualCheck"
      ];
    },
  },
  methods: {
    async openDialog() {
      this.startDate = new Date().toISOString().substr(0, 10);
      this.endDate = new Date().toISOString().substr(0, 10);
      this.selectedProvider = null;
    },
    cancel() {
      this.dialog = false;
    },
    async createManualCheck() {
      this.$store.commit("uxModule/setShowSnackbar", false);
      var currBatchId = this.$store.state.payrollBatchModule.currentBatchId;
      this.dialog = false;
      this.$store.commit("uxModule/setShowLoader", true);
      var success = await this.$store.dispatch(
        "payrollBatchModule/createManualRemittanceReport",
        {
          providerId: this.selectedProvider,
          payrollBatchId: currBatchId,
          start: this.startDate,
          end: this.endDate,
        }
      );
      this.$store.commit("uxModule/setShowLoader", false);
      if (!success) {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Error creating manaul check."
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
    },
  },
};
</script>
