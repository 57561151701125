<template>
  <v-card class="mx-auto">
    <v-card-title>
      <v-toolbar-title class="mr-8">Provider Remittances:</v-toolbar-title>
      <v-autocomplete
        v-model="providerId"
        prepend-icon="mdi-magnify"
        clearable
        :items="providers"
        :item-text="
          (item) =>
            `${item.firstName} ${item.lastName} - ${item.legacyProviderId}`
        "
        item-value="providerId"
        label="Search by provider"
      ></v-autocomplete>
    </v-card-title>
    <provider-remittances-table
      :providerId="providerId"
    ></provider-remittances-table>
  </v-card>
</template>
<script>
import ProviderRemittancesTable from "./provider-remittances-table";
export default {
  components: {
    ProviderRemittancesTable,
  },
  data() {
    return {
      providerId: "",
    };
  },
  async mounted() {
    if (this.providers.length === 0) {
      await this.$store.dispatch("providerModule/getAllProviders");
    }
    this.providerId = this.$route.query.providerId || "";
  },
  computed: {
    providers: function () {
      return this.$store.state.providerModule.providers;
    },
  },
  watch: {
    providerId: function () {
      if (this.providerId == this.$route.query.providerId) {
        return;
      }
      this.$router.push({
        name: "Provider Remittances",
        query: { providerId: this.providerId },
      });
    },
  },
};
</script>
