<template>
  <v-btn color="primary" icon v-on:click="override"
    ><v-icon x-small title="Automatic override to erase adjustment"
      >mdi-eraser</v-icon
    ></v-btn
  >
</template>

<script>
export default {
  props: ["sessionChargeId", "calculatedAmount", "adjustedAmount"],
  methods: {
    async override() {
      var amount = this.calculatedAmount - this.adjustedAmount;

      await this.$store.dispatch("payrollBatchModule/overrideSessionCharge", {
        sessionChargeId: this.sessionChargeId,
        amount: amount,
        explanation: this.explanation,
      });
    },
  },
};
</script>
