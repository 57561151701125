
<template>
  <div>
    <v-toolbar flat>
      <v-autocomplete
        v-model="providerId"
        prepend-icon="mdi-magnify"
        clearable
        :items="providers"
        :item-text="
          (item) =>
            `${item.firstName} ${item.lastName} - ${item.legacyProviderId}`
        "
        item-value="providerId"
        label="Search by provider"
      ></v-autocomplete>
    </v-toolbar>
    <v-row v-show="Boolean(providerId) && !loading">
      <v-col cols="2">
        <balances></balances>
      </v-col>
      <v-col cols="10">
        <router-view></router-view>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Balances from "./balances.vue";
export default {
  components: {
    Balances,
  },
  async mounted() {
    if (this.providers.length === 0) {
      await this.$store.dispatch("providerModule/getAllProviders");
    }
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    providers() {
      return this.$store.state.providerModule.providers;
    },
    providerId: {
      get() {
        return this.$store.state.paidSickLeaveModule.providerId;
      },
      set(newVal) {
        this.$store.dispatch("paidSickLeaveModule/clearData");
        if (this.$route.name !== "Provider Requests") {
          this.$router.push({ name: "Provider Requests" });
        }
        return this.$store.dispatch(
          "paidSickLeaveModule/setSelectedProviderId",
          {
            providerId: newVal,
          }
        );
      },
    },
  },
  methods: {
    async loadData() {
      this.loading = true;
      this.$store.commit("uxModule/setShowLoader", true);

      await this.$store.dispatch(
        "paidSickLeaveModule/getBalanceDetailsForProvider",
        { providerId: this.providerId, date: new Date(Date.now()) }
      );
      await this.$store.dispatch(
        "paidSickLeaveModule/getAllRequestsForProvider",
        { providerId: this.providerId }
      );
      this.$store.commit("uxModule/setShowLoader", false);
      this.loading = false;
    },
  },
  watch: {
    providerId: {
      immediate: true,
      handler() {
        if (this.providerId) {
          this.loadData();
        }
      },
    },
  },
};
</script>