<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip bottom v-bind="attrs" v-on="on">
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn
              text
              x-small
              :disabled="isReversed"
              v-on="on"
              @click="showDialog"
            >
              <v-icon>mdi-undo</v-icon>
            </v-btn>
          </div>
        </template>
        <span> {{ tooltipTitle }} </span>
      </v-tooltip>
    </template>

    <v-card>
      <v-card-title>
        Are you sure you want to revert this report?
      </v-card-title>

      <v-card-text>
        Note: This action cannot be undone.
        <v-text-field
          label="Reason"
          v-model="explanation"
          class="mx-3 mt-3"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark text @click="cancel">Cancel</v-btn>
        <v-btn color="primary" dark outlined @click="revert">Revert</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { formatDateAndTime } from "../../utils/format-utils";
export default {
  props: ["remittanceReportId"],
  data() {
    return {
      dialog: false,
      explanation: "",
    };
  },
  computed: {
    remittanceReport: function () {
      return this.$store.getters["payrollBatchModule/getRemittanceReport"](
        this.remittanceReportId
      );
    },
    isReversed: function () {
      return this.remittanceReport?.isReversed;
    },
    tooltipTitle: function () {
      return this.isReversed
        ? "Report was reverted " +
            formatDateAndTime(this.remittanceReport?.reversedAt)
        : "Revert Report";
    },
  },
  methods: {
    showDialog() {
      this.dialog = true;
    },
    cancel() {
      this.dialog = false;
    },
    revert: async function () {
      this.dialog = false;
      this.$store.commit("uxModule/setShowLoader", true);
      let success = await this.$store.dispatch(
        "payrollBatchModule/revertRemittanceReport",
        {
          remittanceReportId: this.remittanceReportId,
          reversalExplanation: this.explanation,
        }
      );
      if (!success) {
        this.$store.commit("uxModule/setSnackbarMsg", "Error reverting report");
        this.$store.commit("uxModule/setShowSnackbar", true);
      } else {
        let success = await this.$store.dispatch(
          "payrollBatchModule/regeneratePdf",
          {
            remittanceReportId: this.remittanceReportId,
          }
        );
        if (!success) {
          this.$store.commit(
            "uxModule/setSnackbarMsg",
            "Error regenerating pdf"
          );
          this.$store.commit("uxModule/setShowSnackbar", true);
        }
      }
      this.$store.commit("uxModule/setShowLoader", false);
    },
  },
};
</script>
