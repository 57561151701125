<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          outlined
          text
          small
          v-bind="attrs"
          @click="openDialog"
          v-on="on"
          color="primary"
        >
          + Providers
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Add Providers To Batch
        </v-card-title>

        <v-card-text class="mt-4">
          <v-row>
            <v-col cols="10">
              <v-autocomplete
                :items="providerOptions"
                v-model="selectedProvider"
                label="Select provider to add"
                item-text="fullName"
                item-value="providerId"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <loader></loader>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="cancel"> Cancel </v-btn>
          <v-btn color="primary" text @click="addProvider">
            Add Provider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Loader from "../../components/Shared/loader";

export default {
  components: {
    Loader,
  },
  props: ["payrollBatchId"],
  data: function () {
    return {
      dialog: false,
      selectedProvider: null,
      providerOptions: [],
    };
  },
  methods: {
    async openDialog() {
      this.$store.commit("uxModule/setShowLoader", true);
      this.selectedProvider = null;
      var providers = await this.$store.dispatch(
        "payrollBatchModule/getProvidersToAdd"
      );
      providers.forEach((t) => {
        if (t != null) {
          t.fullName = t.lastName + ", " + t.firstName;
        }
      });
      var provAlph = providers.sort((a, b) =>
        a.fullName.localeCompare(b.fullName)
      );

      this.providerOptions = provAlph;
      this.$store.commit("uxModule/setShowLoader", false);
    },
    cancel() {
      this.providerOptions = [];
      this.dialog = false;
    },
    async addProvider() {
      this.$store.commit("uxModule/setShowLoader", true);
      await this.$store.dispatch(
        "payrollBatchModule/addProviderToBatch",
        this.selectedProvider
      );
      this.$store.commit("uxModule/setShowLoader", false);
      this.providerOptions = [];
      this.dialog = false;
      //generate the remittance report
      //add the report to current batch
    },
  },
};
</script>
