// school year is July 1 - June 30
export const getSchoolYearFromDate = (value) => {
  if (value) {
    let date = new Date(value);
    // if month is Jan-June then return that year
    if (date.getMonth() < 6) return date.getFullYear();
    // else if month is July-Dec then return the following year
    else return date.getFullYear() + 1;
  }
};
