<template>
  <div>
    <v-dialog v-model="dialog" width="80vw">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          icon
          title="Charges"
          v-bind="attrs"
          v-on:click="load"
          v-on="on"
        >
          <v-icon small>mdi-cash-multiple</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Associated Charges
        </v-card-title>
        <v-card-text>
          <v-row>
            <charges-table class="mt-5 ml-2" :charges="charges"></charges-table>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ChargesTable from "./charges-table";
export default {
  props: ["sessionId"],
  components: {
    "charges-table": ChargesTable,
  },
  data: function () {
    return {
      dialog: false,
    };
  },
  computed: {
    charges() {
      return this.$store.state.sessionModule.sessionCharges;
    },
  },
  methods: {
    async load() {
      this.$store.commit("uxModule/setShowLoader", true);
      var success = await this.$store.dispatch(
        "sessionModule/getChargesForSession",
        this.sessionId
      );
      this.$store.commit("uxModule/setShowLoader", false);
      if (!success) {
        this.$store.commit("uxModule/setShowSnackbarMsg", {
          msg: "No charges found",
          show: true,
        });
      }
    },
    close() {
      this.$store.commit("sessionModule/clearSessionCharges");
      this.dialog = false;
    },
  },
};
</script>
