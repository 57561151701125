export const formatDate = (value) => {
  if (value) return new Date(value).toLocaleDateString("en-US");
  return "";
};

export const formatTime = (value) => {
  if (value) {
    return new Date(value).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
  }
  return "";
};

export const formatDateAndTime = (value) => {
  if (value) {
    return (
      new Date(value).toLocaleDateString("en-US") +
      "  " +
      new Date(value).toLocaleTimeString(["en-US"], { timeStyle: "short" })
    );
  }
  return "";
};

export const formatTime24Hour = (value) => {
  if (value) {
    return new Date(value).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  }
  return "";
};

export const getDateFromDisplayTime = (value) => {
  if (value) {
    let [hours, minutes] = value.split(":");
    let dateValue = new Date();
    dateValue.setHours(hours);
    dateValue.setMinutes(minutes);
    return dateValue;
  }
  return "";
};

export const formatDateForFilename = (value) => {
  return formatDate(value).replace(/\//g, "-");
};

export const formatCurrency = (value) => {
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return formatter.format(value);
};

export const formatListOr = (value) => {
  const formatter = new Intl.ListFormat("en-US", {
    style: "short",
    type: "disjunction",
  });

  return value && formatter.format(value);
};