var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:_vm.loadingClass},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-title',{staticClass:"headline"},[_vm._v(" Provider Corporation ")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-checkbox',{attrs:{"label":"Staffing agency","disabled":_vm.isDisableStaffingCheckbox},model:{value:(_vm.form.isStaffingAgency),callback:function ($$v) {_vm.$set(_vm.form, "isStaffingAgency", $$v)},expression:"form.isStaffingAgency"}})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"rules":_vm.validations.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Name","dense":"","error-messages":errors},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"rules":_vm.validations.taxId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.maskTaxId),expression:"maskTaxId"}],attrs:{"label":"Tax ID","dense":"","error-messages":errors},model:{value:(_vm.form.taxId),callback:function ($$v) {_vm.$set(_vm.form, "taxId", $$v)},expression:"form.taxId"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"rules":_vm.validations.streetAddress},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Street Address","dense":"","error-messages":errors},model:{value:(_vm.form.streetAddress),callback:function ($$v) {_vm.$set(_vm.form, "streetAddress", $$v)},expression:"form.streetAddress"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Apartment / Suite","dense":""},model:{value:(_vm.form.streetAddressLine2),callback:function ($$v) {_vm.$set(_vm.form, "streetAddressLine2", $$v)},expression:"form.streetAddressLine2"}})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"rules":_vm.validations.city},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"City","dense":"","error-messages":errors},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"rules":_vm.validations.state},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.usStates,"label":"State","dense":"","error-messages":errors},model:{value:(_vm.form.state),callback:function ($$v) {_vm.$set(_vm.form, "state", $$v)},expression:"form.state"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"rules":_vm.validations.zipCode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.maskZipCode),expression:"maskZipCode"}],attrs:{"label":"Zip Code","dense":"","error-messages":errors},model:{value:(_vm.form.zipCode),callback:function ($$v) {_vm.$set(_vm.form, "zipCode", $$v)},expression:"form.zipCode"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"rules":_vm.validations.phoneNumber},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.maskPhone),expression:"maskPhone"}],attrs:{"label":"Phone Number","dense":"","error-messages":errors},model:{value:(_vm.form.phoneNumber),callback:function ($$v) {_vm.$set(_vm.form, "phoneNumber", $$v)},expression:"form.phoneNumber"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.saveCorporation}},[_vm._v(" Save ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }