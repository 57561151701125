import * as api from "../Services/api.js";
import PaidSickLeaveRequestSession from "../../objs/PaidSickLeaveRequestSession";
import PaidSickLeaveRequest from "../../objs/PaidSickLeaveRequest";
import PaidSickLeaveCarryover from "../../objs/PaidSickLeaveCarryover";

export const state = {
  providerId: 0,
  totalHoursAvailable: 0,
  hoursPendingRequests: 0,
  hoursAppliedThisYear: 0,
  remainingHoursAvailable: 0,
  schoolYear: 0,
  maxHoursAllowedPerSchoolYear: 0,
  requests: [],
  cancelledSessions: [],
  carryover: [],
};
export const mutations = {
  SET_PROVIDER_ID(state, payload) {
    state.providerId = payload;
  },
  SET_MAX_HOURS_ALLOWED(state, payload) {
    state.maxHoursAllowedPerSchoolYear = payload;
  },
  SET_BALANCE_DETAILS(state, payload) {
    state.totalHoursAvailable =
      Math.round(payload.totalHoursAvailable * 10) / 10;
    state.hoursPendingRequests =
      Math.round(payload.hoursPendingRequests * 10) / 10;
    state.hoursAppliedThisYear =
      Math.round(payload.hoursAppliedThisYear * 10) / 10;
    state.remainingHoursAvailable =
      Math.round(payload.remainingHoursAvailable * 10) / 10;
    state.schoolYear = payload.schoolYear;
  },
  SET_CANCELLED_SESSIONS(state, payload) {
    state.cancelledSessions = payload.map(
      (item) => new PaidSickLeaveRequestSession(item)
    );
  },
  SET_REQUESTS(state, payload) {
    state.requests = payload.map((item) => new PaidSickLeaveRequest(item));
  },
  ADD_REQUEST(state, payload) {
    state.requests.push(new PaidSickLeaveRequest(payload));
  },
  UPDATE_REQUEST(state, payload) {
    state.requests = state.requests.map((item) => {
      return item.paidSickLeaveRequestId == payload.paidSickLeaveRequestId
        ? new PaidSickLeaveRequest(payload)
        : item;
    });
  },
  DELETE_REQUEST(state, requestId) {
    state.requests = state.requests.filter(
      (item) => item.paidSickLeaveRequestId != requestId
    );
  },
  SET_CARRYOVER(state, payload) {
    state.carryover = payload.map((item) => new PaidSickLeaveCarryover(item));
  },
  UPDATE_CARRYOVER(state, payload) {
    state.carryover = state.carryover.map((item) => {
      return item.paidSickLeaveCarryoverId == payload.paidSickLeaveCarryoverId
        ? new PaidSickLeaveCarryover(payload)
        : item;
    });
  },
};

export const getters = {};

export const actions = {
  setSelectedProviderId({ commit }, data) {
    commit("SET_PROVIDER_ID", data.providerId);
  },
  clearData({ commit }) {
    commit("SET_PROVIDER_ID", 0);
    commit("SET_REQUESTS", []);
    commit("SET_BALANCE_DETAILS", {
      totalHoursAvailable: 0,
      hoursAppliedThisYear: 0,
      hoursPendingRequests: 0,
      remainingHoursAvailable: 0,
    });
    commit("SET_CANCELLED_SESSIONS", []);
  },
  async getMaxPaidSickLeaveHoursPerSchoolYear({ commit }) {
    return await api
      .get("/paidSickLeave/getMaxPaidSickLeaveHoursPerSchoolYear")
      .then((response) => {
        if (response.success) {
          commit("SET_MAX_HOURS_ALLOWED", response.data);
        }
        return response.success;
      });
  },
  async getBalanceDetailsForProvider({ commit }, data) {
    return await api
      .getQuery("/paidSickLeave/getBalanceDetailsForProvider", {
        providerId: data.providerId,
        date: data.date,
      })
      .then((response) => {
        if (response.success) {
          commit("SET_BALANCE_DETAILS", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async getAllRequestsForProvider({ commit, state }, data) {
    return await api
      .getQuery("/paidSickLeave/loadPaidSickLeaveRequestsForProvider", {
        providerId: data.providerId,
      })
      .then((response) => {
        if (response.success) {
          commit("SET_REQUESTS", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async getAllOpenRequests({ commit }) {
    return await api
      .get("/paidSickLeave/loadOpenRequests")
      .then((response) => {
        if (response.success) {
          commit("SET_REQUESTS", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async getCancelledSessionsForProviderForDateRange({ commit, state }, data) {
    return await api
      .getQuery("/paidSickLeave/getCancelledSessionsForProviderDateRange", {
        providerId: state.providerId,
        start: data.startDate,
        end: data.endDate,
      })
      .then((response) => {
        if (response.success) {
          commit("SET_CANCELLED_SESSIONS", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async submitNewRequest({ commit, state, dispatch }, data) {
    return await api
      .post("/paidSickLeave/createPaidSickLeaveRequest", {
        ...data,
        providerId: state.providerId,
        requestSubmittedAt: new Date(Date.now()),
      })
      .then((response) => {
        if (response.success) {
          commit("ADD_REQUEST", response.data);
          dispatch("getBalanceDetailsForProvider", {
            providerId: state.providerId,
            date: new Date(Date.now()),
          });
          return "";
        }

        return response.message;
      })
      .catch((error) => {
        return "An error occurred while creating this request";
      });
  },
  async updateRequest({ commit, state, dispatch }, data) {
    return await api
      .post("/paidSickLeave/updatePaidSickLeaveRequest", {
        ...data,
        providerId: state.providerId,
        requestSubmittedAt: new Date(Date.now()),
      })
      .then((response) => {
        if (response.success) {
          commit("UPDATE_REQUEST", response.data);
          dispatch("getBalanceDetailsForProvider", {
            providerId: state.providerId,
            date: new Date(Date.now()),
          });
          return "";
        }

        return response.message;
      })
      .catch((error) => {
        return "An error occurred while updating this request";
      });
  },
  async approveRequest({ commit, state, dispatch }, data) {
    return await api
      .postQuery("/paidSickLeave/approvePaidSickLeaveRequest", {
        paidSickLeaveRequestId: data.requestId,
      })
      .then((response) => {
        if (response.success) {
          commit("UPDATE_REQUEST", response.data);
          dispatch("getBalanceDetailsForProvider", {
            providerId: state.providerId,
            date: new Date(Date.now()),
          });
          return "";
        }

        return response.message;
      })
      .catch((error) => {
        return "An error occurred while approving this request";
      });
  },
  async denyRequest({ commit, state, dispatch }, data) {
    return await api
      .postQuery("/paidSickLeave/denyPaidSickLeaveRequest", {
        paidSickLeaveRequestId: data.requestId,
      })
      .then((response) => {
        if (response.success) {
          commit("UPDATE_REQUEST", response.data);
          dispatch("getBalanceDetailsForProvider", {
            providerId: state.providerId,
            date: new Date(Date.now()),
          });
          return "";
        }

        return response.message;
      })
      .catch((error) => {
        return "An error occurred while denying this request";
      });
  },
  async loadCarryover({ commit }, data) {
    return await api
      .getQuery("/paidSickLeave/loadCarryover", data)
      .then((response) => {
        if (response.success) {
          commit("SET_CARRYOVER", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async exportCarryover({}) {
    return await api
      .postQuery("/paidSickLeave/exportCarryover")
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return false;
      });
  },
  async moveBalancesToCarryover({ dispatch }, data) {
    return await api
      .postQuery("/paidSickLeave/moveAllBalancesToCarryover", data)
      .then((response) => {
        if (response.success) {
          dispatch("loadCarryover", { date: new Date() });
        }
        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async resetCarryover({ commit }, data) {
    return await api
      .post("/paidSickLeave/resetCarryover", data)
      .then((response) => {
        if (response.success) {
          commit("UPDATE_CARRYOVER", response.data);
        }
        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async bulkResetCarryover({ commit, dispatch }, data) {
    let failures = [];
    for await (const carryover of data) {
      let response = await api.post("/paidSickLeave/resetCarryover", carryover);

      if (!response.success) {
        failures.push(carryover);
      }
    }

    dispatch("loadCarryover", { date: new Date() });

    let message =
      failures.length == 0
        ? "All carryover reset successfully."
        : `Failed to reset carryover for ${failures.map(
            (item) => item.providerName
          )}`;
    commit("uxModule/setSnackbarMsg", message, {
      root: true,
    });
    commit("uxModule/setShowSnackbar", true, {
      root: true,
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
