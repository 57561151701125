var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.newform)?_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"text":"","plain":"","color":"primary","link":"","to":{ name: 'All Providers' }}},[_vm._v(" Provider Rates ")]),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-menu-right ")]),_c('v-btn',{staticClass:"lower-case",attrs:{"text":"","plain":"","color":"primary","link":"","to":{ name: 'All Providers' }}},[_vm._v(" "+_vm._s(_vm.providerName)+" ")]),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-menu-right ")]),(_vm.form.sessionRateId === 0)?_c('v-btn',{attrs:{"text":"","plain":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$route.name)+" ")]):_c('v-btn',{attrs:{"text":"","plain":"","color":"primary"}},[_vm._v(" Edit Session Rate ")])],1):_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"text":"","plain":"","color":"primary","link":"","to":{ name: 'Current batch' }}},[_vm._v(" "+_vm._s(_vm.batchLabel)+" ")]),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-menu-right ")]),_c('v-btn',{staticClass:"lower-case",attrs:{"text":"","plain":"","color":"primary","link":"","to":{ name: 'Remittance report' }}},[_vm._v(" "+_vm._s(_vm.providerLabel)+" ")]),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-menu-right ")]),_c('v-btn',{attrs:{"text":"","plain":"","color":"primary"}},[_vm._v(" New Rate ")])],1),_c('v-toolbar',{staticStyle:{"margin-top":"20px"},attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Session Rate")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('date-picker',{attrs:{"value":_vm.form.effectiveDate,"field":{ label: 'Effective Date' },"validations":{ required: true }},on:{"fieldChange":function($event){_vm.form.effectiveDate = $event}}})],1)],1),_c('v-row',[_c('v-radio-group',{model:{value:(_vm.lastEffective),callback:function ($$v) {_vm.lastEffective=$$v},expression:"lastEffective"}},[_c('v-radio',{attrs:{"label":"Indeterminate","value":"Indeterminate"}}),_c('v-radio',{attrs:{"value":"Specific date"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"mr-5"},[_vm._v("Specific date")]),(_vm.showLastEffective)?_c('date-picker',{attrs:{"value":_vm.form.lastEffectiveDate,"field":{ label: 'Last effective date' },"validations":_vm.validations.lastEffectiveDate},on:{"fieldChange":function($event){_vm.form.lastEffectiveDate = $event}}}):_vm._e()]},proxy:true}],null,true)})],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLastEffective),expression:"showLastEffective"}]}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":_vm.validations.programId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.programs,"item-text":function (item) { return ((item.code) + " - " + (item.name)); },"item-value":"programId","label":"Program","dense":"","error-messages":errors},model:{value:(_vm.form.programId),callback:function ($$v) {_vm.$set(_vm.form, "programId", $$v)},expression:"form.programId"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":_vm.validations.serviceId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.serviceOptions,"item-text":"name","item-value":"serviceId","label":"Service","dense":"","error-messages":errors},model:{value:(_vm.form.serviceId),callback:function ($$v) {_vm.$set(_vm.form, "serviceId", $$v)},expression:"form.serviceId"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"rules":_vm.validations.taxStatusId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.taxStatuses,"item-text":"name","item-value":"taxStatusId","label":"Tax/Billing Status","dense":"","error-messages":errors},model:{value:(_vm.form.taxStatusId),callback:function ($$v) {_vm.$set(_vm.form, "taxStatusId", $$v)},expression:"form.taxStatusId"}})]}}],null,true)})],1),(_vm.form.taxStatusId === 3 && _vm.associatedCorporations.length > 0)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-radio-group',{staticClass:"mt-0",model:{value:(_vm.professionActionType),callback:function ($$v) {_vm.professionActionType=$$v},expression:"professionActionType"}},_vm._l((_vm.professionActionRadios),function(item,index){return _c('v-radio',{key:index,attrs:{"label":item.text,"value":item.value}})}),1)],1):_vm._e(),(
          _vm.form.taxStatusId === 3 &&
          _vm.professionActionType === 1 &&
          _vm.associatedCorporations.length > 0
        )?_c('v-col',[_c('validation-provider',{attrs:{"rules":_vm.validations.providerCorporationId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.associatedCorporations,"item-text":"name","item-value":"providerCorporationId","label":"Select an existing company","dense":"","error-messages":errors},model:{value:(_vm.form.providerCorporationId),callback:function ($$v) {_vm.$set(_vm.form, "providerCorporationId", $$v)},expression:"form.providerCorporationId"}})]}}],null,true)})],1):_vm._e(),(
          _vm.form.taxStatusId === 3 &&
          (_vm.associatedCorporations.length === 0 || _vm.professionActionType === 2)
        )?_c('v-col',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.createCorporation(false)}}},[_vm._v(" Create new company ")])],1):_vm._e(),(_vm.form.taxStatusId === 4)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-radio-group',{staticClass:"mt-0",model:{value:(_vm.agencyActionType),callback:function ($$v) {_vm.agencyActionType=$$v},expression:"agencyActionType"}},_vm._l((_vm.agencyActionRadios),function(item,index){return _c('v-radio',{key:index,attrs:{"label":item.text,"value":item.value}})}),1)],1):_vm._e(),(_vm.form.taxStatusId === 4 && _vm.agencyActionType === 1)?_c('v-col',[_c('validation-provider',{attrs:{"rules":_vm.validations.providerCorporationId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.allStaffingAgencies,"item-text":"name","item-value":"providerCorporationId","label":"Select staffing agency","dense":"","error-messages":errors},model:{value:(_vm.form.providerCorporationId),callback:function ($$v) {_vm.$set(_vm.form, "providerCorporationId", $$v)},expression:"form.providerCorporationId"}})]}}],null,true)})],1):_vm._e(),(
          _vm.form.taxStatusId === 4 &&
          (_vm.allStaffingAgencies.length === 0 || _vm.agencyActionType === 2)
        )?_c('v-col',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.createCorporation(true)}}},[_vm._v(" Create new company ")])],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":_vm.validations.rateUnitTypeId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.rateUnitTypes,"item-text":"name","item-value":"rateUnitTypeId","label":"Unit Type","dense":"","error-messages":errors},model:{value:(_vm.form.rateUnitTypeId),callback:function ($$v) {_vm.$set(_vm.form, "rateUnitTypeId", $$v)},expression:"form.rateUnitTypeId"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":_vm.validations.sessionLocationCategory},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.sessionLocationCategories,"label":"Location Type","dense":"","error-messages":errors},model:{value:(_vm.form.sessionLocationCategory),callback:function ($$v) {_vm.$set(_vm.form, "sessionLocationCategory", $$v)},expression:"form.sessionLocationCategory"}})]}}],null,true)})],1),(_vm.form.sessionLocationCategory === 'Specific location')?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":_vm.validations.sessionLocationId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.sessionLocations,"item-text":function (item) { return ((item.name) + " (" + (item.legacyLocationId) + ")"); },"item-value":"sessionLocationId","label":"Location","clearable":"","dense":"","error-messages":errors},model:{value:(_vm.form.sessionLocationId),callback:function ($$v) {_vm.$set(_vm.form, "sessionLocationId", $$v)},expression:"form.sessionLocationId"}})]}}],null,true)})],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"label":"Group","dense":"","hide-details":"auto"},model:{value:(_vm.form.isGroup),callback:function ($$v) {_vm.$set(_vm.form, "isGroup", $$v)},expression:"form.isGroup"}})],1),(_vm.form.isGroup)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-radio-group',{staticClass:"mt-0",model:{value:(_vm.groupType),callback:function ($$v) {_vm.groupType=$$v},expression:"groupType"}},_vm._l((_vm.groupRadios),function(item,index){return _c('v-radio',{key:index,attrs:{"label":item.text,"value":item.value}})}),1)],1):_vm._e(),(_vm.form.isGroup && _vm.groupType === 1)?_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"rules":_vm.validations.groupSize},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Group Size","dense":"","type":"number","min":"0","step":"1","error-messages":errors},model:{value:(_vm.form.groupSize),callback:function ($$v) {_vm.$set(_vm.form, "groupSize", $$v)},expression:"form.groupSize"}})]}}],null,true)})],1):_vm._e(),(_vm.form.isGroup && _vm.groupType === 2)?_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"rules":_vm.validations.groupSizeMin},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mr-5",attrs:{"label":"Min","dense":"","type":"number","min":"0","step":"1","error-messages":errors},model:{value:(_vm.form.groupSizeMin),callback:function ($$v) {_vm.$set(_vm.form, "groupSizeMin", $$v)},expression:"form.groupSizeMin"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":_vm.validations.groupSizeMax},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Max","dense":"","type":"number","min":"0","step":"1","error-messages":errors},model:{value:(_vm.form.groupSizeMax),callback:function ($$v) {_vm.$set(_vm.form, "groupSizeMax", $$v)},expression:"form.groupSizeMax"}})]}}],null,true)})],1):_vm._e()],1),_c('v-row',[_c('v-col',[_c('v-checkbox',{staticClass:"mt-0",attrs:{"label":"Bilingual","dense":"","hide-details":"auto"},model:{value:(_vm.form.isBilingual),callback:function ($$v) {_vm.$set(_vm.form, "isBilingual", $$v)},expression:"form.isBilingual"}})],1),(_vm.form.isBilingual)?_c('v-col',[_c('validation-provider',{attrs:{"rules":_vm.validations.languages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.languages,"item-text":"name","return-object":"","label":"Language","menu-props":{ openOnHover: true },"chips":"","clearable":"","dense":"","multiple":"","error-messages":errors},model:{value:(_vm.form.languages),callback:function ($$v) {_vm.$set(_vm.form, "languages", $$v)},expression:"form.languages"}})]}}],null,true)})],1):_vm._e()],1),_c('v-row',[_c('v-col',[_c('v-checkbox',{staticClass:"mt-0",attrs:{"label":"Client specific","dense":"","hide-details":"auto"},model:{value:(_vm.form.isClientSpecific),callback:function ($$v) {_vm.$set(_vm.form, "isClientSpecific", $$v)},expression:"form.isClientSpecific"}})],1),(_vm.form.isClientSpecific)?_c('v-col',[_c('validation-provider',{attrs:{"rules":_vm.validations.clientId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.clients,"item-text":"text","item-value":"value","label":"Client","chips":"","clearable":"","dense":"","error-messages":errors},model:{value:(_vm.form.clientId),callback:function ($$v) {_vm.$set(_vm.form, "clientId", $$v)},expression:"form.clientId"}})]}}],null,true)})],1):_vm._e()],1),(_vm.hasSEITDiscipline)?_c('v-row',[_c('v-col',[_c('v-checkbox',{staticClass:"mt-0",attrs:{"label":"Disable SEIT 20+ Hours Rate","dense":"","hide-details":"auto"},model:{value:(_vm.form.bonusRateDisabled),callback:function ($$v) {_vm.$set(_vm.form, "bonusRateDisabled", $$v)},expression:"form.bonusRateDisabled"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"rules":_vm.validations.dollarAmount},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Amount","dense":"","prefix":"$","type":"number","min":"0","step":"0.01","error-messages":errors},model:{value:(_vm.form.dollarAmount),callback:function ($$v) {_vm.$set(_vm.form, "dollarAmount", $$v)},expression:"form.dollarAmount"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":function($event){return _vm.saveRates()}}},[_vm._v(" Assign Rate ")])],1)]}}])}),_c('v-dialog',{attrs:{"persistent":"","width":"500"},model:{value:(_vm.corporationDialog),callback:function ($$v) {_vm.corporationDialog=$$v},expression:"corporationDialog"}},[(_vm.corporationDialog)?_c('individual-coporation-modal',{attrs:{"corporation":_vm.newCorporation},on:{"setNewCompany":_vm.setNewCompany,"close":function($event){_vm.corporationDialog = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }