var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table"},[_c('v-row',[_c('v-radio-group',{attrs:{"row":"","label":"Group charges by"},model:{value:(_vm.groupBy),callback:function ($$v) {_vm.groupBy=$$v},expression:"groupBy"}},[_c('v-radio',{attrs:{"label":"Provider","value":"providerName"}}),_c('v-radio',{attrs:{"label":"Client","value":"clientName"}}),_c('v-radio',{attrs:{"label":"Service","value":"serviceName"}}),_c('v-radio',{attrs:{"label":"Program","value":"programName"}})],1),_c('v-spacer'),_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","clearable":"","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.sessions,"sort-by":['providerName', 'clientName', 'dateOfService'],"group-by":_vm.groupBy,"search":_vm.search,"show-select":"","item-key":"sessionId","fixed-header":"","hide-default-footer":"","disable-pagination":"","dense":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-btn',{ref:group,attrs:{"x-small":"","icon":""},on:{"click":toggle}},[(isOpen)?_c('v-icon',[_vm._v("mdi-minus")]):_c('v-icon',[_vm._v("mdi-plus")])],1),_c('span',{staticClass:"mx-5 font-weight-bold",staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(group))])],1)]}},{key:"item.sessionId",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(item.sessionId))])]}},{key:"item.legacyActivityId",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(item.legacyActivityId))])]}},{key:"item.providerName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(item.providerName)+" ")])]}},{key:"item.clientName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(item.clientName))])]}},{key:"item.dateOfService",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(item.formattedDateOfService))])]}},{key:"item.startTime",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(item.startTime))])]}},{key:"item.durationMinutes",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(item.durationMinutes)+" minutes")])]}},{key:"item.programName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(item.programName))])]}},{key:"item.serviceName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(item.serviceName))])]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(item.location))])]}},{key:"item.bilingual",fn:function(ref){
var item = ref.item;
return [(item.isBilingual)?_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v("Yes")]):_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v("No")])]}},{key:"item.language",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(item.language))])]}},{key:"item.invoiced",fn:function(ref){
var item = ref.item;
return [(item.invoiced)?_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v("Yes")]):_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v("No")])]}},{key:"item.sessionStatus",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(item.sessionStatus))])]}},{key:"item.onHold",fn:function(ref){
var item = ref.item;
return [(item.isHoldPay)?_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v("Yes")]):_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v("No")])]}},{key:"item.overrideAmount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(_vm.formatCurrency(item.overrideAmount)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"align-center"},[_c('override-modal',{attrs:{"sessionId":item.sessionId,"mode":"OverrideSession","noEdit":_vm.noEdit}}),_c('charges-modal',{attrs:{"sessionId":item.sessionId}}),_c('remote-attendance-log-checkbox',{attrs:{"item":item}}),_c('eval-invoice-checkbox',{attrs:{"item":item}})],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }