<template>
  <v-card :class="loadingClass">
    <validation-observer v-slot="{ invalid }">
      <v-card-title class="headline"> Provider Corporation </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-checkbox
              v-model="form.isStaffingAgency"
              label="Staffing agency"
              :disabled="isDisableStaffingCheckbox"
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider :rules="validations.name" v-slot="{ errors }">
              <v-text-field
                v-model="form.name"
                label="Name"
                dense
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col>
            <validation-provider :rules="validations.taxId" v-slot="{ errors }">
              <v-text-field
                v-model="form.taxId"
                label="Tax ID"
                dense
                :error-messages="errors"
                v-mask="maskTaxId"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider
              :rules="validations.streetAddress"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="form.streetAddress"
                label="Street Address"
                dense
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="form.streetAddressLine2"
              label="Apartment / Suite"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider :rules="validations.city" v-slot="{ errors }">
              <v-text-field
                v-model="form.city"
                label="City"
                dense
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col>
            <validation-provider :rules="validations.state" v-slot="{ errors }">
              <v-select
                :items="usStates"
                label="State"
                v-model="form.state"
                dense
                :error-messages="errors"
              ></v-select>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider
              :rules="validations.zipCode"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="form.zipCode"
                label="Zip Code"
                dense
                v-mask="maskZipCode"
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col>
            <validation-provider
              :rules="validations.phoneNumber"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="form.phoneNumber"
                label="Phone Number"
                dense
                v-mask="maskPhone"
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="$emit('close')"> Cancel </v-btn>
        <v-btn color="primary" @click="saveCorporation" :disabled="invalid">
          Save
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import { required, minLength, DistinctTaxId } from "../../plugins/vee-validate/validation";
import ProviderCorporation from "../../objs/ProviderCorporation";

export default {
  name: "NewRatesModal",
  directives: { mask },
  props: ["corporation"],
  data: () => ({
    loadingClass: "",
    form: new ProviderCorporation(),
    maskZipCode: "#####",
    maskPhone: "(###) ###-####",
    maskTaxId: "##-#######",
  }),
  computed: {
    ...mapGetters({
      usStates: "optionModule/usStates",
      getTaxIds: "providerModule/getTaxIds",
    }),
    taxIds: function () {
      return this.$store.getters["providerModule/getTaxIds"];
    },
    uxData() {
      if (this.form.providerCorporationId > 0) {
        return {
          loaderText: "Updating provider corporation...",
          path: "providerModule/updateProviderCorporation",
          snackText: "Updated successfully!",
        };
      }
      return {
        loaderText: "Creating provider corporation...",
        path: "providerModule/createProviderCorporation",
        snackText: "Created successfully!",
      };
    },
    validations() {
      return {
        name: {
          required: true
        },
        taxId: {
          required: true,
          DistinctTaxId: this.taxIds
        },
        streetAddress: {
          required: true
        },
        city: {
          required: true
        },
        state: {
          required: true
        },
        zipCode: {
          required: true
        },
        phoneNumber: {
          required: true,
          minLength: 14
        },
        city: {
          required: true
        },
      }
    },
    isDisableStaffingCheckbox() {
      if (this.$route.name === "New Session Rate") return true;
      return false;
    },
  },
  mounted() {
    this.form = {
      ...this.corporation,
    };
  },
  methods: {
    async saveCorporation() {
      this.form.phoneNumber = this.form.phoneNumber.replace(/\D/g, "");
      this.form.taxId = this.form.taxId.replace(/\D/g, "");
      this.$store.dispatch("uxModule/showLoader", this.uxData.loaderText);
      this.loadingClass = "modal-underloading";
      const res = await this.$store.dispatch(this.uxData.path, {
        ...this.form,
      });
      this.$store.dispatch("uxModule/hideLoader");
      if (this.$route.name === "New Session Rate")
        this.$emit("setNewCompany", res.data.providerCorporationId);
      if (res.success)
        this.$store.dispatch("uxModule/showSnackBarMsg", this.uxData.snackText);
      else this.$store.dispatch("uxModule/showSnackBarMsg", "Raised an error!");
      this.$emit("close");
    },
  },
};
</script>
