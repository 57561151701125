<template>
  <v-container>
    <validation-observer v-slot="{ invalid }" class="mt-10">
      <h2>Generate a new payroll batch</h2>
      <v-row class="mt-5">
        <v-col cols="12" md="3">
          <p>Name:</p>
        </v-col>
        <v-col cols="12" md="3">
          <validation-provider :rules="{ required: true }" v-slot="{ errors }">
            <v-text-field
              v-model="form.name"
              dense
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <p>Start date:</p>
        </v-col>
        <v-col cols="12" md="3">
          <date-picker
            :value="form.startDate"
            :field="{ label: 'Start Date' }"
            :validations="{ required: true }"
            @fieldChange="changeStartDate"
          ></date-picker>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <p>End date:</p>
        </v-col>
        <v-col cols="12" md="3">
          <date-picker
            :value="form.endDate"
            :field="{ label: 'End Date' }"
            :validations="{ required: true }"
            @fieldChange="changeEndDate"
          ></date-picker>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <p>Paycheck date:</p>
        </v-col>
        <v-col cols="12" md="3">
          <date-picker
            :value="form.paycheckDate"
            :field="{ label: 'Paycheck Date' }"
            :validations="{ required: true }"
            @fieldChange="changePaycheckDate"
          ></date-picker>
        </v-col>
      </v-row>
      <v-btn color="primary" :disabled="invalid" @click="generateBatch()">
        Generate batch
      </v-btn>
    </validation-observer>
  </v-container>
</template>

<script>
import { required } from "../../plugins/vee-validate/validation";
import CreatePayrollBatch from "../../objs/CreatePayrollBatch";
export default {
  data() {
    return {
      form: new CreatePayrollBatch(),
    };
  },
  methods: {
    changeStartDate(date) {
      this.form.startDate = date;
    },
    changeEndDate(date) {
      this.form.endDate = date;
    },
    changePaycheckDate(date) {
      this.form.paycheckDate = date;
    },
    async generateBatch() {
      this.$store.commit("uxModule/setShowLoader", true);
      var success = await this.$store.dispatch(
        "payrollBatchModule/generateBatch",
        { ...this.form }
      );

      this.$store.commit("uxModule/setShowLoader", false);

      if (success) {
        this.$router.push({
          name: "All batches",
        });
      } else {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Error generating batch!"
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
    },
  },
};
</script>
