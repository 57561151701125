<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" icon v-bind="attrs" v-on="on" :disabled="noEdit">
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Delete Account
        </v-card-title>
        <v-card-text>
          <v-row class="mt-5">
            <label
              style="
                font-weight: bold;
                font-size: 16px;
                color: #1c344b;
                margin-left: 12px;
              "
              >Are you sure you want to delete this account?</label
            >
          </v-row>
          <v-row>
            <v-col cols="3">
              <label>Provider: </label>
            </v-col>
            <v-col>
              <span>{{ acct.provider }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <label>Corporation: </label>
            </v-col>
            <v-col>
              <span>{{ acct.omniCorporationDivision }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <label>ADP File: </label>
            </v-col>
            <v-col>
              <span>{{ acct.adpFileNo }}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Cancel </v-btn>
          <v-btn color="primary" text v-on:click="deleteAcct"> Delete </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["acct", "noEdit"],
  data: function () {
    return {
      dialog: false,
    };
  },
  methods: {
    async deleteAcct() {
      var id = this.acct.adpAccountId;
      var success = await this.$store.dispatch("adpModule/deleteAdpAccount", {
        adpAccountId: id,
      });
      if (success) {
        this.$store.commit("uxModule/setShowSnackbarMsg", {
          msg: "Account deleted!",
          show: true,
        });
      } else {
        this.$store.commit("uxModule/setShowSnackbarMsg", {
          msg: "Error deleting this account!",
          show: true,
        });
      }
      this.dialog = false;
    },
  },
};
</script>
