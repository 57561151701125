import { formatDate, formatTime } from "../utils/format-utils";

export default class PaidSickLeaveRequestSession {
  paidSickLeaveRequestSessionId?: number | null;
  paidSickLeaveRequestId?: number | null;
  minutesCovered?: number | null;
  sessionId?: number | null;
  legacyActivityId?: number | null;
  dateOfService?: Date | null;
  startTime?: Date | null;
  durationMinutes?: number | null;
  groupSize?: number | null;
  isBilingual?: boolean | null;
  language?: string | null;
  location?: string | null;
  programName?: string | null;
  serviceName?: string | null;
  clientId?: number | null;
  clientName?: string | null;
  providerName?: string | null;
  rateId?: number | null;
  rateSummary?: string | null;

  public constructor(
    params: PaidSickLeaveRequestSession = {} as PaidSickLeaveRequestSession
  ) {
    let {
      paidSickLeaveRequestSessionId = 0,
      paidSickLeaveRequestId = 0,
      minutesCovered = 0,
      sessionId = 0,
      legacyActivityId = 0,
      dateOfService = null,
      startTime = null,
      groupSize = 0,
      durationMinutes = 0,
      isBilingual = false,
      language = "",
      location = "",
      serviceName = "",
      programName = "",
      clientId = 0,
      clientName = "",
      providerName = "",
      rateId = 0,
      rateSummary = "",
    } = params;

    this.paidSickLeaveRequestSessionId = paidSickLeaveRequestSessionId;
    this.paidSickLeaveRequestId = paidSickLeaveRequestId;
    this.minutesCovered = minutesCovered;
    this.sessionId = sessionId;
    this.legacyActivityId = legacyActivityId;
    this.dateOfService = dateOfService;
    this.startTime = startTime;
    this.groupSize = groupSize;
    this.durationMinutes = durationMinutes;
    this.isBilingual = isBilingual;
    this.language = language;
    this.location = location;
    this.serviceName = serviceName;
    this.programName = programName;
    this.clientId = clientId;
    this.clientName = clientName;
    this.providerName = providerName;
    this.rateId = rateId;
    this.rateSummary = rateSummary;
  }

  get formattedDateOfService() {
    if (this.dateOfService) return formatDate(this.dateOfService);
  }

  get formattedStartTime() {
    if (this.startTime) return formatTime(this.startTime);
  }

  get durationHours() {
    if (this.durationMinutes)
      return Math.round((this.durationMinutes / 60) * 10) / 10;
  }

  get isPartialSession() {
    if (this.minutesCovered && this.durationMinutes)
      return this.minutesCovered < this.durationMinutes;
  }
}
