<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          title="Reimport sessions"
          x-small
          v-bind="attrs"
          @click="openDialog"
          v-on="on"
        >
          <v-icon>mdi-database-import</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Refresh Activity Import
        </v-card-title>

        <v-card-text class="mt-4">
          <v-row>
            <v-col cols="10">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate"
                    :clearable="false"
                    label="Start date"
                    prepend-icon="mdi-calendar"
                    readonly
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="startDate = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="10">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDate"
                    :clearable="false"
                    label="End date"
                    prepend-icon="mdi-calendar"
                    readonly
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-overlay :value="showLoader">
            <v-progress-circular indeterminate></v-progress-circular>
          </v-overlay>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="cancel"> Cancel </v-btn>
          <v-btn color="primary" text @click="rerun"> Rerun </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["legacyId", "origStart", "origEnd"],
  data: function () {
    return {
      dialog: false,
      startDate: "",
      endDate: "",
      menu1: false,
      menu2: false,
      showLoader: false,
    };
  },
  methods: {
    openDialog() {
      this.startDate = new Date(this.origStart).toISOString().substr(0, 10);
      this.endDate = new Date(this.origEnd).toISOString().substr(0, 10);
      this.dialog = true;
    },
    cancel() {
      this.dialog = false;
    },
    rerun: async function () {
      this.$store.commit("uxModule/setShowLoader", true);
      this.showLoader = true;
      var success = await this.$store.dispatch(
        "payrollBatchModule/reimportProviderActivities",
        { legacyId: this.legacyId, start: this.startDate, end: this.endDate }
      );
      this.$store.commit("uxModule/setShowLoader", false);
      this.showLoader = false;
      this.dialog = false;
    },
  },
};
</script>
