var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex pb-10 mb-5"},[_c('v-btn',{attrs:{"text":"","plain":"","color":"primary","link":"","to":{ name: 'All Providers' }}},[_vm._v(" Provider Rates ")]),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-menu-right ")]),_c('v-btn',{staticClass:"lower-case",attrs:{"text":"","plain":"","color":"primary","link":"","to":{ name: 'All Providers' }}},[_vm._v(" "+_vm._s(_vm.providerName)+" ")]),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-menu-right ")]),_c('v-btn',{attrs:{"text":"","plain":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$route.name)+" ")])],1),_c('v-data-table',{staticClass:"elevation-1 mx-auto font-weight-medium",attrs:{"headers":_vm.headers,"items":_vm.tableData,"sort-by":['lastEffectiveDate', 'effectiveDate'],"sort-desc":[true, true],"multi-sort":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Rates List")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{staticClass:"btn mb-2",attrs:{"disabled":_vm.noEdit,"color":"primary","dark":!_vm.noEdit},on:{"click":_vm.newItem}},[_vm._v(" New Rate ")])],1)]},proxy:true},{key:"item.groupSize",fn:function(ref){
var item = ref.item;
return [(item.isGroup)?_c('td',[_vm._v(_vm._s(item.groupSize))]):_c('td',{staticClass:"n-a-grey"},[_vm._v("N/A")])]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [(item.isClientSpecific)?_c('td',[_vm._v(_vm._s(_vm.getClientName(item)))]):_c('td',{staticClass:"n-a-grey"},[_vm._v("N/A")])]}},{key:"item.isBilingual",fn:function(ref){
var item = ref.item;
return [(item.isBilingual)?_c('td',_vm._l((item.languages),function(itm,idx){return _c('span',{key:idx,attrs:{"small":""}},[_vm._v(" "+_vm._s(itm.name)+" "),(idx !== item.languages.length - 1)?_c('span',[_vm._v(", ")]):_vm._e()])}),0):_c('td',{staticClass:"n-a-grey"},[_vm._v("N/A")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"x-small":"","color":"primary","disabled":!item.canModify || _vm.noEdit},on:{"click":function($event){return _vm.editItem(item.sessionRateId)}}},'v-icon',attrs,false),on),[_vm._v(" fa fa-pencil-square-o ")])],1)]}}],null,true)},[(!item.canModify)?_c('span',[_vm._v("This rate cannot be modified, as there are already paid charges associated with it.")]):_c('span',[_vm._v("Edit session rate")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":"primary","disabled":!item.canModify || _vm.noEdit},on:{"click":function($event){return _vm.deleteItem(item.sessionRateId)}}},[_vm._v(" fa fa-trash-o ")])],1)]}}],null,true)},[(!item.canModify)?_c('span',[_vm._v("This rate cannot be modified, as there are already charges associated with it.")]):_c('span',[_vm._v("Delete session rate")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":"primary","disabled":_vm.noEdit},on:{"click":function($event){return _vm.duplicate(item.sessionRateId)}}},[_vm._v(" fa fa-files-o ")])],1)]}}],null,true)},[_c('span',[_vm._v("Duplicate session rate")])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }