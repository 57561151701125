import SessionCharge from "./SessionCharge";
import OtherEarningsCharge from "./OtherEarningsCharge";
import { formatDate } from "../utils/format-utils";

export default class RemittanceReport {
  remittanceReportId?: number | null;
  payrollBatchId?: number | null;
  lastRerunAt?: Date | null;
  startDate?: Date | string | null;
  endDate?: Date | string | null;
  providerId?: number | null;
  providerName?: string | null;
  legacyProviderId?: string | null;
  programs?: string | null;
  totalPayout?: number | null;
  isFlagged?: boolean | null;
  missingRates?: boolean | null;
  isManual?: boolean | null;
  isReversed?: boolean | null;
  reversalExplanation?: string | null;
  reversedAt?: Date | null;
  sessionCharges?: SessionCharge[];
  deferredSessionCharges?: SessionCharge[];
  otherEarnings?: OtherEarningsCharge[];
  closedAt?: Date | null;
  paycheckDate?: Date | null;
  fileId?: number | null;

  public constructor(params: RemittanceReport = {} as RemittanceReport) {
    let {
      remittanceReportId = 0,
      startDate = null,
      endDate = null,
      lastRerunAt = null,
      providerId = 0,
      providerName = "",
      legacyProviderId = "",
      missingRates = false,
      programs = "",
      totalPayout = 0,
      isFlagged = false,
      deferredSessionCharges = new Array<SessionCharge>(),
      sessionCharges = new Array<SessionCharge>(),
      otherEarnings = new Array<OtherEarningsCharge>(),
      isManual = false,
      isReversed = false,
      reversalExplanation = "",
      reversedAt = null,
      closedAt = null,
      paycheckDate = null,
      fileId = 0,
    } = params;

    this.remittanceReportId = remittanceReportId;
    this.providerId = providerId;
    this.providerName = providerName;
    this.legacyProviderId = legacyProviderId;
    this.startDate = formatDate(startDate);
    this.endDate = formatDate(endDate);
    this.lastRerunAt = lastRerunAt;
    this.programs = programs;
    this.totalPayout = totalPayout;
    this.missingRates = missingRates;
    this.isFlagged = isFlagged;
    this.sessionCharges =
      sessionCharges && sessionCharges.map((item) => new SessionCharge(item));
    this.deferredSessionCharges =
      deferredSessionCharges &&
      deferredSessionCharges.map((item) => new SessionCharge(item));
    this.otherEarnings =
      otherEarnings &&
      otherEarnings.map((item) => new OtherEarningsCharge(item));
    this.isManual = isManual;
    this.isReversed = isReversed;
    this.reversedAt = reversedAt;
    this.reversalExplanation = reversalExplanation;
    this.closedAt = closedAt;
    this.fileId = fileId;
    this.paycheckDate = paycheckDate;
  }
}
