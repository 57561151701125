<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="noEdit"
          text
          v-bind="attrs"
          v-on:click="openDialog"
          v-on="on"
        >
          Import Provider
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Import a Provider
        </v-card-title>
        <v-card-text class="mt-4">
          <v-row>
            <v-col cols="10">
              <v-text-field
                v-model="legacyProviderId"
                label="Legacy Provider Id"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-overlay :value="showLoader">
            <v-progress-circular indeterminate></v-progress-circular>
          </v-overlay>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="legacyProviderId == ''"
            @click="cancel"
          >
            Cancel
          </v-btn>
          <v-btn color="primary" text @click="importProvider">
            Import Provider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["noEdit"],
  data: function () {
    return {
      dialog: false,
      legacyProviderId: "",
      showLoader: false,
    };
  },
  methods: {
    openDialog() {
      this.legacyProviderId = "";
      this.dialog = true;
    },
    cancel() {
      this.legacyProviderId = "";
      this.dialog = false;
    },
    importProvider: async function () {
      this.$store.commit("uxModule/setShowLoader", true);
      this.showLoader = true;
      var success = await this.$store.dispatch(
        "providerModule/importSingleProvider",
        { legacyProviderId: this.legacyProviderId }
      );
      this.$store.commit("uxModule/setShowLoader", false);
      this.showLoader = false;
      this.dialog = false;
    },
  },
};
</script>
