import * as api from "../Services/api.js";
import RemittanceReport from "../../objs/RemittanceReport";
import * as hub from "../Hubs/payrollHub.js";
import SessionCharge from "../../objs/SessionCharge";
import PayrollBatch from "../../objs/PayrollBatch";

const NON_PAYABLE_CHARGE_FLAGS = [
  "Missing invoice",
  "Missing consent",
  "Missing remote consent",
  "On hold",
  "Not certified",
];

export const state = {
  batches: [],
  currentBatchId: 0,
  currentBatch: [],
  currentRemittanceReportId: 0,
  currentRemittanceReport: new RemittanceReport(),
  sessionCharges: [],
  providerFilter: "",
  programFilter: "",
  additionalFilters: "",
  scrollBy: 0,
  chargesMissingEvalInvoice: [],
};

export const mutations = {
  addReportToBatch(state, payload) {
    state.currentBatch.push(new RemittanceReport(payload));
    state.currentBatch.sort((a, b) =>
      a.providerName.localeCompare(b.providerName)
    );
  },
  setCurrentBatchFilter(state, payload) {
    state[payload.filterName] = payload.value;
  },
  setCurrentBatchId(state, payload) {
    state.currentBatchId = payload;
  },
  setCurrentRemittanceReportId(state, payload) {
    state.currentRemittanceReportId = payload;
  },
  setCurrentRemittanceReport(state, payload) {
    state.currentRemittanceReport = new RemittanceReport(payload);
    state.currentRemittanceReportId = payload.remittanceReportId;

    // replace report in current batch list
    state.currentBatch = state.currentBatch.map((item) => {
      if (item.remittanceReportId == payload.remittanceReportId) {
        return state.currentRemittanceReport;
      }
      return item;
    });
  },

  setBatches(state, payload) {
    state.batches = payload
      .map((item) => new PayrollBatch(item))
      .sort((a, b) => {
        var dateA = new Date(a.paycheckDate);
        var dateB = new Date(b.paycheckDate);
        return dateB - dateA;
      });
  },
  setCurrentBatchId(state, payload) {
    state.currentBatchId = payload;
  },
  setCurrentBatch(state, payload) {
    state.currentBatch = payload
      .map((item) => new RemittanceReport(item))
      .sort((a, b) => a.providerName.localeCompare(b.providerName));
  },
  updateScroll(state, payload) {
    state.scrollBy = payload;
  },
  clearScroll(state) {
    state.scrollBy = 0;
  },
  updateRemittanceReportInBatch(state, payload) {
    state.currentBatch = state.currentBatch
      .map((item) => {
        if (item.remittanceReportId == payload.remittanceReportId) {
          return new RemittanceReport(payload);
        }
        return item;
      })
      .sort((a, b) => a.providerName.localeCompare(b.providerName));
  },
  setChargesMissingEvalInvoice(state, payload) {
    state.chargesMissingEvalInvoice = payload.map(
      (item) => new SessionCharge(item)
    );
  },
};

export const getters = {
  getProviderOptions: (state) => {
    return state.currentBatch.map((item) => {
      return {
        text: item.providerName + " - " + item.legacyProviderId,
        value: item.providerId,
      };
    });
  },
  getProviderOptionsForAddingManualCheck: (state) => {
    var providersWithManualCheck = state.currentBatch
      .filter((t) => t.isManual)
      .map((t) => t.providerId);

    return state.currentBatch
      .filter((item) => !providersWithManualCheck.includes(item.providerId))
      .map((item) => {
        return {
          text: item.providerName + " - " + item.legacyProviderId,
          value: item.providerId,
        };
      });
  },
  getCurrentBatchName: (state) => {
    return state.batches.find(
      (item) => item.payrollBatchId == state.currentBatchId
    ).name;
  },
  getPayableCharges: (state) => {
    return state.currentRemittanceReport.sessionCharges.filter((item) => {
      return (
        !NON_PAYABLE_CHARGE_FLAGS.includes(item.flag) &&
        !item.missingRate &&
        !item.isAdjustment
      );
    });
  },
  getNonPayableCharges: (state) => {
    return state.currentRemittanceReport.sessionCharges.filter((item) => {
      return (
        (NON_PAYABLE_CHARGE_FLAGS.includes(item.flag) || item.missingRate) &&
        !item.isAdjustment
      );
    });
  },
  getAdjustments: (state) => {
    return state.currentRemittanceReport.sessionCharges.filter((item) => {
      return item.isAdjustment;
    });
  },
  isMultipleReportsForProviderInCurrentBatch: (state) => {
    let providerId = state.currentRemittanceReport.providerId;
    return (
      state.currentBatch.filter((report) => report.providerId === providerId)
        .length > 1
    );
  },
  getRemittanceReport: (state) => (remittanceReportId) => {
    return state.currentBatch.find(
      (report) => report.remittanceReportId === remittanceReportId
    );
  },
};

export const actions = {
  async getProvidersToAdd({ commit, state }) {
    var response = await api.get(
      "/payrollBatch/getProvidersToAdd?payrollBatchId=" +
        state.currentBatchId.toString()
    );
    if (response.success) {
      return response.data;
    }
  },

  async addProviderToBatch({ commit, state }, data) {
    var response = await api.postQuery("/payrollBatch/addProviderToBatch", {
      providerId: data,
      payrollBatchId: state.currentBatchId,
    });
    if (response.success) {
      commit("addReportToBatch", response.data);
    }
  },

  async createManualRemittanceReport({ commit }, data) {
    return await api
      .postQuery("/payrollBatch/createManualRemittanceReport", data)
      .then((response) => {
        if (response.success) {
          commit("addReportToBatch", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async loadBatches({ commit }) {
    var response = await api.get("/payrollBatch/getAllPayrollBatches");
    if (response.success) {
      commit("setBatches", response.data);
    }
  },

  async sendRemittanceNotificationEmails({ commit, state }, data) {
    return await api
      .postQuery("/paystub/sendRemittanceNotificationEmails", {
        payrollBatchId: data,
      })
      .then((response) => {
        if (response.success) {
          // list of emails that failed to send
          return response.data;
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },

  async generatePdfs({ commit, state }, data) {
    return await api
      .postQuery("/paystub/createAllStubs", {
        payrollBatchId: data,
      })
      .then((response) => {
        if (response.success) {
          // list of remittance ids in batch that failed to create or upload paystub
          return response.data;
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },

  async updateAutoAdd({ commit, state }, data) {
    var response = await api.postQuery(
      "/payrollBatch/setContinueAddingProviders",
      data
    );
  },
  async closeBatch({ commit, state }, data) {
    var response = await api.postQuery("/payrollBatch/closePayrollBatch", {
      payrollBatchId: data,
    });

    if (response.success) {
      if (response.code == 16) {
        commit("uxModule/setSnackbarMsg", response.message, {
          root: true,
        });
        commit("uxModule/setShowSnackbar", true, {
          root: true,
        });
      } else {
        //Replace the batch
        commit("uxModule/setSnackbarMsg", "Successfully closed batch", {
          root: true,
        });
        commit("uxModule/setShowSnackbar", true, {
          root: true,
        });
      }
    } else {
      commit("uxModule/setSnackbarMsg", "Error closing batch", {
        root: true,
      });
      commit("uxModule/setShowSnackbar", true, {
        root: true,
      });
    }
    return response.success;
  },
  async loadBatch({ commit, state }, data) {
    if (data === state.currentBatchId) {
      return;
    }
    state.currentBatchId = data;
    var response = await api.get(
      "/payrollBatch/loadPayrollBatch?payrollBatchId=" +
        state.currentBatchId.toString()
    );
    if (response.success) {
      commit("setCurrentBatch", response.data);
    }
  },

  async loadRemittanceReport({ commit, state }, data) {
    var response = await api.get(
      "/payrollBatch/loadRemittanceReport?remittanceReportId=" +
        state.currentRemittanceReportId.toString()
    );
    if (response.success) {
      commit("setCurrentRemittanceReport", response.data);
    }
  },

  async reprocessRemittanceReport({ commit, state }) {
    var response = await api.postQuery(
      "/payrollBatch/reprocessRemittanceReport",
      { remittanceReportId: state.currentRemittanceReportId }
    );
    if (response.success) {
      //Reset the current remittance report
      commit("setCurrentRemittanceReport", response.data);
    }
  },

  async reimportProviderActivities({ commit, state }, payload) {
    var response = await api.postQuery("/bOEImport/importForProvider", payload);
  },

  async rerunRemittanceReport({ commit, state }, payload) {
    var response = await api.postQuery("/payrollBatch/rerunRemittanceReport", {
      remittanceReportId: payload.remittanceReportId,
      start: payload.start,
      end: payload.end,
    });
    if (response.success) {
      //Reset the current remittance report
      commit("setCurrentRemittanceReport", response.data);
      state.currentRemittanceReportId =
        state.currentRemittanceReport.remittanceReportId;

      //Replace the remittance report in the list for current batch
      var rep = response.data;
      commit("updateRemittanceReportInBatch", rep);
      rep.startDate = new Date(rep.startDate);
      rep.endDate = new Date(rep.endDate);
      hub.SendUpdateReportAlert(rep);
    }
  },
  async revertRemittanceReport({ commit }, payload) {
    return await api
      .postQuery("/payrollBatch/revertRemittanceReport", {
        remittanceReportId: payload.remittanceReportId,
        reversalExplanation: payload.reversalExplanation,
      })
      .then((response) => {
        if (response.success) {
          let rep = response.data;
          commit("updateRemittanceReportInBatch", rep);
          rep.startDate = new Date(rep.startDate);
          rep.endDate = new Date(rep.endDate);
          hub.SendUpdateReportAlert(rep);
        }

        return response.success;
      })
      .catch((err) => {
        return false;
      });
  },
  async regeneratePdf({}, payload) {
    return await api
      .postQuery("/paystub/createStub", {
        remittanceReportId: payload.remittanceReportId,
      })
      .then((response) => {
        return response.success;
      })
      .catch((err) => {
        return false;
      });
  },
  async closeRemittanceReport({ commit }, payload) {
    return await api
      .postQuery("/payrollBatch/closeIndividualRemittanceReport", {
        remittanceReportId: payload.remittanceReportId,
      })
      .then((response) => {
        if (response.success) {
          let rep = response.data;
          commit("updateRemittanceReportInBatch", rep);
          rep.startDate = new Date(rep.startDate);
          rep.endDate = new Date(rep.endDate);
          hub.SendUpdateReportAlert(rep);
        }

        return response.success;
      })
      .catch((err) => {
        return false;
      });
  },
  async overrideSessionCharge({ commit, state }, data) {
    var response = await api.postQuery(
      "/payrollBatch/overrideSessionCharge",
      data
    );
    if (response.success) {
      //Reset the current remittance report
      commit("setCurrentRemittanceReport", response.data);
    }
  },

  async flagRemittanceReport({ commit, state }, data) {
    var response = await api.postQuery(
      "/payrollBatch/flagRemittanceReport",
      data
    );
    if (response.success) {
      var rep = response.data;
      commit("updateRemittanceReportInBatch", rep);
      rep.startDate = new Date(rep.startDate);
      rep.endDate = new Date(rep.endDate);
      hub.SendUpdateReportAlert(rep);
    }
  },

  async deferSessionCharge({ commit }, data) {
    var response = await api.postQuery("/payrollBatch/deferSessionCharge", {
      sessionChargeId: data.sessionChargeId,
    });
    if (response.success) {
      commit("setCurrentRemittanceReport", response.data);
    }

    let message = response.success
      ? "Charge successfully deferred."
      : "Error deferring this charge";
    commit("uxModule/setSnackbarMsg", message, {
      root: true,
    });
    commit("uxModule/setShowSnackbar", true, {
      root: true,
    });
  },
  async deferSessionCharges({ state, commit }, data) {
    let remittanceReportId = state.currentRemittanceReportId;
    return await api
      .post("/payrollBatch/deferSessionCharges", {
        remittanceReportId: remittanceReportId,
        sessionChargeIds: data.sessionChargeIds,
      })
      .then((response) => {
        if (response.success) {
          commit("setCurrentRemittanceReport", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },

  async deferOtherEarningsCharge({ commit }, data) {
    var response = await api.postQuery(
      "/payrollBatch/deferOtherEarningsCharge",
      { otherEarningsChargeId: data.otherEarningsChargeId }
    );
    if (response.success) {
      commit("setCurrentRemittanceReport", response.data);
    }

    let message = response.success
      ? "Charge successfully deferred."
      : "Error deferring this charge";
    commit("uxModule/setSnackbarMsg", message, {
      root: true,
    });
    commit("uxModule/setShowSnackbar", true, {
      root: true,
    });
  },

  async undeferSessionCharge({ commit }, data) {
    var response = await api.postQuery("/payrollBatch/undeferSessionCharge", {
      sessionChargeId: data.sessionChargeId,
    });
    if (response.success) {
      commit("setCurrentRemittanceReport", response.data);
    }
    let message = response.success
      ? "Charge successfully undeferred."
      : "Error undeferring this charge";
    commit("uxModule/setSnackbarMsg", message, {
      root: true,
    });
    commit("uxModule/setShowSnackbar", true, {
      root: true,
    });
  },

  async undeferOtherEarningsCharge({ commit }, data) {
    var response = await api.postQuery(
      "/payrollBatch/undeferOtherEarningsCharge",
      { otherEarningsChargeId: data.otherEarningsChargeId }
    );
    if (response.success) {
      commit("setCurrentRemittanceReport", response.data);
    }

    let message = response.success
      ? "Charge successfully undeferred."
      : "Error undeferring this charge";
    commit("uxModule/setSnackbarMsg", message, {
      root: true,
    });
    commit("uxModule/setShowSnackbar", true, {
      root: true,
    });
  },

  async generateBatch({}, data) {
    return await api
      .post("/payrollBatch/generatePayrollBatch", data)
      .then((response) => {
        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },

  async moveSessionChargesToOtherReport({ state, commit, dispatch }, data) {
    let remittanceReportId = state.currentRemittanceReportId;
    return await api
      .post("/payrollBatch/moveSessionChargesToManualReport", {
        remittanceReportId: remittanceReportId,
        sessionChargeIds: data.sessionChargeIds,
      })
      .then((response) => {
        if (response.success) {
          // update other report in batch
          let rep = response.data;
          commit("updateRemittanceReportInBatch", rep);
          rep.startDate = new Date(rep.startDate);
          rep.endDate = new Date(rep.endDate);
          hub.SendUpdateReportAlert(rep);

          // reload original report
          dispatch("loadRemittanceReport");
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async moveOtherEarningsChargesToOtherReport(
    { state, commit, dispatch },
    data
  ) {
    let remittanceReportId = state.currentRemittanceReportId;
    return await api
      .post("/payrollBatch/moveOtherEarningsChargesToManualReport", {
        remittanceReportId: remittanceReportId,
        otherEarningsChargeIds: data.otherEarningsChargeIds,
      })
      .then((response) => {
        if (response.success) {
          // update other report in batch
          let rep = response.data;
          commit("updateRemittanceReportInBatch", rep);
          rep.startDate = new Date(rep.startDate);
          rep.endDate = new Date(rep.endDate);
          hub.SendUpdateReportAlert(rep);

          // reload original report
          dispatch("loadRemittanceReport");
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async loadChargesMissingEvalInvoice({ commit, state }) {
    return await api
      .getQuery("/payrollBatch/loadSessionChargesMissingEvalInvoice", {
        payrollBatchId: state.currentBatchId,
      })
      .then((response) => {
        if (response.success) {
          commit("setChargesMissingEvalInvoice", response.data);
        }
        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async setEvalSessionInvoice({ commit }, data) {
    var response = await api.postQuery(
      "/payrollBatch/setEvalSessionInvoice",
      data
    );
    if (response.success) {
      commit("setCurrentRemittanceReport", response.data);
    }
  },
  async setRemoteAttendanceLog({ commit }, data) {
    var response = await api.postQuery(
      "/payrollBatch/setRemoteAttendanceLog",
      data
    );
    if (response.success) {
      var rep = response.data;
      commit("updateRemittanceReportInBatch", rep);
      rep.startDate = new Date(rep.startDate);
      rep.endDate = new Date(rep.endDate);
      hub.SendUpdateReportAlert(rep);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
