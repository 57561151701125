<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip bottom v-bind="attrs" v-on="on">
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn
              text
              x-small
              :disabled="isClosed"
              v-on="on"
              @click="showDialog"
            >
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </div>
        </template>
        <span> {{ tooltipTitle }} </span>
      </v-tooltip>
    </template>

    <v-card>
      <v-card-title> Are you sure you want to close this report? </v-card-title>

      <v-card-text> Note: This action cannot be undone. </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark text @click="cancel">Cancel</v-btn>
        <v-btn color="primary" dark outlined @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { formatDateAndTime } from "../../utils/format-utils";
export default {
  props: ["remittanceReportId"],
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    remittanceReport: function () {
      return this.$store.getters["payrollBatchModule/getRemittanceReport"](
        this.remittanceReportId
      );
    },
    isClosed: function () {
      return Boolean(this.remittanceReport?.closedAt);
    },
    tooltipTitle: function () {
      return this.isClosed
        ? "Report was closed " +
            formatDateAndTime(this.remittanceReport?.closedAt)
        : "Close Report";
    },
  },
  methods: {
    showDialog() {
      this.dialog = true;
    },
    cancel() {
      this.dialog = false;
    },
    close: async function () {
      await this.$store.dispatch("payrollBatchModule/closeRemittanceReport", {
        remittanceReportId: this.remittanceReportId,
      });
      this.dialog = false;
    },
  },
};
</script>
