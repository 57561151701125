<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-text-field
          color="primary"
          v-model="searchText"
          rounded
          dense
          height="20px"
          prepend-inner-icon="mdi-magnify"
          outlined
          label="Search for a provider, company, or ADP account"
          clearable
        ></v-text-field>
      </v-col>

      <v-col cols="4"> </v-col>

      <v-col cols="2">
        <account-form
          :isNew="true"
          :isEdit="false"
          :noEdit="noEdit"
        ></account-form>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      fixed-header
      :items="filteredAccounts"
      item-key="adpAccountId"
      class="mt-8"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-row>
          <account-form
            :isNew="false"
            :isEdit="true"
            :acct="item"
            :noEdit="noEdit"
          ></account-form>
          <delete-acct :acct="item" :noEdit="noEdit"></delete-acct>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import AdpAccountForm from "./adp-account-form";
import DeleteAdpAcct from "./delete-acct-btn";

export default {
  components: {
    "account-form": AdpAccountForm,
    "delete-acct": DeleteAdpAcct,
  },
  mounted: async function () {
    await this.$store.dispatch("adpModule/getAllAdpAccounts");
  },
  data: function () {
    return {
      searchText: "",
      headers: [
        {
          text: "Provider",
          value: "provider",
          sortable: true,
        },

        {
          text: "Provider ID",
          value: "legacyProviderId",
          sortable: true,
        },

        {
          text: "Company",
          value: "omniCorporationDivision",
          sortable: true,
        },

        {
          text: "Company Code",
          value: "omniCorporationDivisionCode",
          sortable: true,
        },

        {
          text: "ADP Number",
          value: "adpFileNo",
          sortable: true,
        },

        {
          text: "Actions",
          value: "actions",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    noEdit() {
      return this.$store.getters["securityModule/isReadOnly"];
    },
    accounts() {
      return this.$store.state.adpModule.adpAccounts;
    },
    regex() {
      return new RegExp(this.searchText, "i");
    },
    filteredAccounts() {
      var list = this.accounts;
      if (this.searchText != null && this.searchText != "") {
        list = this.accounts.filter(
          (t) =>
            this.regex.test(t.provider) == true ||
            this.regex.test(t.legacyProviderId) == true ||
            this.regex.test(t.adpFileNo) == true ||
            this.regex.test(t.omniCorporationDivisionCode) == true ||
            this.regex.test(t.omniCorporationDivision) == true
        );
      }
      return list;
    },
  },
  methods: {},
};
</script>
