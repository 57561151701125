var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex pb-10 mb-5"},[_c('v-btn',{attrs:{"text":"","plain":"","color":"primary","link":"","to":{ name: 'Other Earnings Charges' }}},[_vm._v(" Other Earnings ")]),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-menu-right ")]),_c('v-btn',{attrs:{"text":"","plain":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$route.name)+" ")])],1),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":_vm.validations.providerId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.providerOptions,"label":"Select provider","clearable":"","error-messages":errors,"readonly":_vm.noEdit},model:{value:(_vm.form.providerId),callback:function ($$v) {_vm.$set(_vm.form, "providerId", $$v)},expression:"form.providerId"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"rules":_vm.validations.omniCorporationDivisionId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.omniCorpOptions,"item-text":"description","item-value":"omniCorporationDivisionId","label":"Omni Corporation","error-messages":errors,"readonly":_vm.noEdit},model:{value:(_vm.form.omniCorporationDivisionId),callback:function ($$v) {_vm.$set(_vm.form, "omniCorporationDivisionId", $$v)},expression:"form.omniCorporationDivisionId"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"rules":_vm.validations.programId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.programOptions,"label":"Program","item-text":function (item) { return ((item.code) + " - " + (item.name)); },"item-value":"programId","error-messages":errors,"readonly":_vm.noEdit},model:{value:(_vm.form.programId),callback:function ($$v) {_vm.$set(_vm.form, "programId", $$v)},expression:"form.programId"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('date-picker',{attrs:{"value":_vm.form.chargeDate,"field":{ label: 'Charge Date' },"readonly":_vm.noEdit},on:{"fieldChange":_vm.changedDate}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"rules":_vm.validations.earningsCodeId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.earningsCodesOptions,"item-text":"name","item-value":"earningsCodeId","label":"Charge Type","error-messages":errors,"readonly":_vm.noEdit},model:{value:(_vm.form.earningsCodeId),callback:function ($$v) {_vm.$set(_vm.form, "earningsCodeId", $$v)},expression:"form.earningsCodeId"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-radio-group',{attrs:{"row":"","label":"Payment type","readonly":_vm.noEdit},on:{"change":_vm.updateCalculatedAmount},model:{value:(_vm.form.isHourlyAmount),callback:function ($$v) {_vm.$set(_vm.form, "isHourlyAmount", $$v)},expression:"form.isHourlyAmount"}},[_c('v-radio',{attrs:{"label":"Hourly","value":true}}),_c('v-radio',{attrs:{"label":"Flat fee","value":false}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"rules":_vm.validations.duration},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","min":"0","label":"Duration (hours)","value":_vm.form.durationMinutes / 60,"error-messages":errors,"readonly":_vm.noEdit},on:{"input":_vm.changedDuration}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"rules":_vm.validations.amount},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.amountLabel,"prefix":"$","type":"number","min":"0","step":"00.1","error-messages":errors,"readonly":_vm.noEdit},on:{"input":_vm.updateCalculatedAmount},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":"Total Amount","prefix":"$","readonly":"","disabled":"","type":"number"},model:{value:(_vm.form.calculatedAmount),callback:function ($$v) {_vm.$set(_vm.form, "calculatedAmount", $$v)},expression:"form.calculatedAmount"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Note","readonly":_vm.noEdit},model:{value:(_vm.form.note),callback:function ($$v) {_vm.$set(_vm.form, "note", $$v)},expression:"form.note"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}}),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.cancel}},[_vm._v("Cancel")])],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"color":"primary","disabled":invalid || _vm.noEdit,"text":""},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }