import store from "../index";
import { connection } from "./hubConnection";

//incoming message handlers
connection.on("UpdateRemittanceReport", (data) =>
{

      store.commit("payrollBatchModule/updateRemittanceReportInBatch", data);
});

//outgoing messages
export async function SendUpdateReportAlert(payload)
{
  return await connection
    .invoke("UpdateReport", payload)
    .catch(err =>
    {
      console.error(err)
    });
}
