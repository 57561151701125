<template>
  <v-card class="mx-auto">
    <validation-observer v-slot="{ invalid }">
      <v-card-text class="text-body-1">
        This action will carry over to the next year all available balances* as
        of:
        <date-picker
          :value="date"
          :field="{ label: 'Choose date' }"
          :validations="{ required: true }"
          @fieldChange="onDateChange"
        ></date-picker>
      </v-card-text>
      <v-card-subtitle> * max {{ maxHours }} hours</v-card-subtitle>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text :disabled="loading" @click="date = ''">
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="invalid"
          @click="move"
          :loading="loading"
        >
          Move To Carryover
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>
<script>
export default {
  props: [],
  data() {
    return {
      loading: false,
      date: "",
    };
  },
  computed: {
    maxHours() {
      return this.$store.state.paidSickLeaveModule.maxHoursAllowedPerSchoolYear;
    },
  },
  methods: {
    onDateChange(event) {
      this.date = event;
    },
    async move() {
      this.loading = true;
      let success = await this.$store.dispatch(
        "paidSickLeaveModule/moveBalancesToCarryover",
        {
          date: this.date,
        }
      );
      this.loading = false;
      let message = success
        ? "Moved all balances to carryover successfully."
        : "Error moving 1 or more balances to carryover.";
      this.$store.commit("uxModule/setSnackbarMsg", message);
      this.$store.commit("uxModule/setShowSnackbar", true);
    },
  },
};
</script>