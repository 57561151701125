<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="isEdit"
          color="primary"
          icon
          title="Edit"
          v-bind="attrs"
          v-on:click="load"
          v-on="on"
          :disabled="noEdit"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          v-else
          color="primary"
          text
          v-on:click="load"
          v-bind="attrs"
          v-on="on"
          :disabled="noEdit"
        >
          Add Account
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          ADP Account Details
        </v-card-title>
        <v-card-text>
          <v-row> </v-row>
          <v-row class="mt-10">
            <v-col cols="10">
              <v-select
                clearable
                :items="corporationOptions"
                item-text="name"
                item-value="omniCorporationDivisionId"
                label="Omni Corporation Division"
                v-model="omniCorporationDivisionId"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="10">
              <v-autocomplete
                v-model="providerId"
                :items="providerOptions"
                clearable
                label="Provider"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="10">
              <v-text-field
                v-model="adpFileNo"
                clearable
                label="ADP File Number"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Cancel </v-btn>
          <v-btn color="primary" :disabled="!valid" text @click="save">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["isNew", "isEdit", "acct", "noEdit"],
  data: function () {
    return {
      providerId: "",
      omniCorporationDivisionId: "",
      adpFileNo: "",
      dialog: false,
    };
  },
  computed: {
    providerOptions: function () {
      return this.$store.state.optionModule.providers;
    },
    corporationOptions() {
      return this.$store.state.optionModule.omniCorporationDivisions;
    },
    valid() {
      return (
        this.providerId != "" &&
        this.omniCorporationDivisionId != "" &&
        this.adpFileNo != "" &&
        this.providerId != null &&
        this.omniCorporationDivisionId != null &&
        this.adpFileNo != null
      );
    },
  },
  methods: {
    load() {
      if (this.isEdit) {
        this.providerId = this.acct.providerId;
        this.omniCorporationDivisionId = this.acct.omniCorporationDivisionId;
        this.adpFileNo = this.acct.adpFileNo;
      } else {
        this.providerId = "";
        this.omniCorporationDivisionId = "";
        this.adpFileNo = "";
      }
      this.show = true;
    },
    async save() {
      if (this.isNew) {
        var message = await this.$store.dispatch("adpModule/createAdpAccount", {
          providerId: this.providerId,
          omniCorporationDivisionId: this.omniCorporationDivisionId,
          adpFileNo: this.adpFileNo,
        });
        if (message) {
          this.$store.commit("uxModule/setShowSnackbarMsg", {
            msg: message,
            show: true,
          });
        } else {
          this.$store.commit("uxModule/setShowSnackbarMsg", {
            msg: "Account created!",
            show: true,
          });
        }
      } else if (this.isEdit) {
        var success = await this.$store.dispatch("adpModule/updateAdpAccount", {
          adpAccountId: this.acct.adpAccountId,
          providerId: this.providerId,
          omniCorporationDivisionId: this.omniCorporationDivisionId,
          adpFileNo: this.adpFileNo,
        });
        if (success) {
          this.$store.commit("uxModule/setShowSnackbarMsg", {
            msg: "Account updated!",
            show: true,
          });
        } else {
          this.$store.commit("uxModule/setShowSnackbarMsg", {
            msg: "Error updating this account!",
            show: true,
          });
        }
      }
      this.dialog = false;
    },
  },
};
</script>
