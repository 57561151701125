import Callback from "../components/Security/callback";
import Home from "../components/Shared/home";
import Error from "../components/Security/error";

//Providers
import HomeProvider from "../pages/provider/home-provider.vue";
import Provider from "../pages/provider/provider.vue";
import AllProviders from "../pages/provider/all-providers.vue";
import SessionRate from "../pages/provider/session-rate.vue";
import AllSessionRates from "../pages/provider/all-session-rates.vue";
import AllCorporations from "../pages/provider/all-corporations.vue";
import AdpAccounts from "../pages/adp/adp-accounts";
import ProviderRemittances from "../pages/reports/provider-remittances.vue";

//Payroll batches
import ProcessPayroll from "../pages/process/process-payroll.vue";
import NewBatch from "../pages/process/new-batch.vue";
import AllBatches from "../pages/process/all-batches.vue";
import CurrentBatch from "../pages/process/current-batch.vue";
import ReviewBatch from "../pages/process/review-batch.vue";
import MissingEvalInvoice from "../pages/process/missing-eval-invoice.vue";
import RemittanceReport from "../pages/process/remittance-report.vue";

//Sessions
import HomeSessions from "../pages/sessions/home-sessions.vue";
import SessionDetails from "../pages/sessions/session-details.vue";

//Other Earnings Charges
import MedicalDeductionsCharges from "../pages/other-earnings/medical-deductions-charges-table.vue";
import HomeOtherEarning from "../pages/other-earnings/home-other-earning";
import NewDeduction from "../pages/other-earnings/new-deduction.vue";
import AllDeductions from "../pages/other-earnings/all-deductions.vue";
import AllOtherEarnings from "../pages/other-earnings/all-other-earnings-charges.vue";
import OtherEarningsChargeForm from "../pages/other-earnings/other-earnings-charge-form.vue";

//Paid Sick Leave
import PaidSickLeave from "../pages/paid-sick-leave/paid-sick-leave-home";
import OpenRequests from "../pages/paid-sick-leave/open-requests";
import SearchByProvider from "../pages/paid-sick-leave/search-by-provider";
import ProviderRequests from "../pages/paid-sick-leave/provider-requests";
import RequestForm from "../pages/paid-sick-leave/request-form";
import CarryoverSettings from "../pages/paid-sick-leave/carryover-settings";

//Reports
import ReportsHome from "../pages/reports/home-reports";
import PayrollBatchReports from "../pages/reports/payroll-batch-reports";
import ExpenseReports from "../pages/reports/expenses-reports";
import ProviderReports from "../pages/reports/provider-reports";

export const routes = [
  {
    name: "Home",
    path: "/",
    icon: "home",
    title: "Home",
    component: Home,
    onNavBar: false,
    meta: {
      requiresAuth: false,
    },
  },

  {
    name: "sessions",
    path: "/sessions",
    icon: "home",
    title: "Sessions",
    component: HomeSessions,
    onNavBar: true,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: "session-lookup",
        path: "session-lookup",
        icon: "home",
        title: "Session Lookup",
        component: SessionDetails,
        onNavBar: true,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },

  {
    name: "Provider Rates",
    path: "/provider-rates",
    icon: "home",
    title: "Provider Rates",
    component: HomeProvider,
    onNavBar: true,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: "New Provider",
        path: "new-provider",
        icon: "home",
        title: "New Provider",
        component: Provider,
        onNavBar: true,
        props: true,
        meta: {
          requiresAuth: true,
          admin: true,
        },
      },
      {
        name: "All Providers",
        path: "all-providers",
        icon: "home",
        title: "All Providers",
        component: AllProviders,
        onNavBar: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "All Session Rates",
        path: "all-session-rates",
        icon: "home",
        title: "All Session Rates",
        component: AllSessionRates,
        onNavBar: false,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "New Session Rate",
        path: "new-session-rate",
        icon: "home",
        title: "New Session Rate",
        component: SessionRate,
        onNavBar: false,
        props: true,
        meta: {
          requiresAuth: true,
          admin: true,
        },
      },
      {
        name: "Provider Corporations",
        path: "all-corporations",
        icon: "home",
        title: "Corporations and Agencies",
        component: AllCorporations,
        onNavBar: true,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "AdpAccounts",
        path: "/adp-accounts",
        icon: "home",
        title: "ADP Accounts",
        component: AdpAccounts,
        onNavBar: true,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    name: "Process Payroll",
    path: "/process-payroll",
    icon: "home",
    title: "Process Payroll",
    component: ProcessPayroll,
    onNavBar: true,
    meta: {
      requiresAuth: true,
      admin: true,
    },
    children: [
      {
        name: "New batch",
        path: "new-batch",
        icon: "home",
        title: "New batch",
        component: NewBatch,
        onNavBar: true,
        meta: {
          requiresAuth: true,
          admin: true,
        },
      },
      {
        name: "Current batch",
        path: "current-batch",
        icon: "home",
        title: "Current batch",
        component: CurrentBatch,
        onNavBar: false,
        meta: {
          requiresAuth: true,
          admin: true,
        },
      },

      {
        name: "ReviewBatch",
        path: "review-batch",
        icon: "home",
        title: "Review batch",
        component: ReviewBatch,
        onNavBar: false,
        meta: {
          requiresAuth: true,
          admin: true,
        },
      },
      {
        name: "All batches",
        path: "all-batches",
        icon: "home",
        title: "All batches",
        component: AllBatches,
        onNavBar: true,
        meta: {
          requiresAuth: true,
          admin: true,
        },
      },
      {
        name: "Remittance report",
        path: "remittance-report",
        icon: "home",
        title: "Review Provider",
        component: RemittanceReport,
        onNavBar: false,
        meta: {
          requiresAuth: true,
          admin: true,
        },
      },
      {
        name: "Missing Invoices",
        path: "missing-invoices",
        icon: "home",
        title: "Missing Invoices",
        component: MissingEvalInvoice,
        onNavBar: false,
        meta: {
          requiresAuth: true,
          admin: true,
        },
      },
    ],
  },
  {
    name: "Other Earnings",
    path: "/other-earnings",
    icon: "home",
    title: "Other Earnings",
    component: HomeOtherEarning,
    onNavBar: true,
    meta: {
      requiresAuth: true,
      admin: true,
    },
    children: [
      {
        name: "New Scheduled Deduction",
        path: "new-deduction",
        icon: "home",
        title: "New Scheduled Deduction",
        component: NewDeduction,
        onNavBar: true,
        props: true,
        meta: {
          requiresAuth: true,
          admin: true,
        },
      },
      {
        name: "Edit Scheduled Deduction",
        path: "edit-deduction",
        icon: "home",
        title: "Edit Scheduled Deduction",
        component: NewDeduction,
        onNavBar: false,
        props: true,
        meta: {
          requiresAuth: true,
          admin: true,
        },
      },
      {
        name: "Scheduled Deductions",
        path: "all-deductions",
        icon: "home",
        title: "Scheduled Deductions",
        component: AllDeductions,
        onNavBar: true,
        meta: {
          requiresAuth: true,
          admin: true,
        },
      },
      {
        name: "New Other Earnings Charge",
        path: "new-charge",
        icon: "home",
        title: "New Other Earning Charge",
        component: OtherEarningsChargeForm,
        onNavBar: true,
        props: true,
        meta: {
          requiresAuth: true,
          admin: true,
        },
      },
      {
        name: "Edit Other Earnings Charge",
        path: "edit-charge",
        icon: "home",
        title: "Edit Other Earning Charge",
        component: OtherEarningsChargeForm,
        onNavBar: false,
        props: true,
        meta: {
          requiresAuth: true,
          admin: true,
        },
      },
      {
        name: "View Other Earnings Charge",
        path: "view-charge",
        icon: "home",
        title: "View Other Earning Charge",
        component: OtherEarningsChargeForm,
        onNavBar: false,
        props: true,
        meta: {
          requiresAuth: true,
          admin: true,
        },
      },
      {
        name: "Other Earnings Charges",
        path: "all-charges",
        icon: "home",
        title: "Other Earnings Charges",
        component: AllOtherEarnings,
        onNavBar: true,
        meta: {
          requiresAuth: true,
          admin: true,
        },
      },
      {
        name: "Medical Deduction Charges",
        path: "medical-deductions",
        icon: "home",
        title: "Medical Deductions Charges",
        component: MedicalDeductionsCharges,
        onNavBar: true,
        meta: {
          requiresAuth: true,
          admin: true,
        },
      },
    ],
  },
  {
    name: "Reports",
    path: "/reports",
    icon: "home",
    title: "Reports",
    component: ReportsHome,
    onNavBar: true,
    meta: {
      requiresAuth: true,
      admin: true,
    },
    children: [
      {
        name: "Payroll Batch Reports",
        path: "/payroll-batch-reports",
        props: true,
        icon: "home",
        title: "Payroll Batch Reports",
        component: PayrollBatchReports,
        onNavBar: true,
        meta: {
          requiresAuth: true,
          admin: true,
        },
      },

      {
        name: "ExpensesReports",
        path: "/expenses-reports",
        props: true,
        icon: "home",
        title: "Expenses Reports",
        component: ExpenseReports,
        onNavBar: true,
        meta: {
          requiresAuth: true,
          admin: true,
        },
      },
      {
        name: "Provider Reports",
        path: "/provider-reports",
        icon: "home",
        title: "Provider Reports",
        component: ProviderReports,
        onNavBar: true,
        props: true,
        meta: {
          requiresAuth: true,
          admin: true,
        },
      },
      {
        name: "Provider Remittances",
        path: "/remittances",
        icon: "home",
        title: "Provider Remittances",
        component: ProviderRemittances,
        onNavBar: true,
        props: true,
        meta: {
          requiresAuth: true,
          admin: true,
        },
      },
    ],
  },
  {
    name: "Paid Sick Leave",
    path: "/paid-sick-leave",
    icon: "home",
    title: "Paid Sick Leave",
    component: PaidSickLeave,
    onNavBar: true,
    meta: {
      requiresAuth: true,
      admin: true,
    },
    children: [
      {
        name: "Search By Provider",
        path: "provider",
        icon: "home",
        title: "Search By Provider",
        component: SearchByProvider,
        onNavBar: true,
        props: true,
        meta: {
          requiresAuth: true,
          admin: true,
        },
        children: [
          {
            name: "Provider Requests",
            path: "/paid-sick-leave/provider/all-requests",
            icon: "home",
            title: "Provider Requests",
            component: ProviderRequests,
            meta: {
              requiresAuth: true,
              admin: true,
            },
          },
          {
            name: "New Request",
            path: "/paid-sick-leave/provider/new-request",
            icon: "home",
            title: "New Request",
            props: true,
            component: RequestForm,
            meta: {
              requiresAuth: true,
              admin: true,
            },
          },
          {
            name: "Edit Request",
            path: "/paid-sick-leave/provider/edit-request",
            icon: "home",
            title: "Edit Request",
            component: RequestForm,
            props: true,
            meta: {
              requiresAuth: true,
              admin: true,
            },
          },
          {
            name: "View Request",
            path: "/paid-sick-leave/provider/view-request",
            icon: "home",
            title: "View Request",
            component: RequestForm,
            props: true,
            meta: {
              requiresAuth: true,
              admin: true,
            },
          },
        ],
      },
      {
        name: "Open Requests",
        path: "open-requests",
        icon: "home",
        title: "View Open Requests",
        component: OpenRequests,
        onNavBar: true,
        meta: {
          requiresAuth: true,
          admin: true,
        },
      },
      {
        name: "Carryover Settings",
        path: "carryover-settings",
        icon: "home",
        title: "Carryover Settings",
        component: CarryoverSettings,
        onNavBar: true,
        meta: {
          requiresAuth: true,
          admin: true,
        },
      },
    ],
  },
  {
    name: "Error",
    path: "/error",
    title: "Error",
    component: Error,
    onNavBar: false,
  },
  {
    name: "Callback",
    path: "/callback",
    title: "Callback",
    component: Callback,
    onNavBar: false,
  },
];
