import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

Vue.config.devtools = true;

import uxModule from "./Modules/uxModule";

import sharedModule from "./Modules/sharedModule";
import securityModule from "./Modules/securityModule";
import providerModule from "./Modules/providerModule";
import otherEarningsModule from "./Modules/otherEarningsModule";
import optionModule from "./Modules/optionModule";
import payrollBatchModule from "./Modules/payrollBatchModule";
import paidSickLeaveModule from "./Modules/paidSickLeaveModule";
import sessionModule from "./Modules/sessionModule";
import otherEarningsChargesModule from "./Modules/otherEarningsChargesModule"
import adpModule from "./Modules/adpModule";
import reportsModule from "./Modules/reportsModule";
import filesModule from "./Modules/filesModule";


const state = {};

const mutations = {};

const actions = {};

const store = new Vuex.Store({
  modules: {
    uxModule: uxModule,
    sharedModule: sharedModule,
    securityModule: securityModule,
    payrollBatchModule: payrollBatchModule,
    providerModule: providerModule,
    otherEarningsModule: otherEarningsModule,
    optionModule: optionModule,
    payrollBatchModule: payrollBatchModule,
    paidSickLeaveModule: paidSickLeaveModule,
    sessionModule: sessionModule,
    adpModule: adpModule,
    otherEarningsChargesModule: otherEarningsChargesModule,
    reportsModule: reportsModule,
    filesModule: filesModule
  },

  state,
  mutations,
  actions,
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
});

export default store;
