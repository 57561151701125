<template>
  <div>
    <v-row>
      <v-radio-group v-model="groupBy" row label="Group charges by">
        <v-radio label="Client" value="clientFullName"></v-radio>
        <v-radio label="Service" value="service"></v-radio>
        <v-radio label="Program" value="program"></v-radio>
      </v-radio-group>
    </v-row>
    <v-row>
      <session-charges-actions-menu
        :selectedCharges="selectedItems"
        :deferred="deferred"
      ></session-charges-actions-menu>
    </v-row>

    <v-data-table
      dense
      :headers="headers"
      fixed-header
      height="65vh"
      :items="sessionCharges"
      item-key="sessionChargeId"
      class="mt-8"
      :group-by="groupBy"
      hide-default-footer
      disable-pagination
      v-model="selectedItems"
      show-select
    >
      <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen }">
        <td :colspan="headers.length">
          <v-btn @click="toggle" x-small icon :ref="group">
            <v-icon v-if="isOpen">mdi-minus</v-icon>
            <v-icon v-else>mdi-plus</v-icon>
          </v-btn>
          <span
            class="mx-5 font-weight-bold"
            style="font-size: 18px; font-weight: bold"
            >{{ group }}</span
          >
          <span
            v-if="groupBy == 'clientFullName'"
            class="mx-5 font-weight-bold"
            style="font-size: 18px; font-weight: bold"
            >Total: {{ totalForGroup(group) }}</span
          >
        </td>
      </template>

      <template v-slot:[`item.clientFullName`]="{ item }">
        <span style="font-size: 12px; font-weight: bold">{{
          item.clientFullName
        }}</span>
      </template>

      <template v-slot:[`item.sessionId`]="{ item }">
        <span style="font-size: 12px; font-weight: bold">{{
          item.sessionId
        }}</span>
      </template>

      <template v-slot:[`item.durationMinutes`]="{ item }">
        <span style="font-size: 12px; font-weight: bold"
          >{{ item.durationMinutes }} min</span
        >
      </template>

      <template v-slot:[`item.groupSize`]="{ item }">
        <span style="font-size: 12px; font-weight: bold">{{
          item.groupSize
        }}</span>
      </template>

      <template v-slot:[`item.rateSummary`]="{ item }">
        <v-tooltip bottom max-width="250px">
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              style="font-size: 12px; font-weight: bold"
            >
              {{ item.rateSummary.substr(0, item.rateSummary.indexOf(",")) }}
            </span>
          </template>
          <span style="font-size: 16px">{{ item.rateSummary }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.sessionStatus`]="{ item }">
        <span style="font-size: 12px; font-weight: bold">{{
          item.sessionStatus
        }}</span>
      </template>

      <template v-slot:[`item.flag`]="{ item }">
        <span style="font-size: 12px; font-weight: bold">{{ item.flag }}</span>
      </template>

      <template v-slot:[`item.location`]="{ item }">
        <span style="font-size: 12px; font-weight: bold">{{
          item.location
        }}</span>
      </template>

      <template v-slot:[`item.language`]="{ item }">
        <span style="font-size: 12px; font-weight: bold">{{
          item.language
        }}</span>
      </template>

      <template v-slot:[`item.calculatedAmount`]="{ item }">
        <span style="font-size: 12px; font-weight: bold">
          {{ formatCurrency(item.calculatedAmount) }}
        </span>
      </template>

      <template v-slot:[`item.dateOfService`]="{ item }">
        <span style="font-size: 12px; font-weight: bold">{{
          item.dateOfService
        }}</span>
      </template>

      <template v-slot:[`item.startTime`]="{ item }">
        <span style="font-size: 12px; font-weight: bold">{{
          item.startTime
        }}</span>
      </template>

      <template v-slot:[`item.program`]="{ item }">
        <span style="font-size: 12px; font-weight: bold">{{
          item.program
        }}</span>
      </template>

      <template v-slot:[`item.service`]="{ item }">
        <span style="font-size: 12px; font-weight: bold">{{
          item.service
        }}</span>
      </template>
      <template v-slot:[`item.adjustedAmount`]="{ item }">
        <v-tooltip v-if="Boolean(item.adjustmentExplanation)" top>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-on="on"
              v-bind="attrs"
              style="font-size: 12px; font-weight: bold; cursor: pointer"
            >
              {{ formatCurrency(item.adjustedAmount) }}
            </span>
          </template>
          <span>{{ item.adjustmentExplanation }}</span>
        </v-tooltip>
        <span v-else style="font-size: 12px; font-weight: bold">
          {{ formatCurrency(item.adjustedAmount) }}
        </span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-row no-gutters dense>
          <eval-invoice-checkbox
            v-if="item.isEvalInvoiceRequired"
            :item="item"
          ></eval-invoice-checkbox>
          <v-btn
            class="mt-2"
            x-small
            title="Create rate"
            @click="createRate(item)"
            icon
            ><v-icon x-small color="primary">mdi-currency-usd</v-icon></v-btn
          >

          <adjustment-eraser
            v-if="item.isAdjustment"
            :sessionChargeId="item.sessionChargeId"
            :calculatedAmount="item.calculatedAmount"
            :adjustedAmount="item.adjustedAmount"
          ></adjustment-eraser>
          <!--<v-btn class="mt-2"
     x-small
     title="Create rate"
     @click="editRate(item)"
     icon><v-icon x-small color="primary">mdi-pen</v-icon></v-btn>-->

          <override-modal
            :sessionChargeId="item.sessionChargeId"
            mode="OverrideSessionCharge"
          ></override-modal>

          <v-btn
            title="Defer charge"
            class="mt-2"
            x-small
            icon
            @click="defer(item)"
            v-if="!deferred"
            ><v-icon x-small color="primary">mdi-timer-sand</v-icon></v-btn
          >
          <v-btn
            title="Undefer charge"
            class="mt-2"
            x-small
            icon
            @click="undefer(item)"
            v-if="deferred && !item.isPaid"
            ><v-icon x-small color="primary">mdi-timer-off</v-icon></v-btn
          >
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import SessionRate from "../../objs/SessionRate";
import OverrideModal from "./override-modal";
import AdjustmentEraser from "../sessions/adjustment-eraser";
import EvalInvoiceCheckbox from "./eval-invoice-checkbox.vue";
import { formatCurrency } from "../../utils/format-utils";
import SessionChargesActionsMenu from "./session-charges-actions-menu.vue";

export default {
  props: ["payable", "nonpayable", "adjustments", "deferred"],
  components: {
    OverrideModal,
    AdjustmentEraser,
    EvalInvoiceCheckbox,
    SessionChargesActionsMenu,
  },
  data: function () {
    return {
      groupBy: "clientFullName",
      headers: [
        {
          text: "Session ID",
          value: "sessionId",
          sortable: true,
        },
        {
          text: "Client",
          value: "clientFullName",
          sortable: true,
        },
        {
          text: "Program",
          value: "program",
          sortable: true,
        },
        {
          text: "Service",
          value: "service",
          sortable: true,
        },
        {
          text: "Date",
          value: "dateOfService",
          sortable: true,
        },
        {
          text: "Time",
          value: "startTime",
          sortable: false,
        },

        {
          text: "Duration",
          value: "durationMinutes",
          sortable: false,
        },

        {
          text: "Location",
          value: "location",
          sortable: true,
        },
        {
          text: "Group",
          value: "groupSize",
          sortable: false,
        },
        {
          text: "Language",
          value: "language",
          sortable: false,
        },
        {
          text: "Rate",
          value: "rateSummary",
          sortable: false,
        },
        {
          text: "Flag",
          value: "flag",
          sortable: false,
        },
        {
          text: "Session Status",
          value: "sessionStatus",
          sortable: false,
        },
        {
          text: "Calculated $",
          value: "calculatedAmount",
          sortable: false,
        },
        {
          text: "Adjusted $",
          value: "adjustedAmount",
          sortable: false,
        },

        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      nameFilter: "",
      programFilter: [],
      showFlaggedOnlyFilter: false,
      selectedItems: [],
    };
  },
  computed: {
    sessionRates() {
      return this.$store.state.providerModule.sessionRates;
    },
    pdfTitle: function () {
      return "Remittance Report";
    },
    clientTotals() {
      var totals = this.sessionCharges.reduce(function (res, value) {
        if (!res[value.clientFullName]) {
          res[value.clientFullName] = 0;
        }
        res[value.clientFullName] += value.adjustedAmount;
        return res;
      }, {});
      return totals;
    },

    currentRemittanceReport: function () {
      return this.$store.state.payrollBatchModule.currentRemittanceReport;
    },
    sessionCharges: function () {
      var charges;
      if (this.deferred) {
        charges = this.currentRemittanceReport.deferredSessionCharges;
      } else if (this.payable) {
        charges = this.$store.getters["payrollBatchModule/getPayableCharges"];
      } else if (this.nonpayable) {
        charges =
          this.$store.getters["payrollBatchModule/getNonPayableCharges"];
      } else if (this.adjustments) {
        charges = this.$store.getters["payrollBatchModule/getAdjustments"];
      }

      charges.sort(function (vote1, vote2) {
        if (vote1.clientFullName > vote2.clientFullName) return 1;
        if (vote1.clientFullName < vote2.clientFullName) return -1;
        if (new Date(vote1.dateOfService) > new Date(vote2.dateOfService))
          return 1;
        if (new Date(vote1.dateOfService) < new Date(vote2.dateOfService))
          return -1;
      });
      return charges;
    },
  },
  methods: {
    formatCurrency,
    async undefer(item) {
      await this.$store.dispatch("payrollBatchModule/undeferSessionCharge", {
        sessionChargeId: item.sessionChargeId,
        remittanceReportId: item.remittanceReportDeferredFromId,
      });
    },

    async defer(item) {
      await this.$store.dispatch("payrollBatchModule/deferSessionCharge", {
        sessionChargeId: item.sessionChargeId,
        remittanceReportId: item.remittanceReportId,
      });
    },
    totalForGroup(groupName) {
      var num = this.clientTotals[groupName];
      return formatCurrency(num);
    },
    createRate(item) {
      var rate = new SessionRate();
      if (item.groupSize > 1) {
        rate.isGroup = true;
        rate.groupSize = item.groupSize;
      }
      if (item.language != "English") {
        rate.isBilingual = true;
      }
      rate.serviceId = item.serviceId;
      rate.programId = item.programId;
      rate.providerId = this.currentRemittanceReport.providerId;
      rate.sessionLocationId = item.locationId;
      rate.sessionLocationCategory = "Specific location";
      rate.isAssign = true;
      this.$router.push({
        name: "New Session Rate",
        params: {
          sessionRate: rate,
        },
      });
    },
    editRate(item) {
      const findIndex = this.sessionRates.findIndex(
        ({ sessionRateId }) => sessionRateId === item.sessionRateId
      );
      this.$router.push({
        name: "New Session Rate",
        params: {
          sessionRate: { ...this.sessionRates[findIndex] },
        },
      });
    },
  },
};
</script>

<style>
.v-data-table span {
  font-size: 8px;
}
</style>
