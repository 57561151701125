var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"primary--text",attrs:{"flat":""}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Paid Sick Leave Request")])],1),_c('v-card-text',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form-field',{attrs:{"field":_vm.startDateField,"validations":_vm.validations.startDate,"readonly":_vm.noEdit},on:{"fieldChange":_vm.onStartDateChange}}),_c('form-field',{attrs:{"field":_vm.endDateField,"validations":_vm.validations.endDate,"readonly":_vm.noEdit},on:{"fieldChange":_vm.onEndDateChange}}),_c('sessions-modal',{attrs:{"startDate":_vm.startDate,"endDate":_vm.endDate,"disabled":invalid,"request":_vm.request,"noEdit":_vm.noEdit},on:{"setHoursRequested":_vm.setHoursRequested,"setSessions":_vm.setSessions}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":_vm.validations.hoursRequested},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',{staticClass:"mt-5"},[_c('v-subheader',{staticClass:"mt-2"},[_vm._v("Total hours to apply:")]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"type":"number","readonly":"","filled":"","dense":"","hide-details":"","reverse":""},model:{value:(_vm.hoursRequested),callback:function ($$v) {_vm.hoursRequested=$$v},expression:"hoursRequested"}})],1)],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],staticClass:"text-caption error--text mb-4"},[_vm._v(" Lookup and select which cancelled sessions to apply paid sick leave hours towards ")])]}}],null,true)}),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Reason","items":_vm.reasonOptions,"error-messages":errors,"readonly":_vm.noEdit},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Provider Notes","readonly":_vm.noEdit},model:{value:(_vm.providerNote),callback:function ($$v) {_vm.providerNote=$$v},expression:"providerNote"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Admin Notes","readonly":_vm.noEdit},model:{value:(_vm.adminNote),callback:function ($$v) {_vm.adminNote=$$v},expression:"adminNote"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","outlined":"","disabled":invalid || _vm.noEdit},on:{"click":_vm.submit}},[_vm._v(" Submit Request ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")]),_c('v-spacer')],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }