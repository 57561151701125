import RemittanceReport from "./RemittanceReport";

export default class PayrollBatch {
  payrollBatchId?: number | null;
  name?: string | null;
  startDate?: Date | null;
  endDate?: Date | null;
  paycheckDate?: Date | null;
  createdOn?: Date | null;
  closedOn?: Date | null;
  totalPayout?: number | null;
  remittanceReports?: RemittanceReport[];
  continueAddingProviders?: boolean | null;

  public constructor(params: PayrollBatch = {} as PayrollBatch) {
    let {
      payrollBatchId = 0,
      name = "",
      startDate = null,
      endDate = null,
      paycheckDate = null,
      createdOn = null,
      continueAddingProviders = false,
      closedOn = null,
      totalPayout = 0,
      remittanceReports = [],
    } = params;
    this.payrollBatchId = payrollBatchId;
    this.name = name;
    this.startDate = startDate;
    this.continueAddingProviders = continueAddingProviders;
    this.endDate = endDate;
    this.paycheckDate = paycheckDate;
    this.createdOn = createdOn;
    this.closedOn = closedOn;
    this.totalPayout = totalPayout;
    this.remittanceReports =
      remittanceReports &&
      remittanceReports.map((i) => new RemittanceReport(i));
  }
}
