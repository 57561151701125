import * as api from "../Services/api.js";

function transformOtherEarningsCharge(charge) {
  charge.chargeDate = new Date(Date.parse(charge.chargeDate));
  charge.createdOn = new Date(Date.parse(charge.createdOn));
}

export const state = {
  charges: [],
};

export const mutations = {
  setCharges(state, payload) {
    state.charges = payload;
    state.charges.forEach((element) => transformOtherEarningsCharge(element));
    state.charges = state.charges.sort((a, b) => b.chargeDate - a.chargeDate);
  },

  ADD_OTHER_EARNING_CHARGE(state, payload) {
    state.charges.push({ ...payload });
    state.charges = state.charges.sort((a, b) => b.chargeDate - a.chargeDate);
  },
  UPDATE_OTHER_EARNING_CHARGE(state, payload) {
    const findIndex = state.charges.findIndex(
      ({ otherEarningsChargeId }) => otherEarningsChargeId === payload
    );
    state.charges.splice(findIndex, 1, { ...payload });
  },

  DELETE_OTHER_EARNING_CHARGE(state, payload) {
    const findIndex = state.charges.findIndex(
      ({ otherEarningsChargeId }) => otherEarningsChargeId === payload
    );
    state.charges.splice(findIndex, 1);
  },
};

export const getters = {};

export const actions = {
  async getAllOtherEarningsCharges({ commit }) {
    await api
      .get("/otherEarningsCharge/getAllOtherEarningsCharges")
      .then((response) => {
        if (response.success) {
          commit("setCharges", response.data);
        }
      });
  },

  async deleteOtherEarningsCharge({ commit }, data) {
    return await api
      .post(
        "/otherEarningsCharge/deleteOtherEarningsCharge?otherEarningsChargeId=" +
          data
      )
      .then((response) => {
        response.success && commit("DELETE_OTHER_EARNING_CHARGE", data);
        return response.success;
      });
  },

  async createOtherEarningsCharge({ commit }, data) {
    return await api
      .post("/otherEarningsCharge/createOtherEarningsCharge", data)
      .then((response) => {
        response.success && commit("ADD_OTHER_EARNING_CHARGE", response.data);
        return response.success;
      });
  },
  async updateOtherEarningsCharge({ commit }, data) {
    return await api
      .post("/otherEarningsCharge/updateOtherEarningsCharge", data)
      .then((response) => {
        response.success &&
          commit("UPDATE_OTHER_EARNING_CHARGE", response.data);
        return response.success;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
