<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <v-btn
          text
          x-small
          :disabled="!fileId"
          v-on="on"
          v-on:click="downloadFile"
        >
          <v-icon>mdi-cloud-download-outline</v-icon>
        </v-btn>
      </span>
    </template>
    <span>{{ title }}</span>
  </v-tooltip>
</template>

<script>
import { downloadFileUrl } from "../../utils/download-utils";
export default {
  props: ["fileId", "filename"],
  computed: {
    title: function () {
      return this.fileId
        ? "Download Report"
        : "Formatted PDF report unavailable to download.";
    },
  },
  methods: {
    downloadFile: async function () {
      this.$store.commit("uxModule/setShowLoader", true);
      var fileUrl = await this.$store.dispatch(
        "filesModule/getFileUrl",
        {
          fileId: this.fileId,
        }
      );
      this.$store.commit("uxModule/setShowLoader", false);

      if (fileUrl) {
        downloadFileUrl(fileUrl, this.filename);
      } else {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Oops! Something went wrong. Please try again later."
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
    },
  },
};
</script>