import { formatDate, formatTime } from "../utils/format-utils";

export default class SessionDetails {
  sessionId?: number | null;
  legacyActivityId?: number | null;
  legacyActionId?: number | null;
  providerId?: number | null;
  providerName?: string | null;
  clientName?: string | null;
  clientId?: number | null;
  dateOfService?: Date | null;
  startTime?: Date | string | null;
  durationMinutes?: number | null;
  programId?: number | null;
  programName?: string | null;
  serviceId?: number | null;
  serviceName?: string | null;
  groupSize?: number | null;
  isBilingual?: boolean | null;
  language?: string | null;
  location?: string | null;
  isCancellation?: boolean | null;
  isAbsence?: boolean | null;
  isHoliday?: boolean | null;
  isHoldPay?: boolean | null;
  overrideAmount?: number | null;
  overrideExplanation?: string | null;
  appInvoiceAccepted?: boolean | null;
  evalInvoiceReceived?: boolean | null;
  remoteAttendanceLogReceived?: boolean | null;
  rsInvoiceProcessed?: boolean | null;
  sCNoteCertified?: boolean | null;
  hasInPersonConsent?: boolean | null;
  hasRemoteConsent?: boolean | null;
  editedAt?: Date | null;
  lastProcessedAt?: Date | null;
  isRemoteAttendanceLogRequired?: boolean | null;

  public constructor(params: SessionDetails = {} as SessionDetails) {
    let {
      sessionId = 0,
      legacyActivityId = 0,
      legacyActionId = 0,
      providerId = 0,
      providerName = "",
      clientId = 0,
      clientName = "",
      dateOfService = null,
      startTime = null,
      durationMinutes = 0,
      programId = 0,
      programName = "",
      serviceId = 0,
      serviceName = "",
      groupSize = 0,
      isBilingual = false,
      language = "",
      location = "",
      isCancellation = false,
      isAbsence = false,
      isHoliday = false,
      isHoldPay = false,
      overrideAmount = null,
      overrideExplanation = "",
      appInvoiceAccepted = false,
      evalInvoiceReceived = false,
      remoteAttendanceLogReceived = false,
      rsInvoiceProcessed = false,
      sCNoteCertified = false,
      hasInPersonConsent = false,
      hasRemoteConsent = false,
      editedAt = null,
      lastProcessedAt = null,
      isRemoteAttendanceLogRequired = false,
    } = params;

    this.sessionId = sessionId;
    this.legacyActivityId = legacyActivityId;
    this.legacyActionId = legacyActionId;
    this.providerId = providerId;
    this.providerName = providerName;
    this.clientId = clientId;
    this.clientName = clientName;
    this.dateOfService = dateOfService;
    this.startTime = formatTime(startTime);
    this.durationMinutes = durationMinutes;
    this.programId = programId;
    this.programName = programName;
    this.serviceId = serviceId;
    this.serviceName = serviceName;
    this.groupSize = groupSize;
    this.isBilingual = isBilingual;
    this.language = language;
    this.location = location;
    this.isCancellation = isCancellation;
    this.isAbsence = isAbsence;
    this.isHoliday = isHoliday;
    this.isHoldPay = isHoldPay;
    this.overrideAmount = overrideAmount;
    this.overrideExplanation = overrideExplanation;
    this.appInvoiceAccepted = appInvoiceAccepted;
    this.evalInvoiceReceived = evalInvoiceReceived;
    this.remoteAttendanceLogReceived = remoteAttendanceLogReceived;
    this.rsInvoiceProcessed = rsInvoiceProcessed;
    this.sCNoteCertified = sCNoteCertified;
    this.hasInPersonConsent = hasInPersonConsent;
    this.hasRemoteConsent = hasRemoteConsent;
    this.editedAt = editedAt;
    this.lastProcessedAt = lastProcessedAt;
    this.isRemoteAttendanceLogRequired = isRemoteAttendanceLogRequired;
  }

  get formattedDateOfService() {
    return formatDate(this.dateOfService);
  }

  get sessionStatus() {
    if (this.isAbsence) {
      return "Absence";
    } else if (this.isCancellation) {
      return "Cancellation";
    } else if (this.isHoliday) {
      return "Holiday";
    } else {
      return "Standard";
    }
  }

  get missingRemoteAttendanceLog() {
    return (
      this.isRemoteAttendanceLogRequired && !this.remoteAttendanceLogReceived
    );
  }

  get isEvalInvoiceRequired() {
    return [1, 7, 10, 19].includes(this.programId || 0);
  }
}
