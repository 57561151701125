<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>All Paid Sick Leave Requests</v-toolbar-title>
      <v-spacer></v-spacer>
      <new-request-button></new-request-button>
    </v-toolbar>
    <requests-table></requests-table>
  </div>
</template>
<script>
import NewRequestButton from "./new-request-button.vue";
import RequestsTable from "./requests-table.vue";
export default {
  components: {
    NewRequestButton,
    RequestsTable,
  },
};
</script>