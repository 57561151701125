import axios from "axios";
const urlPrefix = process.env.VUE_APP_OMNI_BASE_URL + "/api";
var securityApiUrlPrefix = process.env.VUE_APP_OMNI_SECURITY_BASE_URL + "/api";

export async function sendFeedbackEmail(data) {
  return axios
    .post(omniApiUrlPrefix + "/shared/sendFeedbackEmail", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getUserAccess(data) {
  return axios
    .get(securityApiUrlPrefix + "/identity/getUserAccess", {
      params: {
        clientId: data.clientId,
        userId: data.userId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export function getUser(data) {
  return axios
    .get(securityApiUrlPrefix + "/identity/getUser", {
      params: {
        userId: data,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export async function get(subURL) {
  return axios
    .get(urlPrefix + subURL)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function getQuery(subURL, data) {
  return axios
    .get(urlPrefix + subURL, {
      params: data,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function post(subURL, data) {
  return axios
    .post(urlPrefix + subURL, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function postQuery(subURL, data) {
  return axios
    .post(urlPrefix + subURL, null, {
      params: data,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}
