export default class OtherEarningsCharge {
  otherEarningsChargeId?: number | null;
  isPaid?: boolean | null;
  createdOn?: Date | null;
  providerId?: number | null;
  canModify?: boolean | null;
  remittanceReportId?: number | null;
  legacyProviderId?: string | null;
  providerText?: string | null;
  chargeDate?: Date | null;
  calculatedAmount?: number | null;
  durationMinutes?: number | null;
  isHourlyAmount?: boolean | null;
  amount?: number | null;
  isScheduledDeduction?: boolean | null;
  scheduledDeductionId?: number | null;
  chargeType?: string | null;
  earningsCodeId?: number | null;
  earningsCodeText?: string | null;
  adpCode?: string | null;
  omniCorporationDivisionId?: number | null;
  omniCorporationDivisionText?: string | null;
  programId?: number | null;
  programText?: string | null;
  payrollBatchId?: number | null;
  payrollBatchText?: string | null;
  payrollBatch?: string | null;
  payrollBatchDeferredFromId?: number | null;
  remittanceReportDeferredFromId?: number | null;
  payrollBatchDeferredFrom?: string | null;
  note?: string | null;

  public constructor(params: OtherEarningsCharge = {} as OtherEarningsCharge) {
    let {
      otherEarningsChargeId = 0,
      isPaid = false,
      createdOn = null,
      providerId = 0,
      canModify = false,
      remittanceReportId = null,
      legacyProviderId = "",
      providerText = "",
      chargeDate = new Date(),
      calculatedAmount = 0,
      durationMinutes = 0,
      isHourlyAmount = false,
      amount = 0,
      isScheduledDeduction = false,
      scheduledDeductionId = 0,
      chargeType = "",
      earningsCodeId = 0,
      earningsCodeText = "",
      adpCode = "",
      omniCorporationDivisionId = 0,
      omniCorporationDivisionText = "",
      programId = 0,
      programText = "",
      payrollBatchId = null,
      payrollBatchText = "",
      payrollBatch = "",
      payrollBatchDeferredFromId = null,
      remittanceReportDeferredFromId = null,
      payrollBatchDeferredFrom = "",
      note = "",
    } = params;

    this.otherEarningsChargeId = otherEarningsChargeId;
    (this.isPaid = isPaid),
      (this.createdOn = createdOn),
      (this.providerId = providerId);
    this.canModify = canModify;
    this.remittanceReportId = remittanceReportId;
    this.legacyProviderId = legacyProviderId;
    this.providerText = providerText;
    this.chargeDate = chargeDate;
    this.calculatedAmount = calculatedAmount;
    this.durationMinutes = durationMinutes;
    this.isHourlyAmount = isHourlyAmount;
    this.amount = amount;
    this.isScheduledDeduction = isScheduledDeduction;
    this.scheduledDeductionId = scheduledDeductionId;
    this.chargeType = chargeType;
    this.earningsCodeId = earningsCodeId;
    this.earningsCodeText = earningsCodeText;
    this.adpCode = adpCode;
    this.omniCorporationDivisionId = omniCorporationDivisionId;
    this.omniCorporationDivisionText = omniCorporationDivisionText;
    this.programId = programId;
    this.programText = programText;
    this.payrollBatchId = payrollBatchId;
    this.payrollBatchText = payrollBatchText;
    this.payrollBatch = payrollBatch;
    this.payrollBatchDeferredFromId = payrollBatchDeferredFromId;
    this.remittanceReportDeferredFromId = remittanceReportDeferredFromId;
    this.payrollBatchDeferredFrom = payrollBatchDeferredFrom;
    this.note = note;
  }
}
