import Provider from "./Provider";

export default class PaidSickLeaveRequest {
  paidSickLeaveCarryoverId?: number | null;
  providerId?: number | null;
  provider?: Provider | null;
  year?: number | null;
  carryover?: number | null;
  public constructor(
    params: PaidSickLeaveRequest = {} as PaidSickLeaveRequest
  ) {
    let {
      paidSickLeaveCarryoverId = 0,
      providerId = 0,
      provider = null,
      year = 0,
      carryover = 0,
    } = params;
    this.paidSickLeaveCarryoverId = paidSickLeaveCarryoverId;
    this.providerId = providerId;
    this.provider = provider ? new Provider(provider) : null;
    this.year = year;
    this.carryover = carryover;
  }

  get providerName() {
    if (this.provider)
      return this.provider.firstName + " " + this.provider.lastName;
  }

  get legacyProviderId() {
    if (this.provider) return this.provider.legacyProviderId;
  }
}
