<template>
  <div>
    <div class="d-flex pb-10 mb-5">
      <v-btn
        text
        plain
        color="primary"
        link
        :to="{ name: 'Other Earnings Charges' }"
      >
        Other Earnings
      </v-btn>
      <v-icon color="primary"> mdi-menu-right </v-icon>
      <v-btn text plain color="primary">
        {{ $route.name }}
      </v-btn>
    </div>
    <validation-observer v-slot="{ invalid }">
      <v-row>
        <v-col cols="6">
          <validation-provider
            :rules="validations.providerId"
            v-slot="{ errors }"
          >
            <v-autocomplete
              v-model="form.providerId"
              :items="providerOptions"
              label="Select provider"
              clearable
              :error-messages="errors"
              :readonly="noEdit"
            ></v-autocomplete>
          </validation-provider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <validation-provider
            :rules="validations.omniCorporationDivisionId"
            v-slot="{ errors }"
          >
            <v-select
              v-model="form.omniCorporationDivisionId"
              :items="omniCorpOptions"
              item-text="description"
              item-value="omniCorporationDivisionId"
              label="Omni Corporation"
              :error-messages="errors"
              :readonly="noEdit"
            ></v-select>
          </validation-provider>
        </v-col>

        <v-col cols="3">
          <validation-provider
            :rules="validations.programId"
            v-slot="{ errors }"
          >
            <v-select
              v-model="form.programId"
              :items="programOptions"
              label="Program"
              :item-text="(item) => `${item.code} - ${item.name}`"
              item-value="programId"
              :error-messages="errors"
              :readonly="noEdit"
            ></v-select>
          </validation-provider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="2">
          <date-picker
            :value="form.chargeDate"
            :field="{ label: 'Charge Date' }"
            @fieldChange="changedDate"
            :readonly="noEdit"
          ></date-picker>
        </v-col>

        <v-col cols="4">
          <validation-provider
            :rules="validations.earningsCodeId"
            v-slot="{ errors }"
          >
            <v-select
              v-model="form.earningsCodeId"
              :items="earningsCodesOptions"
              item-text="name"
              item-value="earningsCodeId"
              label="Charge Type"
              :error-messages="errors"
              :readonly="noEdit"
            ></v-select>
          </validation-provider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-radio-group
            row
            v-model="form.isHourlyAmount"
            @change="updateCalculatedAmount"
            label="Payment type"
            :readonly="noEdit"
          >
            <v-radio label="Hourly" :value="true"></v-radio>
            <v-radio label="Flat fee" :value="false"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <validation-provider
            :rules="validations.duration"
            v-slot="{ errors }"
          >
            <v-text-field
              type="number"
              min="0"
              label="Duration (hours)"
              :value="form.durationMinutes / 60"
              @input="changedDuration"
              :error-messages="errors"
              :readonly="noEdit"
            ></v-text-field>
          </validation-provider>
        </v-col>

        <v-col cols="2">
          <validation-provider :rules="validations.amount" v-slot="{ errors }">
            <v-text-field
              v-model="form.amount"
              @input="updateCalculatedAmount"
              :label="amountLabel"
              prefix="$"
              type="number"
              min="0"
              step="00.1"
              :error-messages="errors"
              :readonly="noEdit"
            ></v-text-field>
          </validation-provider>
        </v-col>

        <v-col cols="2">
          <v-text-field
            v-model="form.calculatedAmount"
            label="Total Amount"
            prefix="$"
            readonly
            disabled
            type="number"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="form.note"
            label="Note"
            :readonly="noEdit"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4"> </v-col>
        <v-col cols="1">
          <v-btn color="primary" @click="cancel" text>Cancel</v-btn>
        </v-col>
        <v-col cols="1">
          <v-btn
            color="primary"
            :disabled="invalid || noEdit"
            @click="save"
            text
            >Save</v-btn
          >
        </v-col>
      </v-row>
    </validation-observer>
  </div>
</template>

<script>
import OtherEarningsCharge from "../../objs/OtherEarningsCharge";
import {
  required,
  minValue,
  notDefault,
} from "../../plugins/vee-validate/validation";

export default {
  props: ["noEdit", "charge"],
  data() {
    return {
      form: this.charge ? this.charge : new OtherEarningsCharge(),
    };
  },
  computed: {
    amountLabel() {
      return this.form.isHourlyAmount ? "Amount (per hour)" : "Flat amount";
    },
    providerOptions() {
      return this.$store.state.optionModule.providers;
    },
    earningsCodesOptions() {
      var reimbursements = this.$store.state.optionModule.earningsCodes.filter(
        (t) => t.isReimbursement
      );
      var deductions = this.$store.state.optionModule.earningsCodes.filter(
        (t) => t.isDeduction
      );
      var opts = [];
      opts.push({ header: "Reimbursements" });
      opts = opts.concat(reimbursements);
      opts.push({ divider: true });
      opts.push({ header: "Deductions" });
      opts = opts.concat(deductions);
      return opts;
    },
    omniCorpOptions() {
      return this.$store.state.optionModule.omniCorporationDivisions;
    },
    programOptions() {
      return this.$store.state.optionModule.programs;
    },
    uxData() {
      if (this.form.otherEarningsChargeId > 0) {
        return {
          loaderText: "Updating item...",
          path: "otherEarningsChargesModule/updateOtherEarningsCharge",
          snackText: "Updated charge successfully!",
        };
      }
      return {
        loaderText: "Creating item...",
        path: "otherEarningsChargesModule/createOtherEarningsCharge",
        snackText: "Created charge successfully!",
      };
    },
    validations() {
      return {
        providerId: {
          required: true,
          requiredValue: true,
        },
        omniCorporationDivisionId: {
          required: true,
          requiredValue: true,
        },
        programId: {
          required: true,
          requiredValue: true,
        },
        earningsCodeId: {
          required: true,
          requiredValue: true,
        },
        duration: {
          required: this.form.isHourlyAmount,
        },
        amount: {
          required: true,
          minValue: 0,
        },
      };
    },
  },
  methods: {
    changedDuration(event) {
      this.form.durationMinutes = event * 60;
      this.updateCalculatedAmount();
    },
    changedDate(event) {
      this.form.chargeDate = event;
    },
    updateCalculatedAmount() {
      if (this.form.isHourlyAmount) {
        this.form.calculatedAmount =
          this.form.amount * (this.form.durationMinutes / 60);
      } else {
        this.form.calculatedAmount = this.form.amount;
      }
    },
    async save() {
      this.$store.dispatch("uxModule/showLoader", this.uxData.loaderText);

      const success = await this.$store.dispatch(this.uxData.path, {
        ...this.form,
      });

      this.$store.dispatch("uxModule/hideLoader");

      if (success)
        this.$store.dispatch("uxModule/showSnackBarMsg", this.uxData.snackText);
      else
        this.$store.dispatch(
          "uxModule/showSnackBarMsg",
          "Error saving charge."
        );

      this.$router.push({
        name: "Other Earnings Charges",
      });
    },
    cancel() {
      this.$router.push({ name: "Other Earnings Charges" });
    },
  },
};
</script>
