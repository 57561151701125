<template>
  <v-container>
    <v-row>
      <v-col cols="4">
        <v-select
          v-model="batch"
          :items="batchOptions"
          item-text="name"
          return-object
          label="Payroll Batch"
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-data-table
        :headers="headers"
        :items="reportsList"
        item-key="name"
        dense
        class="elevation-1"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <tooltip-button
            icon="mdi-cloud-download"
            size="26"
            tooltip-text="Download report"
            :disabled="!selectedBatchId"
            @click="downloadReport(item.downloadPath)"
          ></tooltip-button>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
import { downloadZip } from "../../utils/download-utils";
import { formatDateForFilename } from "../../utils/format-utils";
export default {
  async mounted() {
    if (this.batchOptions.length == 0) {
      await this.$store.dispatch("payrollBatchModule/loadBatches");
    }
  },
  data() {
    return {
      batch: null,
      headers: [
        { text: "Report", value: "name" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  computed: {
    batchOptions() {
      return this.$store.state.payrollBatchModule.batches;
    },
    reportsList() {
      return [
        {
          name: "Missing ADP Accounts",
          downloadPath: "missingAdpAccountsForBatch",
        },
        {
          name: "EIO Missing Remote Consent Charges",
          downloadPath: "EIOMissingRemoteConsentChargesForBatch",
        },
        {
          name: "Paydata Spreadsheet for Review",
          downloadPath: "generatePaydataReportsForReview",
        },
        {
          name: "Paydata Spreadsheet for Upload",
          downloadPath: "generatePaydataReportsForUpload",
        },
        {
          name: "Provider Remittances",
          downloadPath: "getProviderRemittances",
        },
      ];
    },
    selectedBatchId() {
      return this.batch && this.batch.payrollBatchId;
    },

    zipFileName() {
      return `ProviderRemittancesPayrollPeriodEnding_${formatDateForFilename(
        this.batch.endDate
      )}.zip`;
    },
  },
  methods: {
    getPayrollBatchReportUrl(path) {
      return (
        process.env.VUE_APP_OMNI_BASE_URL +
        "/api/payrollBatch/" +
        path +
        "?payrollBatchId=" +
        this.selectedBatchId.toString()
      );
    },
    async downloadReport(path) {
      if (path === "getProviderRemittances") {
        await this.downloadProviderRemittances();
      } else {
        let fileUrl = this.getPayrollBatchReportUrl(path);
        window.location = fileUrl;
      }
    },

    async downloadProviderRemittances() {
      this.$store.commit("uxModule/setShowLoader", true, { root: true });

      let success = false;
      let fileIds = await this.$store.dispatch(
        "reportsModule/getFileIdsForPayrollBatch",
        { batchId: this.selectedBatchId.toString() }
      );

      if (fileIds && fileIds.length > 0) {
        let fileDownloads = await this.$store.dispatch(
          "filesModule/generateFileDownloads",
          {
            fileIds: fileIds,
          }
        );

        if (fileDownloads && fileDownloads.length > 0) {
          try {
            await downloadZip(fileDownloads, this.zipFileName);
            success = true;
          } catch (err) {
            console.log(`Error downloading zip: ${err}`);
          }
        }
      }
      this.$store.commit("uxModule/setShowLoader", false, { root: true });

      if (!success) {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          `Error downloading provider remittances for Payroll Batch ${this.batch.name}!`
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
    },
  },
};
</script>
