<template>
  <div>
    <v-row v-if="!isProvider">
      <v-col cols="4">
        <v-autocomplete
          v-model="nameFilter"
          clearable
          :items="nameOptions"
          label="Search by provider"
        ></v-autocomplete>
      </v-col>

      <v-col cols="2">
        <v-select
          v-if="!isMedical"
          v-model="codeFilter"
          :items="codeFilterOptions"
          item-text="name"
          item-value="earningsCodeId"
          label="Charge Type"
          clearable
        ></v-select>
        <v-select
          v-if="isMedical"
          v-model="codeFilter"
          :items="codeFilterOptions"
          label="Charge Type"
          clearable
        ></v-select>
      </v-col>

      <v-col cols="2">
        <v-select
          v-model="paidFilter"
          :items="paidFilterOptions"
          label="Payment Status"
          clearable
        ></v-select>
      </v-col>
    </v-row>

    <v-row v-if="isProvider" class="my-8">
      <other-charges-actions-menu
        :selectedCharges="selectedItems"
      ></other-charges-actions-menu>
    </v-row>

    <v-data-table
      v-model="selectedItems"
      :show-select="isProvider"
      dense
      :headers="headers"
      :items="charges"
      item-key="otherEarningsChargeId"
      class="elevation-1"
    >
      <template v-slot:[`item.providerText`]="{ item }">
        <span style="font-size: 12px">{{ item.providerText }}</span>
      </template>

      <template v-slot:[`item.legacyProviderId`]="{ item }">
        <span style="font-size: 12px">{{ item.legacyProviderId }}</span>
      </template>

      <template v-slot:[`item.earningsCodeText`]="{ item }">
        <span style="font-size: 12px">{{ item.earningsCodeText }}</span>
      </template>

      <template v-slot:[`item.calculatedAmount`]="{ item }">
        <span style="font-size: 12px">{{
          formatCurrency(item.calculatedAmount)
        }}</span>
      </template>

      <template v-slot:[`item.chargeDate`]="{ item }">
        <span style="font-size: 12px">{{
          formatDateString(item.chargeDate)
        }}</span>
      </template>

      <template v-slot:[`item.note`]="{ item }">
        <span style="font-size: 11px">{{ item.note }}</span>
      </template>

      <template v-slot:[`item.isScheduledDeduction`]="{ item }">
        <span style="font-size: 12px" v-if="item.isScheduledDeduction"
          >Auto-scheduled</span
        >
        <span v-else style="font-size: 12px">Manually entered</span>
      </template>

      <template v-slot:[`item.payrollBatchText`]="{ item }">
        <span style="font-size: 11px">{{ item.payrollBatchText }}</span>
      </template>

      <template v-slot:[`item.isPaid`]="{ item }">
        <span style="font-size: 12px" v-if="item.isPaid">Paid</span>
        <span v-else style="font-size: 12px">Unpaid</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <tooltip-button
          icon="mdi-magnify"
          size="16"
          tooltip-text="View charge"
          v-if="item.isPaid"
          v-on:click="viewCharge(item)"
        ></tooltip-button>
        <tooltip-button
          icon="mdi-delete"
          size="16"
          tooltip-text="Delete charge"
          v-if="!item.isPaid"
          v-on:click="deleteCharge(item)"
        ></tooltip-button>
        <tooltip-button
          icon="mdi-pencil"
          size="16"
          tooltip-text="Edit charge"
          v-if="!item.isPaid"
          v-on:click="editCharge(item)"
          >Edit</tooltip-button
        >
        <tooltip-button
          icon="mdi-timer-sand"
          size="16"
          tooltip-text="Defer charge"
          v-if="!item.isPaid && item.remittanceReportId !== null"
          v-on:click="deferCharge(item)"
        ></tooltip-button>
        <tooltip-button
          icon="mdi-timer-off"
          size="16"
          tooltip-text="Undefer charge"
          v-if="
            !item.isPaid &&
            item.payrollBatchDeferredFromId !== null &&
            item.remittanceReportId === null
          "
          v-on:click="undeferCharge(item)"
        ></tooltip-button>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { formatCurrency } from "../../utils/format-utils";
import OtherChargesActionsMenu from "../process/other-charges-actions-menu.vue";

export default {
  props: ["isMedical", "isProvider", "chargeList"],
  components: {
    OtherChargesActionsMenu,
  },
  data: function () {
    return {
      nameFilter: "",
      codeFilter: "",
      paidFilter: "",
      paidFilterOptions: [
        { text: "Paid", value: true },
        { text: "Unpaid", value: false },
      ],
      selectedItems: [],
    };
  },
  computed: {
    headers() {
      var headerList = [];
      if (!this.isProvider) {
        headerList.push({
          text: "Provider ID",
          value: "legacyProviderId",
          sortable: true,
        });
        headerList.push({
          text: "Provider",
          value: "providerText",
          sortable: true,
        });
      }
      if (this.isMedical) {
        var medicalList = [
          { text: "Charge ID", value: "otherEarningsChargeId", sortable: true },
          { text: "Category", value: "chargeType", sortable: false },
          { text: "ADP Code", value: "adpCode", sortable: false },
          { text: "Amount", value: "calculatedAmount", sortable: false },
          { text: "Charge Date", value: "chargeDate", sortable: false },
          { text: "Note", value: "note", sortable: false },
          { text: "Payroll Batch", value: "payrollBatchText", sortable: false },
          { text: "Paid", value: "isPaid", sortable: false },
          {
            text: "Auto / Scheduled",
            value: "isScheduledDeduction",
            sortable: false,
          },
          { text: "Actions", value: "actions", sortable: false },
        ];
        headerList = headerList.concat(medicalList);
      }
      if (!this.isMedical) {
        var otherList = [
          { text: "Charge ID", value: "otherEarningsChargeId", sortable: true },
          { text: "Category", value: "chargeType", sortable: false },
          { text: "ADP Code", value: "adpCode", sortable: false },
          { text: "Description", value: "earningsCodeText", sortable: false },
          { text: "Amount", value: "calculatedAmount", sortable: false },
          { text: "Charge Date", value: "chargeDate", sortable: false },
          { text: "Note", value: "note", sortable: false },
          { text: "Payroll Batch", value: "payrollBatchText", sortable: false },
          { text: "Paid", value: "isPaid", sortable: false },
          { text: "Actions", value: "actions", sortable: false },
        ];
        headerList = headerList.concat(otherList);
      }
      return headerList;
    },
    codeFilterOptions() {
      if (!this.isMedical) {
        var reimbursements =
          this.$store.state.optionModule.earningsCodes.filter(
            (t) =>
              t.isReimbursement &&
              t.earningsCodeId != 16 &&
              t.earningsCodeId != 17 &&
              t.earningsCodeId != 18
          );
        var deductions = this.$store.state.optionModule.earningsCodes.filter(
          (t) =>
            t.isDeduction &&
            t.earningsCodeId != 3 &&
            t.earningsCodeId != 4 &&
            t.earningsCodeId != 5
        );

        var opts = [];
        opts.push({ header: "Reimbursements" });
        opts = opts.concat(reimbursements);
        opts.push({ divider: true });
        opts.push({ header: "Deductions" });
        opts = opts.concat(deductions);
        return opts;
      } else if (this.isMedical) {
        return [
          { text: "Medical", value: "M" },
          { text: "Dental", value: "D" },
          { text: "FSA / HSA", value: "L" },
        ];
      }
    },
    nameOptions() {
      var opts = [];
      this.chargeList.forEach((t) => {
        opts.push({
          text: t.providerText + " - " + t.legacyProviderId,
          value: t.providerId,
        });
      });
      return opts;
    },
    charges() {
      var filteredList = this.chargeList;

      //Name filter
      //Name filter
      if (this.nameFilter != "" && this.nameFilter != null) {
        filteredList = filteredList.filter(
          (t) => t.providerId == this.nameFilter
        );
      }
      //Code filter
      if (this.codeFilter != "" && this.codeFilter != null && !this.isMedical) {
        filteredList = filteredList.filter(
          (t) => t.earningsCodeId == this.codeFilter
        );
      }

      if (this.codeFilter != "" && this.codeFilter != null && this.isMedical) {
        filteredList = filteredList.filter((t) => t.adpCode == this.codeFilter);
      }

      //Paid filter
      if (this.paidFilter !== "" && this.paidFilter !== null) {
        filteredList = filteredList.filter((t) => t.isPaid == this.paidFilter);
      }

      return filteredList;
    },
  },
  methods: {
    formatCurrency,
    formatDateString(date) {
      return date instanceof Date
        ? date.toLocaleDateString("us-en")
        : new Date(date).toLocaleDateString("us-en");
    },
    async deferCharge(item) {
      this.$store.dispatch("uxModule/showLoader", "Deferring charge");
      await this.$store.dispatch(
        "payrollBatchModule/deferOtherEarningsCharge",
        {
          otherEarningsChargeId: item.otherEarningsChargeId,
          remittanceReportId: item.remittanceReportId,
        }
      );

      this.$store.dispatch("uxModule/hideLoader");
    },
    async undeferCharge(item) {
      this.$store.dispatch("uxModule/showLoader", "Undeferring charge");
      await this.$store.dispatch(
        "payrollBatchModule/undeferOtherEarningsCharge",
        { otherEarningsChargeId: item.otherEarningsChargeId }
      );
      this.$store.commit("uxModule/setShowLoader", false);
    },
    async deleteCharge(item) {
      this.$store.dispatch("uxModule/showLoader", "Deleting Item");
      const success = await this.$store.dispatch(
        "otherEarningsChargesModule/deleteOtherEarningsCharge",
        item.otherEarningsChargeId
      );

      this.$store.dispatch("uxModule/hideLoader");

      if (success)
        this.$store.dispatch(
          "uxModule/showSnackBarMsg",
          "Deleted successfully"
        );
      else this.$store.dispatch("uxModule/showSnackBarMsg", "Raised an error!");
    },
    editCharge(item) {
      this.$router.push({
        name: "Edit Other Earnings Charge",
        params: {
          charge: item,
          noEdit: false,
        },
      });
    },
    viewCharge(item) {
      this.$router.push({
        name: "View Other Earnings Charge",
        params: {
          charge: item,
          noEdit: true,
        },
      });
    },
  },
};
</script>
