<template>
  <div class="table">
    <v-row>
      <v-radio-group v-model="groupBy" row label="Group charges by">
        <v-radio label="Provider" value="providerName"></v-radio>
        <v-radio label="Client" value="clientName"></v-radio>
        <v-radio label="Service" value="serviceName"></v-radio>
        <v-radio label="Program" value="programName"></v-radio>
      </v-radio-group>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        prepend-icon="mdi-magnify"
        clearable
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="sessions"
      :sort-by="['providerName', 'clientName', 'dateOfService']"
      :group-by="groupBy"
      :search="search"
      v-model="selected"
      show-select
      item-key="sessionId"
      fixed-header
      hide-default-footer
      disable-pagination
      dense
    >
      <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen }">
        <td :colspan="headers.length">
          <v-btn @click="toggle" x-small icon :ref="group">
            <v-icon v-if="isOpen">mdi-minus</v-icon>
            <v-icon v-else>mdi-plus</v-icon>
          </v-btn>
          <span class="mx-5 font-weight-bold" style="font-size: 14px">{{
            group
          }}</span>
        </td>
      </template>
      <template v-slot:[`item.sessionId`]="{ item }">
        <span style="font-size: 11px">{{ item.sessionId }}</span>
      </template>
      <template v-slot:[`item.legacyActivityId`]="{ item }">
        <span style="font-size: 11px">{{ item.legacyActivityId }}</span>
      </template>
      <template v-slot:[`item.providerName`]="{ item }">
        <span style="font-size: 11px">{{ item.providerName }} </span>
      </template>
      <template v-slot:[`item.clientName`]="{ item }">
        <span style="font-size: 11px">{{ item.clientName }}</span>
      </template>
      <template v-slot:[`item.dateOfService`]="{ item }">
        <span style="font-size: 11px">{{ item.formattedDateOfService }}</span>
      </template>
      <template v-slot:[`item.startTime`]="{ item }">
        <span style="font-size: 11px">{{ item.startTime }}</span>
      </template>
      <template v-slot:[`item.durationMinutes`]="{ item }">
        <span style="font-size: 11px">{{ item.durationMinutes }} minutes</span>
      </template>
      <template v-slot:[`item.programName`]="{ item }">
        <span style="font-size: 11px">{{ item.programName }}</span>
      </template>
      <template v-slot:[`item.serviceName`]="{ item }">
        <span style="font-size: 11px">{{ item.serviceName }}</span>
      </template>
      <template v-slot:[`item.location`]="{ item }">
        <span style="font-size: 11px">{{ item.location }}</span>
      </template>
      <template v-slot:[`item.bilingual`]="{ item }">
        <span style="font-size: 11px" v-if="item.isBilingual">Yes</span>
        <span style="font-size: 11px" v-else>No</span>
      </template>
      <template v-slot:[`item.language`]="{ item }">
        <span style="font-size: 11px">{{ item.language }}</span>
      </template>
      <template v-slot:[`item.invoiced`]="{ item }">
        <span style="font-size: 11px" v-if="item.invoiced">Yes</span>
        <span style="font-size: 11px" v-else>No</span>
      </template>
      <template v-slot:[`item.sessionStatus`]="{ item }">
        <span style="font-size: 11px">{{ item.sessionStatus }}</span>
      </template>
      <template v-slot:[`item.onHold`]="{ item }">
        <span style="font-size: 11px" v-if="item.isHoldPay">Yes</span>
        <span style="font-size: 11px" v-else>No</span>
      </template>
      <template v-slot:[`item.overrideAmount`]="{ item }">
        <span style="font-size: 11px">{{
          formatCurrency(item.overrideAmount)
        }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-row class="align-center">
          <override-modal
            :sessionId="item.sessionId"
            mode="OverrideSession"
            :noEdit="noEdit"
          ></override-modal>
          <charges-modal :sessionId="item.sessionId"></charges-modal>
          <remote-attendance-log-checkbox
            :item="item"
          ></remote-attendance-log-checkbox>
          <eval-invoice-checkbox :item="item"></eval-invoice-checkbox>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import OverrideModal from "../process/override-modal";
import ChargesModal from "./charges-modal";
import RemoteAttendanceLogCheckbox from "./remote-attendance-log-checkbox.vue";
import EvalInvoiceCheckbox from "../process/eval-invoice-checkbox.vue";
import { formatCurrency } from "../../utils/format-utils";
export default {
  components: {
    OverrideModal,
    ChargesModal,
    RemoteAttendanceLogCheckbox,
    EvalInvoiceCheckbox,
  },
  props: ["sessions"],
  data() {
    return {
      headers: [
        { text: "Session ID", value: "sessionId" },
        { text: "Activity ID", value: "legacyActivityId" },
        { text: "Provider", value: "providerName" },
        { text: "Client", value: "clientName" },
        { text: "Date", value: "dateOfService" },
        { text: "Time", value: "startTime", sortable: false },
        { text: "Duration", value: "durationMinutes", sortable: false },
        { text: "Program", value: "programName" },
        { text: "Service", value: "serviceName" },
        { text: "Location", value: "location" },
        { text: "Bilingual", value: "bilingual", sortable: false },
        { text: "Language", value: "language", sortable: false },
        { text: "Invoiced", value: "invoiced", sortable: false },
        { text: "Schedule Status", value: "sessionStatus" },
        { text: "On Hold", value: "onHold", sortable: false },
        { text: "Override", value: "overrideAmount", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      selected: [],
      groupBy: "providerName",
      search: "",
      filter: "",
    };
  },
  computed: {
    noEdit() {
      return this.$store.getters["securityModule/isReadOnly"];
    },
  },
  methods: {
    formatCurrency,
  },
  watch: {
    selected() {
      this.$emit("rowSelected", this.selected);
    },
  },
};
</script>

<style>
.table {
  border-collapse: collapse;
  width: 100%;
}
</style>
