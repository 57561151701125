import * as api from "../Services/api.js";
import SessionDetails from "../../objs/SessionDetails";

export const state = {
  sessionDetails: [],
  sessionCharges: [],
};

export const mutations = {
  setSessionDetails(state, payload) {
    state.sessionDetails = payload.map((item) => new SessionDetails(item));
  },

  setSessionCharges(state, payload) {
    state.sessionCharges = [...payload];
  },

  clearSessionCharges(state) {
    state.sessionCharges = [];
  },

  clearSessionDetails(state) {
    state.sessionDetails = [];
  },

  updateOverrideInSessionDetails(state, payload) {
    let found = state.sessionDetails.find((o, i) => {
      if (o.sessionId == payload.sessionId) {
        o.overrideAmount = payload.amount;
        return true;
      }
    });
  },
};
export const getters = {};

export const actions = {
  async getChargesForSession({ commit, state }, data) {
    var response = await api.get(
      "/sessionDetails/getChargesForSession?sessionId=" + data
    );
    if (response.success) {
      commit("setSessionCharges", response.data);
    }
    return response.success && state.sessionCharges.length > 0;
  },

  async lookupSessionByProviderAndDate({ commit, state }, data) {
    var response = await api.getQuery("/sessionDetails/findByProviderAndDate", {
      providerId: data.providerId,
      dateOfService: data.dateOfService,
    });
    if (response.success) {
      commit("setSessionDetails", response.data);
    }
    return response.success && state.sessionDetails.length > 0;
  },

  async lookupSessionByProviderAndDateRange({ commit, state }, data) {
    var response = await api.getQuery(
      "/sessionDetails/findByProviderAndDateRange",
      {
        providerId: data.providerId,
        startDate: data.startDate,
        endDate: data.endDate,
      }
    );
    if (response.success) {
      commit("setSessionDetails", response.data);
    }
    return response.success && state.sessionDetails.length > 0;
  },

  async lookupSessionByProviderAndClient({ commit, state }, data) {
    var response = await api.getQuery(
      "/sessionDetails/findByProviderAndClient",
      {
        providerId: data.providerId,
        clientId: data.clientId,
      }
    );
    if (response.success) {
      commit("setSessionDetails", response.data);
    }
    return response.success && state.sessionDetails.length > 0;
  },

  async lookupSessionByDateOfService({ commit, state }, data) {
    var response = await api.getQuery("/sessionDetails/findByDateOfService", {
      dateOfService: data.dateOfService,
    });
    if (response.success) {
      commit("setSessionDetails", response.data);
    }
    return response.success && state.sessionDetails.length > 0;
  },

  async lookupSessionByLegacyActivityId({ commit, state }, data) {
    var response = await api.getQuery(
      "/sessionDetails/findByLegacyActivityId",
      {
        legacyActivityId: data.legacyActivityId,
      }
    );
    if (response.success) {
      commit("setSessionDetails", response.data);
    }
    return response.success && state.sessionDetails.length > 0;
  },

  async lookupSessionBySessionId({ commit, state }, data) {
    var response = await api.getQuery("/sessionDetails/findBySessionId", {
      sessionId: data.sessionId,
    });
    if (response.success) {
      commit("setSessionDetails", response.data);
    }
    return response.success && state.sessionDetails.length > 0;
  },

  async lookupSessionByClient({ commit, state }, data) {
    var response = await api.getQuery("/sessionDetails/findByClient", {
      clientId: data.clientId,
    });
    if (response.success) {
      commit("setSessionDetails", response.data);
    }
    return response.success && state.sessionDetails.length > 0;
  },

  async lookupSessionsMissingRemoteAttendanceLog({ commit, state }) {
    var response = await api.get(
      "/sessionDetails/findSessionsMissingRemoteAttendanceLog"
    );
    if (response.success) {
      commit("setSessionDetails", response.data);
    }
    return response.success && state.sessionDetails.length > 0;
  },

  async overrideSession({ commit, state }, data) {
    var response = await api.post(
      "/sessionRate/createOrUpdateSessionOverride",
      data
    );
    if (response.success) {
      //Reset the current remittance report
      commit("updateOverrideInSessionDetails", response.data);
    }
  },

  async bulkOverrideSessions({ commit, state }, data) {
    var response = await api.post("/sessionRate/bulkOverrideSessions", data);
    if (response.success) {
      response.data.forEach((t) => {
        commit("updateOverrideInSessionDetails", response.data);
      });
    }
  },

  async clearSessionOverride({ commit, state }, data) {
    var response = await api.postQuery(
      "/sessionRate/deleteSessionOverride",
      data
    );
    if (response.success) {
      //Reset the current remittance report
      commit("updateOverrideInSessionDetails", {
        sessionId: data.sessionId,
        amount: null,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
