<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on }">
        <v-tooltip bottom v-if="mode != 'BulkOverride'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              v-bind="attrs"
              icon
              @click="dialog = true"
              v-on="on"
              :disabled="noEdit"
            >
              <v-icon x-small>mdi-currency-usd-off</v-icon>
            </v-btn>
          </template>
          <span>Override session payout</span>
        </v-tooltip>

        <v-btn
          v-else
          color="primary"
          :dark="!noEdit"
          small
          @click="dialog = true"
          v-on="on"
          :disabled="noEdit"
          >Bulk Override</v-btn
        >
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Override Rate
        </v-card-title>

        <v-card-text class="mt-4">
          <v-row>
            <v-col cols="10">
              <v-text-field
                v-model="amount"
                label="Custom amount"
                dense
                prefix="$"
                type="number"
                min="0"
                step="0.01"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="10">
              <v-text-field
                v-model="explanation"
                label="Adjustment explanation"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="cancel"> Cancel </v-btn>

          <v-btn
            color="primary"
            text
            v-if="mode == 'OverrideSession'"
            @click="clearOverride"
          >
            Clear override
          </v-btn>

          <v-btn color="primary" text @click="override"> Override </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["sessionChargeId", "sessionId", "sessionIds", "mode", "noEdit"],
  data: function () {
    return {
      dialog: false,
      amount: 0,
      explanation: "",
    };
  },
  methods: {
    cancel() {
      this.amount = 0;
      this.explanation = "";
      this.dialog = false;
    },
    override: async function () {
      if (this.mode == "OverrideSessionCharge") {
        await this.$store.dispatch("payrollBatchModule/overrideSessionCharge", {
          sessionChargeId: this.sessionChargeId,
          amount: this.amount,
          explanation: this.explanation,
        });
        this.dialog = false;
      } else if (this.mode == "OverrideSession") {
        await this.$store.dispatch("sessionModule/overrideSession", {
          sessionId: this.sessionId,
          amount: this.amount,
          overrideExplanation: this.explanation,
        });
        this.dialog = false;
      } else if (this.mode == "BulkOverride") {
        var i;
        for (i = 0; i < this.sessionIds.length; i++) {
          await this.$store.dispatch("sessionModule/overrideSession", {
            sessionId: this.sessionIds[i],
            amount: this.amount,
            overrideExplanation: this.explanation,
          });
        }
        this.dialog = false;
      }
    },
    clearOverride: async function () {
      await this.$store.dispatch("sessionModule/clearSessionOverride", {
        sessionId: this.sessionId,
      });
      this.dialog = false;
    },
  },
};
</script>
