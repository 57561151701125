var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-radio-group',{attrs:{"row":"","label":"Group charges by"},model:{value:(_vm.groupBy),callback:function ($$v) {_vm.groupBy=$$v},expression:"groupBy"}},[_c('v-radio',{attrs:{"label":"Client","value":"clientFullName"}}),_c('v-radio',{attrs:{"label":"Service","value":"service"}}),_c('v-radio',{attrs:{"label":"Program","value":"program"}})],1)],1),_c('v-row',[_c('session-charges-actions-menu',{attrs:{"selectedCharges":_vm.selectedItems,"deferred":_vm.deferred}})],1),_c('v-data-table',{staticClass:"mt-8",attrs:{"dense":"","headers":_vm.headers,"fixed-header":"","height":"65vh","items":_vm.sessionCharges,"item-key":"sessionChargeId","group-by":_vm.groupBy,"hide-default-footer":"","disable-pagination":"","show-select":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-btn',{ref:group,attrs:{"x-small":"","icon":""},on:{"click":toggle}},[(isOpen)?_c('v-icon',[_vm._v("mdi-minus")]):_c('v-icon',[_vm._v("mdi-plus")])],1),_c('span',{staticClass:"mx-5 font-weight-bold",staticStyle:{"font-size":"18px","font-weight":"bold"}},[_vm._v(_vm._s(group))]),(_vm.groupBy == 'clientFullName')?_c('span',{staticClass:"mx-5 font-weight-bold",staticStyle:{"font-size":"18px","font-weight":"bold"}},[_vm._v("Total: "+_vm._s(_vm.totalForGroup(group)))]):_vm._e()],1)]}},{key:"item.clientFullName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12px","font-weight":"bold"}},[_vm._v(_vm._s(item.clientFullName))])]}},{key:"item.sessionId",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12px","font-weight":"bold"}},[_vm._v(_vm._s(item.sessionId))])]}},{key:"item.durationMinutes",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12px","font-weight":"bold"}},[_vm._v(_vm._s(item.durationMinutes)+" min")])]}},{key:"item.groupSize",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12px","font-weight":"bold"}},[_vm._v(_vm._s(item.groupSize))])]}},{key:"item.rateSummary",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"font-size":"12px","font-weight":"bold"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.rateSummary.substr(0, item.rateSummary.indexOf(",")))+" ")])]}}],null,true)},[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(item.rateSummary))])])]}},{key:"item.sessionStatus",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12px","font-weight":"bold"}},[_vm._v(_vm._s(item.sessionStatus))])]}},{key:"item.flag",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12px","font-weight":"bold"}},[_vm._v(_vm._s(item.flag))])]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12px","font-weight":"bold"}},[_vm._v(_vm._s(item.location))])]}},{key:"item.language",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12px","font-weight":"bold"}},[_vm._v(_vm._s(item.language))])]}},{key:"item.calculatedAmount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12px","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.formatCurrency(item.calculatedAmount))+" ")])]}},{key:"item.dateOfService",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12px","font-weight":"bold"}},[_vm._v(_vm._s(item.dateOfService))])]}},{key:"item.startTime",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12px","font-weight":"bold"}},[_vm._v(_vm._s(item.startTime))])]}},{key:"item.program",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12px","font-weight":"bold"}},[_vm._v(_vm._s(item.program))])]}},{key:"item.service",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12px","font-weight":"bold"}},[_vm._v(_vm._s(item.service))])]}},{key:"item.adjustedAmount",fn:function(ref){
var item = ref.item;
return [(Boolean(item.adjustmentExplanation))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"font-size":"12px","font-weight":"bold","cursor":"pointer"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.formatCurrency(item.adjustedAmount))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.adjustmentExplanation))])]):_c('span',{staticStyle:{"font-size":"12px","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.formatCurrency(item.adjustedAmount))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":"","dense":""}},[(item.isEvalInvoiceRequired)?_c('eval-invoice-checkbox',{attrs:{"item":item}}):_vm._e(),_c('v-btn',{staticClass:"mt-2",attrs:{"x-small":"","title":"Create rate","icon":""},on:{"click":function($event){return _vm.createRate(item)}}},[_c('v-icon',{attrs:{"x-small":"","color":"primary"}},[_vm._v("mdi-currency-usd")])],1),(item.isAdjustment)?_c('adjustment-eraser',{attrs:{"sessionChargeId":item.sessionChargeId,"calculatedAmount":item.calculatedAmount,"adjustedAmount":item.adjustedAmount}}):_vm._e(),_c('override-modal',{attrs:{"sessionChargeId":item.sessionChargeId,"mode":"OverrideSessionCharge"}}),(!_vm.deferred)?_c('v-btn',{staticClass:"mt-2",attrs:{"title":"Defer charge","x-small":"","icon":""},on:{"click":function($event){return _vm.defer(item)}}},[_c('v-icon',{attrs:{"x-small":"","color":"primary"}},[_vm._v("mdi-timer-sand")])],1):_vm._e(),(_vm.deferred && !item.isPaid)?_c('v-btn',{staticClass:"mt-2",attrs:{"title":"Undefer charge","x-small":"","icon":""},on:{"click":function($event){return _vm.undefer(item)}}},[_c('v-icon',{attrs:{"x-small":"","color":"primary"}},[_vm._v("mdi-timer-off")])],1):_vm._e()],1)]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }