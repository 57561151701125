<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <v-btn text x-small v-on="on" @click="goToEditRequest">
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
      </span>
    </template>
    <span> Edit request </span>
  </v-tooltip>
</template>
<script>
export default {
  props: ["request"],
  methods: {
    goToEditRequest() {
      this.$store.dispatch("paidSickLeaveModule/setSelectedProviderId", {
        providerId: this.request.providerId,
      });
      this.$router.push({
        name: "Edit Request",
        params: {
          request: this.request,
        },
      });
    },
  },
};
</script>
