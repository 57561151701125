<template>
  <div>
    <v-row dense>
      <v-col cols="2">
        <v-autocomplete
          v-model="providerFilter"
          :items="providerOptions"
          clearable
          label="Provider name"
        ></v-autocomplete>
      </v-col>

      <v-col cols="2">
        <v-select
          :items="programOptions"
          :item-text="(item) => `${item.code} - ${item.name}`"
          item-value="code"
          label="Program"
          v-model="programFilter"
          clearable
        ></v-select>
      </v-col>
    </v-row>
    <v-divider class="mt-4"></v-divider>
    <remittance-reports-table
      :items="filteredBatch"
      :review="true"
    ></remittance-reports-table>
    <v-row class="pt-4">
      <label class="primary--text font-weight-bold text-body-1">
        Showing {{ filteredBatch.length }} out of
        {{ currentBatch.length }} reports
      </label>
    </v-row>
  </div>
</template>

<script>
import RemittanceReportsTable from "./remittance-reports-table.vue";
export default {
  components: {
    RemittanceReportsTable,
  },
  computed: {
    providerFilter: {
      get() {
        return this.$store.state.payrollBatchModule.providerFilter;
      },
      set(val) {
        this.$store.commit("payrollBatchModule/setCurrentBatchFilter", {
          filterName: "providerFilter",
          value: val,
        });
      },
    },
    programFilter: {
      get() {
        return this.$store.state.payrollBatchModule.programFilter;
      },
      set(val) {
        this.$store.commit("payrollBatchModule/setCurrentBatchFilter", {
          filterName: "programFilter",
          value: val,
        });
      },
    },
    providerOptions: function () {
      return this.$store.getters["payrollBatchModule/getProviderOptions"];
    },
    programOptions: function () {
      return this.$store.state.optionModule.programs;
    },
    currentBatch: function () {
      return this.$store.state.payrollBatchModule.currentBatch;
    },
    filteredBatch() {
      var batchRows = this.currentBatch;
      if (Boolean(this.providerFilter)) {
        batchRows = batchRows.filter(
          (t) => t.providerId == this.providerFilter
        );
      }
      if (Boolean(this.programFilter)) {
        batchRows = batchRows.filter(
          (t) => t.programs != null && t.programs.includes(this.programFilter)
        );
      }
      return batchRows;
    },
  },
};
</script>
