var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"d-flex pb-10 mb-5"},[_c('v-btn',{attrs:{"text":"","plain":"","color":"primary","link":"","to":{ name: 'All Providers' }}},[_vm._v(" Provider Rates ")]),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-menu-right ")]),_c('v-btn',{attrs:{"text":"","plain":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$route.name)+" ")])],1),_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Provider List")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('import-provider-modal',{attrs:{"noEdit":_vm.noEdit}}),_c('v-btn',{staticClass:"btn mb-2",attrs:{"disabled":_vm.noEdit,"color":"primary","dark":!_vm.noEdit},on:{"click":_vm.showNewProvider}},[_vm._v(" New Provider ")])],1),_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-row',{staticClass:"mt-5 mb-2"},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"height":"42","items":_vm.disciplines,"item-text":"name","item-value":"disciplineId","multiple":"","clearable":"","label":"Filter by disciplines"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.filterDisciplines.length - 1)+" others) ")]):_vm._e()]}}]),model:{value:(_vm.filterDisciplines),callback:function ($$v) {_vm.filterDisciplines=$$v},expression:"filterDisciplines"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"clearable":"","label":"Search","height":"42","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 mx-auto",attrs:{"headers":_vm.headers,"items":_vm.tableData,"sort-by":"name"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":"primary","disabled":_vm.noEdit},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" fa fa-pencil-square-o ")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit provider")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.showRate(item)}}},[_vm._v(" fa fa-usd ")])],1)]}}],null,true)},[_c('span',[_vm._v("View session rates")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.showOtherEarnings(item)}}},[_vm._v(" fa fa-money ")])],1)]}}],null,true)},[_c('span',[_vm._v("View other earnings")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(item.canDelete)?_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":"primary","disabled":_vm.noEdit},on:{"click":function($event){return _vm.deleteItem(item.providerId)}}},[_vm._v(" fa fa-trash-o ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Delete provider")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.goToRemittances(item)}}},[_vm._v(" mdi-file-code-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("View all remittances")])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }