export const SessionQueries = {
  PROVIDER_AND_DATE: "Provider and date of service",
  PROVIDER_AND_DATE_RANGE: "Provider and date range",
  PROVIDER_AND_CLIENT: "Provider and client",
  CLIENT: "Client",
  DATE_OF_SERVICE: "Date of service",
  LEGACY_ACTIVITY_ID: "Legacy activity ID",
  SESSION_ID: "Session ID",
  MISSING_REMOTE_ATTENDANCE_LOG: "Missing Remote Attendance Log",
};
