<template>
  <div>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn
        @click="goToMissingEvalInvoice"
        plain
        small
        color="primary"
        class="text-decoration-underline"
        >View All Missing Invoices</v-btn
      >
    </v-row>
    <v-row dense>
      <v-col cols="2">
        <v-autocomplete
          v-model="providerFilter"
          :items="providerOptions"
          clearable
          label="Provider name"
        ></v-autocomplete>
      </v-col>

      <v-col cols="2">
        <v-select
          :items="programOptions"
          :item-text="(item) => `${item.code} - ${item.name}`"
          item-value="code"
          label="Program"
          v-model="programFilter"
          clearable
        ></v-select>
      </v-col>

      <v-col cols="2">
        <v-select
          label="More filters"
          :items="filterOptions"
          v-model="selectedFilters"
          clearable
        >
        </v-select>
      </v-col>

      <v-spacer></v-spacer>

      <v-col cols="1">
        <add-providers-to-batch class="pt-5"></add-providers-to-batch>
      </v-col>

      <v-col cols="1">
        <add-manual-check-to-batch class="pt-5"></add-manual-check-to-batch>
      </v-col>
    </v-row>
    <v-divider class="mt-4"></v-divider>
    <remittance-reports-table
      :items="filteredBatch"
      :review="false"
    ></remittance-reports-table>
    <v-row class="pt-4">
      <label class="primary--text font-weight-bold text-body-1">
        Showing {{ filteredBatch.length }} out of
        {{ currentBatch.length }} reports
      </label>
    </v-row>
  </div>
</template>

<script>
import AddProvidersToBatch from "./add-providers-to-batch";
import AddManualCheckToBatch from "./add-manual-check-to-batch";
import RemittanceReportsTable from "./remittance-reports-table.vue";
export default {
  components: {
    AddProvidersToBatch,
    AddManualCheckToBatch,
    RemittanceReportsTable,
  },
  data: function () {
    return {
      filterOptions: [
        "Missing rates only",
        "Negative payouts only",
        "Flagged only",
      ],
    };
  },
  computed: {
    providerFilter: {
      get() {
        return this.$store.state.payrollBatchModule.providerFilter;
      },
      set(val) {
        this.$store.commit("payrollBatchModule/setCurrentBatchFilter", {
          filterName: "providerFilter",
          value: val,
        });
      },
    },
    selectedFilters: {
      get() {
        return this.$store.state.payrollBatchModule.additionalFilters;
      },
      set(val) {
        this.$store.commit("payrollBatchModule/setCurrentBatchFilter", {
          filterName: "additionalFilters",
          value: val,
        });
      },
    },
    programFilter: {
      get() {
        return this.$store.state.payrollBatchModule.programFilter;
      },
      set(val) {
        this.$store.commit("payrollBatchModule/setCurrentBatchFilter", {
          filterName: "programFilter",
          value: val,
        });
      },
    },
    providerOptions: function () {
      return this.$store.getters["payrollBatchModule/getProviderOptions"];
    },
    programOptions: function () {
      return this.$store.state.optionModule.programs;
    },
    currentBatch: function () {
      return this.$store.state.payrollBatchModule.currentBatch;
    },
    filteredBatch() {
      var batchRows = this.currentBatch;
      if (Boolean(this.providerFilter)) {
        batchRows = batchRows.filter(
          (t) => t.providerId == this.providerFilter
        );
      }
      if (Boolean(this.programFilter)) {
        batchRows = batchRows.filter(
          (t) => t.programs != null && t.programs.includes(this.programFilter)
        );
      }
      if (this.selectedFilters == "Flagged only") {
        batchRows = batchRows.filter((t) => t.isFlagged == true);
      }
      if (this.selectedFilters == "Missing rates only") {
        batchRows = batchRows.filter((t) => t.missingRates == true);
      }
      if (this.selectedFilters == "Negative payouts only") {
        batchRows = batchRows.filter((t) => t.totalPayout < 0);
      }
      return batchRows;
    },
  },
  methods: {
    goToMissingEvalInvoice() {
      this.$router.push({
        name: "Missing Invoices",
      });
    },
  },
};
</script>
