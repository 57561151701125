import * as api from "../Services/api.js";

export const state = {
  schools: [],
  teams: [],
  serviceOptions: [],
  programOptions: [],
  omniSiteOptions: [],
  omniCorpOptions: [],
  locationOptions: ["Clinic", "Offsite"],
};

export const mutations = {
  SET_SCHOOLS(state, payload) {
    state.schools = payload;
  },
  SET_TEAMS(state, payload) {
    state.teams = payload;
  },
  SET_SERVICE_OPTIONS(state, payload) {
    state.serviceOptions = payload;
  },
  SET_PROGRAM_OPTIONS(state, payload) {
    state.programOptions = payload;
  },
  SET_OMNI_SITES(state, payload) {
    state.omniSiteOptions = payload;
  },
  SET_OMNI_CORPS(state, payload) {
    state.omniCorpOptions = payload;
  },
};

export const getters = {};

export const actions = {
  async getReportOptions({ commit }) {
    await api
      .get("/options/getReportOptions")
      .then((response) => {
        if (response.success) {
          commit("SET_SCHOOLS", response.data.schools);
          commit("SET_TEAMS", response.data.teams);
          commit("SET_PROGRAM_OPTIONS", response.data.programCodes);
          commit("SET_SERVICE_OPTIONS", response.data.serviceCodes);
          commit("SET_OMNI_SITES", response.data.omniSites);
          commit("SET_OMNI_CORPS", response.data.omniCorporations);
        }
        return response.success;
      })
      .catch((err) => {
        return false;
      });
  },
  async getExpensesByPaydateReport({}, data) {
    return await api
      .post("/expenseReport/getExpensesByPaydate", data)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return false;
      });
  },
  async getExpensesDetailsByPaydateReport({}, data) {
    return await api
      .post("/expenseReport/getExpensesDetailsByPaydate", data)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return false;
      });
  },
  async getExpensesDetailsByDosReport({}, data) {
    return await api
      .post("/expenseReport/getExpensesDetailsByDateOfService", data)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return false;
      });
  },
  async getFileIdsForPayrollBatch({}, data) {
    return await api
      .get("/paystub/GetFileIdsForPayrollBatch?payrollBatchId=" + data.batchId)
      .then((response) => {
        if (response.success) {
          return response.data;
        }

        return false;
      })
      .catch((err) => {
        return false;
      });
  },
  async getSessionRatesReport({}, data) {
    return await api
      .post("/sessionRate/getSessionRatesReport", data)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return false;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
