<template>
  <v-checkbox
    v-if="item.isEvalInvoiceRequired"
    x-small
    v-model="item.evalInvoiceReceived"
    @change="setHasInvoice"
  >
    <template v-slot:label>
      <span style="font-size: 10px">Invoiced</span>
    </template>
  </v-checkbox>
</template>

<script>
export default {
  props: ["item"],
  methods: {
    async setHasInvoice() {
      await this.$store.dispatch("payrollBatchModule/setEvalSessionInvoice", {
        remittanceReportId: this.item.remittanceReportId,
        sessionId: this.item.sessionId,
        hasInvoice: this.item.evalInvoiceReceived,
      });
    },
  },
};
</script>
