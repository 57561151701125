<template>
  <div>
    <v-row>
      <v-col cols="10">
        <div class="d-flex">
          <v-btn
            text
            plain
            color="primary"
            link
            :to="{ name: 'Current batch' }"
          >
            {{ batchLabel }}
          </v-btn>
          <v-icon color="primary"> mdi-menu-right </v-icon>
          <v-btn text plain color="primary"> Missing Invoices </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-radio-group v-model="groupBy" row label="Group charges by">
        <v-radio label="Provider" value="providerFullName"></v-radio>
        <v-radio label="Client" value="clientFullName"></v-radio>
        <v-radio label="Service" value="service"></v-radio>
        <v-radio label="Program" value="program"></v-radio>
      </v-radio-group>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        prepend-icon="mdi-magnify"
        clearable
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="charges"
      :loading="loading"
      :sort-by="['providerFullName', 'clientFullName']"
      :group-by="groupBy"
      :search="search"
      fixed-header
      dense
      class="mt-8"
      height="80vw"
      hide-default-footer
      disable-pagination
    >
      <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen }">
        <td :colspan="headers.length">
          <v-btn @click="toggle" x-small icon :ref="group">
            <v-icon v-if="isOpen">mdi-minus</v-icon>
            <v-icon v-else>mdi-plus</v-icon>
          </v-btn>
          <span
            class="mx-5 font-weight-bold"
            style="font-size: 18px; font-weight: bold"
            >{{ group }}</span
          >
        </td>
      </template>
      <template v-slot:[`item.rateSummary`]="{ item }">
        <v-tooltip bottom max-width="250px">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" style="font-size: 12px">
              {{ item.rateSummary.substr(0, item.rateSummary.indexOf(",")) }}
            </span>
          </template>
          <span>{{ item.rateSummary }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <eval-invoice-checkbox :item="item"></eval-invoice-checkbox>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import EvalInvoiceCheckbox from "./eval-invoice-checkbox.vue";
export default {
  components: {
    EvalInvoiceCheckbox,
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch(
      "payrollBatchModule/loadChargesMissingEvalInvoice"
    );
    this.loading = false;
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: "Session ID", value: "sessionId", sortable: true },
        { text: "Provider", value: "providerFullName", sortable: true },
        { text: "Client", value: "clientFullName", sortable: true },
        { text: "Program", value: "program", sortable: true },
        { text: "Service", value: "service", sortable: true },
        { text: "Date", value: "dateOfService", sortable: true },
        { text: "Time", value: "startTime", sortable: false },
        { text: "Duration", value: "durationMinutes", sortable: false },
        { text: "Location", value: "location", sortable: true },
        { text: "Group", value: "groupSize", sortable: false },
        { text: "Language", value: "language", sortable: false },
        { text: "Rate", value: "rateSummary", sortable: false },
        { text: "Flag", value: "flag", sortable: false },
        { text: "Session Status", value: "sessionStatus", sortable: false },
        {
          text: "Calculated $",
          value: "formattedCalculatedAmount",
          sortable: false,
        },
        {
          text: "Adjusted $",
          value: "formattedAdjustedAmount",
          sortable: false,
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      groupBy: "providerFullName",
      search: "",
    };
  },
  computed: {
    charges() {
      return this.$store.state.payrollBatchModule.chargesMissingEvalInvoice;
    },
    batchLabel() {
      return this.$store.getters["payrollBatchModule/getCurrentBatchName"];
    },
  },
};
</script>
