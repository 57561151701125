var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{attrs:{"height":"60vh"}},[_c('tr',{staticClass:"font-weight-bold"},[_c('td',{staticClass:"lg-col"},[_vm._v("Provider Name")]),_c('td',{staticClass:"sm-col"},[_vm._v("Provider ID")]),_c('td',{staticClass:"med-col"},[_vm._v("Programs")]),_c('td',{staticClass:"med-col"},[_vm._v("Payout")]),_c('td',{staticClass:"sm-col"},[_vm._v("Flagged")]),_c('td',{staticClass:"sm-col"},[_vm._v("Details")]),_c('td',{staticClass:"med-col"},[_vm._v("Last Rerun")]),_c('td',{staticClass:"lg-col"},[_vm._v("Actions")])]),_c('v-divider'),_c('v-virtual-scroll',{directives:[{name:"scroll",rawName:"v-scroll.self",value:(_vm.onScroll),expression:"onScroll",modifiers:{"self":true}}],attrs:{"items":_vm.items,"item-height":"50","id":"virtual-scroll-table"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('tr',{key:item.remittanceReportId,staticClass:"text-body-2 font-weight-medium"},[_c('td',{staticClass:"lg-col"},[_vm._v(_vm._s(item.providerName))]),_c('td',{staticClass:"sm-col"},[_vm._v(_vm._s(item.legacyProviderId))]),_c('td',{staticClass:"med-col"},[_vm._v(_vm._s(item.programs))]),_c('td',{staticClass:"med-col"},[_vm._v(_vm._s(_vm.formatCurrency(item.totalPayout)))]),_c('td',{staticClass:"sm-col"},[_c('v-switch',{attrs:{"dense":"","hide-details":"","disabled":_vm.review},on:{"change":function($event){return _vm.flagItem(item)}},model:{value:(item.isFlagged),callback:function ($$v) {_vm.$set(item, "isFlagged", $$v)},expression:"item.isFlagged"}})],1),_c('td',{staticClass:"sm-col"},[(item.missingRates)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-alert-box-outline")])]}}],null,true)},[_c('span',[_vm._v("Missing Rates")])]):_vm._e(),(item.isManual)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-checkbook")])]}}],null,true)},[_c('span',[_vm._v("Manual Check")])]):_vm._e(),(!item.missingRates && !item.isManual)?_c('span',{staticClass:"grey--text darken-2 text-body-2"},[_vm._v("N/A")]):_vm._e()],1),_c('td',{staticClass:"med-col"},[_vm._v(_vm._s(_vm.formatDateAndTime(item.lastRerunAt)))]),_c('td',{staticClass:"lg-col"},[_c('v-row',{attrs:{"dense":""}},[(!_vm.review)?_c('rerun-modal',{attrs:{"remittanceReportId":item.remittanceReportId,"origStart":item.startDate,"origEnd":item.endDate}}):_vm._e(),(!_vm.review)?_c('reimport-modal',{attrs:{"legacyId":item.legacyProviderId,"origStart":item.startDate,"origEnd":item.endDate}}):_vm._e(),(!_vm.review)?_c('v-btn',{attrs:{"title":"Go to report","x-small":"","text":""},on:{"click":function($event){return _vm.goToRemittanceReport(item)}}},[_c('v-icon',[_vm._v("mdi-arrow-right-bold")])],1):_vm._e(),(!_vm.review)?_c('close-report-modal',{attrs:{"remittanceReportId":item.remittanceReportId}}):_vm._e(),(_vm.review)?_c('preview-file-action',{attrs:{"fileId":item.fileId}}):_vm._e(),(_vm.review)?_c('download-file-action',{attrs:{"fileId":item.fileId,"filename":_vm.reportFilename(item)}}):_vm._e(),(_vm.review)?_c('revert-report-modal',{attrs:{"remittanceReportId":item.remittanceReportId}}):_vm._e()],1)],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }