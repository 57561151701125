<template>
  <router-view></router-view>
</template>
<script>
export default {
  async mounted() {
    await this.$store.dispatch(
      "paidSickLeaveModule/getMaxPaidSickLeaveHoursPerSchoolYear"
    );
  },
};
</script>
