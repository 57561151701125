<template>
  <div>
    <other-earnings-charges-table
      :chargeList="charges"
      :isMedical="true"
    ></other-earnings-charges-table>
  </div>
</template>

<script>
import OtherEarningsChargesTable from "./other-earnings-charges-table";
export default {
  components: { OtherEarningsChargesTable },
  computed: {
    charges() {
      var chargeList = this.$store.state.otherEarningsChargesModule.charges;
      chargeList.sort((a, b) => b.chargeDate - a.chargeDate);
      return chargeList.filter(
        (t) => t.adpCode == "D" || t.adpCode == "M" || t.adpCode == "L"
      );
    },
  },
};
</script>
