<template>
  <v-row>
    <v-col cols="4">
      <v-list nav>
        <v-subheader>Carryover Settings</v-subheader>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item v-for="(item, i) in items" :key="i">
            <v-list-item-icon>
              <v-icon>{{item.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{item.text}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-col>
    <v-col>
      <move-to-carryover-form v-if="selectedItem == 0"></move-to-carryover-form>
      <carryover-table v-else-if="selectedItem == 1"></carryover-table>
    </v-col>
  </v-row>
</template>
<script>
import MoveToCarryoverForm from "./move-to-carryover-form.vue";
import CarryoverTable from "./carryover-table.vue";
export default {
  components: { MoveToCarryoverForm, CarryoverTable },
  data() {
    return {
      selectedItem: null,
      items: [
        { text: "Move Balance To Carryover", icon: "mdi-arrow-top-right" },
        { text: "Review Current Carryover Hours", icon: "mdi-view-list" },
      ],
    };
  },
};
</script>