var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"80vw","height":"80vw"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":!_vm.disabled,"rounded":"","small":"","disabled":_vm.disabled},on:{"click":_vm.lookupSessions}},'v-btn',attrs,false),on),[_vm._v(" Lookup sessions to apply ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_vm._v("Select the cancelled sessions to apply paid sick leave hours")]),_c('v-card-text',{staticClass:"my-4"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.cancelledSessions,"item-key":"sessionId","loading":_vm.loading,"show-select":""},on:{"item-selected":_vm.onSelect},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(){return undefined},proxy:true},{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [(item.isPartialSession)?_c('span',{staticClass:"error--text"},[_vm._v("*")]):_vm._e(),_c('v-simple-checkbox',{attrs:{"value":isSelected,"disabled":(_vm.maxHours && !isSelected) || _vm.noEdit},on:{"input":function($event){return select($event)}}})]}},{key:"footer.prepend",fn:function(){return [_c('v-subheader',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPartialSessionMessage),expression:"showPartialSessionMessage"}],staticClass:"error--text"},[_vm._v("* partial session will be applied ")])]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-row',[_c('v-spacer')],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-subheader',{directives:[{name:"show",rawName:"v-show",value:(_vm.maxHours),expression:"maxHours"}],staticClass:"error--text"},[_vm._v("You've reached your maximum available hours to request paid sick leave. ")]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":"Total hours","value":_vm.hoursRequested,"type":"numeric","readonly":"","outlined":"","hide-details":""}})],1),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":_vm.noEdit},on:{"click":_vm.apply}},[_vm._v(" Apply ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }