var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"text":"","plain":"","color":"primary","link":"","to":{ name: 'Current batch' }}},[_vm._v(" "+_vm._s(_vm.batchLabel)+" ")]),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-menu-right ")]),_c('v-btn',{attrs:{"text":"","plain":"","color":"primary"}},[_vm._v(" Missing Invoices ")])],1)])],1),_c('v-row',[_c('v-radio-group',{attrs:{"row":"","label":"Group charges by"},model:{value:(_vm.groupBy),callback:function ($$v) {_vm.groupBy=$$v},expression:"groupBy"}},[_c('v-radio',{attrs:{"label":"Provider","value":"providerFullName"}}),_c('v-radio',{attrs:{"label":"Client","value":"clientFullName"}}),_c('v-radio',{attrs:{"label":"Service","value":"service"}}),_c('v-radio',{attrs:{"label":"Program","value":"program"}})],1),_c('v-spacer'),_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","clearable":"","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"mt-8",attrs:{"headers":_vm.headers,"items":_vm.charges,"loading":_vm.loading,"sort-by":['providerFullName', 'clientFullName'],"group-by":_vm.groupBy,"search":_vm.search,"fixed-header":"","dense":"","height":"80vw","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-btn',{ref:group,attrs:{"x-small":"","icon":""},on:{"click":toggle}},[(isOpen)?_c('v-icon',[_vm._v("mdi-minus")]):_c('v-icon',[_vm._v("mdi-plus")])],1),_c('span',{staticClass:"mx-5 font-weight-bold",staticStyle:{"font-size":"18px","font-weight":"bold"}},[_vm._v(_vm._s(group))])],1)]}},{key:"item.rateSummary",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"font-size":"12px"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.rateSummary.substr(0, item.rateSummary.indexOf(",")))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.rateSummary))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('eval-invoice-checkbox',{attrs:{"item":item}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }