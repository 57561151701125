<template>
  <v-checkbox
    v-if="item.isRemoteAttendanceLogRequired"
    x-small
    v-model="item.remoteAttendanceLogReceived"
    @change="setRemoteAttendanceLog"
  >
    <template v-slot:label>
      <span style="font-size: 10px">Remote Attendance Log</span>
    </template>
  </v-checkbox>
</template>

<script>
export default {
  props: ["item"],
  methods: {
    async setRemoteAttendanceLog() {
      this.$store.commit("uxModule/setShowLoader", true);
      await this.$store.dispatch("payrollBatchModule/setRemoteAttendanceLog", {
        sessionId: this.item.sessionId,
        received: this.item.remoteAttendanceLogReceived,
      });
      this.$store.commit("uxModule/setShowLoader", false);
    },
  },
};
</script>
