var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 mx-auto",attrs:{"headers":_vm.headers,"items":_vm.charges,"sort-by":"paidOn"},scopedSlots:_vm._u([{key:"item.sessionChargeId",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.sessionChargeId)+" ")])]}},{key:"item.sessionStatus",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.sessionStatus)+" ")])]}},{key:"item.rateSummary",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.rateSummary)+" ")])]}},{key:"item.payrollBatch",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.payrollBatch)+" ")])]}},{key:"item.payrollBatchDeferredFrom",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.payrollBatchDeferredFrom)+" ")])]}},{key:"item.flag",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.flag)+" ")])]}},{key:"item.calculatedAmount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(_vm.formatCurrency(item.calculatedAmount))+" ")])]}},{key:"item.adjustedAmount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(_vm.formatCurrency(item.adjustedAmount))+" ")])]}},{key:"item.isPaid",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(_vm.isPaidText(item.isPaid))+" ")])]}},{key:"item.paidOn",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(_vm.paidOnText(item))+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }